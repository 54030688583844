import sha512Hash from 'crypto-js/sha512';
import hmacSHA512 from 'crypto-js/hmac-sha512';
import hex from 'crypto-js/enc-hex';

const SALT = 'transvoiceweb@12345678';

const sha512 = (password, salt) => {
  const hashDigest = sha512Hash(password);
  const hmacDigest = hex.stringify(hmacSHA512(hashDigest, salt));
  return {
    salt,
    passwordHash: hmacDigest,
  };
};

export default function generateHashPassword(plainTextPassword) {
  const passwordData = sha512(plainTextPassword, SALT);
  return passwordData.passwordHash;
}
