import React from 'react';
import PropTypes from 'prop-types';
import Translate from '../Translate';

import('./style.scss');

function AssignmentStatus({ status, className, children }) {
  let item = '';
  switch (status) {
    case 'accept':
      item = Translate({ content: 'status.accept' });
      break;
    case 'cancelled':
      item = Translate({ content: 'status.cancelled' });
      break;
    case 'reported':
      item = Translate({ content: 'status.reported' });
      break;
    case 'inquiry':
      item = Translate({ content: 'status.inquiry' });
      break;
    case 'performed':
      item = Translate({ content: 'status.performed' });
      break;
    case 'replanning':
      item = Translate({ content: 'status.replanning' });
      break;
    default:
      break;
  }

  return (
    <div className={className}>
      {children}
      <span className={`tv-assignment-status tv-assignment-status--${status}`}>
        {item}
      </span>
    </div>
  );
}
AssignmentStatus.propTypes = {
  status: PropTypes.string,
  children: PropTypes.string,
  className: PropTypes.string,
};
AssignmentStatus.defaultProps = {
  status: 'accept', // item.status ? item.status : item.OrderStatus  - fullfilled, cancelled, available, rejected, accept
  children: '',
  className: '',
};

export default AssignmentStatus;
