/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { TabButton } from 'components';
import './style.scss';

const TabButtonRow = ({
  tabs,
  onTabClick,
  className,
  activeTab,
  style,
  hideTabInMobileview,
}) => (
  <div className={` tv-tab_button_row__container ${className}`} style={style}>
    {tabs.map(tab => (
      <TabButton
        key={`${tab.name}-tabs`}
        label={tab.label}
        className={`${
          hideTabInMobileview && hideTabInMobileview.name === tab.name
            ? 'tv-mobile--hide'
            : ''
        }`}
        disabled={activeTab !== tab.name}
        onClick={() => onTabClick(tab)}
      />
    ))}
  </div>
);

TabButtonRow.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({})),
  activeTab: PropTypes.string,
  onTabClick: PropTypes.func,
  style: PropTypes.shape({}),
  hideTabInMobileview: PropTypes.shape({
    name: PropTypes.string,
  }),
  className: PropTypes.string,
};

TabButtonRow.defaultProps = {
  tabs: [],
  activeTab: '',
  onTabClick: () => {},
  hideTabInMobileview: null,

  style: {},
  className: '',
};

export default TabButtonRow;
