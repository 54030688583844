/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal,
  Translate,
  AssignmentTimeReportPreview,
  AssignmentTimeReportEdit,
} from 'components';

import './style.scss';

const AssignmentTimeReportModal = ({
  onClose,
  onSubmit,
  isVisible,
  item,
  handleChange,
  timeReportMode,
  timeSheetData,
  parkingAttachmentData,
  allExpenses,
  handleSectionSubmit,
  onSwitchMode,
  removeReceipt,
  useOwnVehicle,
  collapseSectionAction,
  collapseSection,
}) => (
  <Modal
    mainTitle={
      timeReportMode === 'preview'
        ? Translate({ content: 'timeReport.previewModetitle' })
        : Translate({ content: 'timeReport.editModeTitle' })
    }
    isVisible={isVisible}
    className="tv-assignment-time-report-modal__container"
    onClose={onClose}
  >
    {timeReportMode !== 'edit' && (
      <AssignmentTimeReportPreview
        onClose={onClose}
        item={item}
        isEdit={item.CanBeTimeReported}
        onSwitchMode={onSwitchMode}
        onSubmit={onSubmit}
      />
    )}
    {timeReportMode === 'edit' && (
      <AssignmentTimeReportEdit
        onClose={onClose}
        onSubmit={onSubmit}
        item={item}
        timeSheetData={timeSheetData}
        handleChange={handleChange}
        parkingAttachmentData={parkingAttachmentData}
        allExpenses={allExpenses}
        handleSectionSubmit={handleSectionSubmit}
        removeReceipt={removeReceipt}
        useOwnVehicle={useOwnVehicle}
        onSwitchMode={onSwitchMode}
        collapseSectionAction={collapseSectionAction}
        collapseSection={collapseSection}
      />
    )}
  </Modal>
);

AssignmentTimeReportModal.propTypes = {
  onClose: PropTypes.func,
  handleChange: PropTypes.func,
  removeReceipt: PropTypes.func,
  handleSectionSubmit: PropTypes.func,
  onSubmit: PropTypes.func,
  isVisible: PropTypes.bool,
  timeReportMode: PropTypes.string,
  item: PropTypes.shape({ CanBeTimeReported: PropTypes.bool }),
  // eslint-disable-next-line react/forbid-prop-types
  allExpenses: PropTypes.array,
  parkingAttachmentData: PropTypes.shape({}),
  useOwnVehicle: PropTypes.bool,
  onSwitchMode: PropTypes.func,
  collapseSectionAction: PropTypes.shape({}),
  collapseSection: PropTypes.shape({}),
  timeSheetData: PropTypes.shape({}),
};

AssignmentTimeReportModal.defaultProps = {
  onClose: () => {},
  handleChange: () => {},
  removeReceipt: () => {},
  handleSectionSubmit: () => {},
  onSubmit: () => {},
  isVisible: false,
  timeReportMode: 'view',
  item: { CanBeTimeReported: false },
  parkingAttachmentData: {},
  allExpenses: [],
  useOwnVehicle: false,
  onSwitchMode: () => {},
  collapseSectionAction: {},
  collapseSection: {},
  timeSheetData: {},
};

export default AssignmentTimeReportModal;
