export const initialState = {
  isInSickLeave: false,
};

export const initialResourceState = {
  list: [],
  detail: null,
};

export const getResourceState = (state = initialState, resource) => {
  const currentState = state[resource] || initialResourceState;
  return currentState;
};

export const getList = (state = initialState, resource) =>
  getResourceState(state, resource).list;

export const getDetail = (state = initialState, resource) =>
  getResourceState(state, resource).detail;

export const getUserProfile = (state = initialState, resource) =>
  getResourceState(state, resource).detail;

export const getSickLeaveInfo = (state = initialState) =>
  state.isInSickLeave || initialState.isInSickLeave;
