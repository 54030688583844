/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { Modal, DatePicker, TabButtonRow, Button } from 'components';
import './style.scss';

function SemesterAllocationModal({
  onClose,
  allowResourceRequestChange,
  tabs,
  onAllowResourceRequestChange,
  selectedStartDate,
  selectedEndDate,
  onStartDatePickerChange,
  onEndDatePickerChange,
  onDelete,
  onSave,
  isVisible,
  validationError,
}) {
  return (
    <Modal
      mainTitle="Semester/längre ledighet"
      isVisible={isVisible}
      className="tv-semester-allocation-modal__container"
      onClose={onClose}
    >
      <div className="tv-semester-allocation-modal__content">
        <p>
          Här kan du lägga in längre ledighet eller semester. Fördelen med denna
          är att du kan lägga in att du ska vara borta en längre period utan att
          påverka din Schemalagda tillgänglighet, dvs din period av
          tillgänglighet efter din semester. Du kan också välja om du vill få
          förfrågningar för perioden efter din semester, under din semester.
        </p>
        <hr className="tv-semester-allocation-modal__seperator" />
        <div className="tv-semester-allocation-modal__wrapper">
          <div className="tv-semester-allocation-modal__tab-container">
            <span>
              Jag vill ha förfrågningar under min ledighet, gällande perioden
              efter ledigheten
            </span>
            <TabButtonRow
              className="tab"
              tabs={tabs}
              activeTab={allowResourceRequestChange}
              onTabClick={onAllowResourceRequestChange}
            />
          </div>
          <div className="tv-semester-allocation-modal__datepicker-container">
            <div className="tv-semester-allocation-modal__control">
              <label htmlFor="startDate">Startdatum</label>
              <DatePicker
                selectedDate={selectedStartDate}
                minDate={new Date()}
                label="Startdatum"
                name="startDate"
                placeholderText="Önskat datum"
                onChange={onStartDatePickerChange}
                disabled
              />
            </div>
            <div className="tv-semester-allocation-modal__control">
              <label htmlFor="endDate">Slutdatum</label>
              <DatePicker
                selectedDate={selectedEndDate}
                minDate={new Date()}
                label="Slutdatum"
                name="endDate"
                placeholderText="Önskat datum"
                onChange={onEndDatePickerChange}
                disabled
              />
            </div>
          </div>
        </div>
        {!validationError.isValid && (
          <div className="tv-semester-allocation-modal__errors">
            {validationError.errorMessage}
          </div>
        )}
        <div className="tv-semester-allocation-modal__actions">
          <div className="tv-semester-allocation-modal__actions__row">
            <div
              className="tv-semester-allocation-modal__actions--delete"
              onClick={onDelete}
            >
              Ta bort
            </div>
          </div>
          <div className="tv-semester-allocation-modal__actions__row">
            <Button
              label="Avbryt"
              className="tv-semester-allocation-modal__actions-button"
              palette="outline"
              onClick={onClose}
            />
            <Button
              label="Lägg till"
              className="tv-semester-allocation-modal__actions-button"
              onClick={onSave}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}

SemesterAllocationModal.propTypes = {
  onClose: PropTypes.func,
  onAllowResourceRequestChange: PropTypes.func,
  onEndDatePickerChange: PropTypes.func,
  onStartDatePickerChange: PropTypes.func,
  onDelete: PropTypes.func,
  onSave: PropTypes.func,
  allowResourceRequestChange: PropTypes.func,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({ name: PropTypes.bool, label: PropTypes.string }),
  ),
  selectedStartDate: PropTypes.shape({}),
  selectedEndDate: PropTypes.shape({}),
  isVisible: PropTypes.bool,
  validationError: PropTypes.shape({
    isValid: PropTypes.bool,
    errorMessage: PropTypes.string,
  }),
};

SemesterAllocationModal.defaultProps = {
  onClose: () => {},
  onAllowResourceRequestChange: () => {},
  onEndDatePickerChange: () => {},
  onStartDatePickerChange: () => {},
  onDelete: () => {},
  onSave: () => {},
  allowResourceRequestChange: true,
  tabs: [
    { name: true, label: 'Ja' },
    { name: false, label: 'Nej' },
  ],
  selectedStartDate: new Date(),
  selectedEndDate: new Date(),
  isVisible: false,
  validationError: {
    isValid: true,
    errorMessage: '',
  },
};

export default SemesterAllocationModal;
