/* eslint-disable*/
import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'components';
import './style.scss';

const ContentPanel = props => (
  <div className="tv-terms single">{props.children}</div>
);

const ContentPageWrap = props => (
  <div className="tv-terms__page-wrap">{props.children}</div>
);

const ContentPage = props => (
  <div className="tv-terms__page">{props.children}</div>
);

const ContentTitle = props => (
  <h4 className="tv-terms__heading1">{props.children}</h4>
);

const ContentHeading = props => (
  <p className="tv-terms__heading2">{props.children}</p>
);

const ContentParagraph = props => (
  <p className="tv-terms__paragraph">{props.children}</p>
);

const ContentSection = props => (
  <div className="tab-panel-section">{props.children}</div>
);

const ContentSubSection = props => (
  <div className="tab-panel-section__sub">{props.children}</div>
);

function Terms(props) {
  return (
      <ContentPanel>
        <ContentPageWrap>
          <ContentPage>
            <ContentSection>
              Användarvillkor avseende Transvoicetjänsterna
            </ContentSection>
            <ContentSection>
              <ContentParagraph>
                Dessa användarvillkor (härefter ”Användarvillkoren”) gäller när
                Du använder Tolka!, kund.transvoice.se samt tolk.transvoice.se
                samt andra appar och webbtjänster (gemensamt benämnda
                ”Transvoicetjänsterna”) som från tid till annan tillhandahålls
                av Transvoice Sweden AB, org.nr: 556653-6370, samt av bolagets
                dotterbolag, inkluderande bl.a. Transvoice AB, org. nr:
                556482-8654 (gemensamt benämnda ”Transvoice”).
              </ContentParagraph>
            </ContentSection>

            <ContentSection>
              <ContentHeading>1. Transvoicetjänsterna</ContentHeading>
              <ContentParagraph>
                Transvoice äger samtliga rättigheter till Transvoicetjänsterna
                och till allt innehåll i Transvoicetjänsterna, inkluderande utan
                begränsning information som uppladdats i Transvoicetjänsterna av
                användare (härefter ”Användarinnehåll”), såvida annat inte
                skriftligen avtalats mellan en enskild användare och Transvoice.
              </ContentParagraph>
              <ContentParagraph>
                Dessa Användarvillkor medför inte att Du erhåller någon
                immateriell rättighet eller del av sådan rättighet, utöver den i
                Användarvillkoren specificerade nyttjanderätten, till
                Transvoicetjänsterna eller till innehåll i Transvoicetjänsterna.
              </ContentParagraph>
              <ContentParagraph>
                Transvoicetjänsterna är avsedda att användas som ett hjälpmedel
                vid orderhantering och administration som relaterar till
                tolktjänster som Du utför eller bokar genom Transvoice.
                Transvoicetjänsterna får ej användas eller laddas ned för andra
                ändamål än för nyssnämnd orderhantering och administration.
              </ContentParagraph>
            </ContentSection>

            <ContentSection>
              <ContentHeading>2. Nyttjanderätt</ContentHeading>
              <ContentParagraph>
                Genom att skapa ett konto för användning av Transvoicetjänsterna
                erhåller Du en icke-exklusiv och återkallelig licens att använda
                Transvoicetjänsterna. Din nyttjanderätt till
                Transvoicetjänsterna upphör omedelbart i det fall Du eller
                Transvoice avslutar Ditt användarkonto.
              </ContentParagraph>
            </ContentSection>

            <ContentSection>
              <ContentHeading>3. Användarens åtagande</ContentHeading>
              <ContentParagraph>
                Genom att skapa ett konto godkänner Du att Du är bunden av vid
                var tid gällande version av dessa Användarvillkor. Detta innebär
                att Du åtar Dig att följa Användarvillkoren samt att endast
                använda Transvoicetjänsterna för de ändamål som anges i dessa
                Användarvillkor.
              </ContentParagraph>
              <ContentParagraph>
                Vid användningen av Transvoicetjänsterna ansvarar Du för
                Användarinnehåll som Du tillgängliggör i Transvoicetjänsterna.
                Detta innefattar ett ansvar för att Användarinnehållet är
                korrekt och inte till någon del är missvisande, till exempel vad
                gäller uppgifter som ligger till grund för orderhantering.
                Transvoice har inget som helst ansvar för fel i slutlig
                orderhantering som direkt eller indirekt har föranletts av att
                Du eller någon annan användare har lagt in felaktig information
                i Transvoicetjänsterna.
              </ContentParagraph>
              <ContentParagraph>
                Du ansvarar även fullt ut för att Användarinnehåll som Du lägger
                in i Transvoicetjänsterna inte (1) gör intrång i någon annan
                tillkommande immateriella rättigheter, (2) är kränkande,
                och/eller (3) strider mot några tillämpliga lagar eller regler.
                Du medger att Transvoice äger rätt att ändra, ta bort och
                begränsa tillgången till Användarinnehåll, i den utsträckning
                Transvoice bedömer det påkallat.
              </ContentParagraph>
            </ContentSection>

            <ContentSection>
              <ContentHeading>4. Kontohantering</ContentHeading>
              <ContentParagraph>
                Du åtar Dig att hantera Ditt användarkonto och Ditt lösenord
                konfidentiellt samt att säkerställa att Dina kontouppgifter inte
                sprids till obehöriga. Vid misstanke om att Dina kontouppgifter
                har spridits till någon obehörig ska Du genast informera
                Transvoice på följande e-postadress{' '}
                <a target="_blank" href="mailto:kvalitet@transvoice.se">
                  kvalitet@transvoice.se.
                </a>
              </ContentParagraph>
            </ContentSection>

            <ContentSection>
              <ContentHeading>
                5. Överträdelser av dessa Användarvillkor
              </ContentHeading>
              <ContentParagraph>
                Transvoice förbehåller sig rätten att stänga av Dig från
                användning av Transvoicetjänsterna i det fall Du använder
                Transvoicetjänsterna i strid mot dessa Användarvillkor.
              </ContentParagraph>
              <ContentParagraph>
                Utöver rätten att stänga av Dig från Transvoicetjänsterna äger
                Transvoice rätt till skadestånd för den skada Transvoice
                åsamkats i anledning av överträdelsen av dessa Användarvillkor.
              </ContentParagraph>
            </ContentSection>

            <ContentSection>
              <ContentHeading>
                6. Ändringar av Transvoicetjänsterna och dessa Användarvillkor
              </ContentHeading>
              <ContentParagraph>
                Transvoice förbehåller sig rätten att ändra eller uppdatera
                Transvoicetjänsterna.
              </ContentParagraph>
              <ContentParagraph>
                Transvoice kan komma att ändra Transvoicetjänsterna för att
                införa nya funktioner samt för att förbättra användningen av
                Transvoicetjänsterna. I det fall Transvoice planerar att utföra
                en väsentlig ändring av Transvoicetjänsterna kommer Transvoice,
                i den omfattning Transvoice bedömer att det är skäligt,
                informera Dig innan den väsentliga ändringen genomförs.
              </ContentParagraph>
              <ContentParagraph>
                Transvoice kan även komma att ändra och uppdatera innehållet i
                dessa Användarvillkor. Du blir bunden av uppdaterade versioner
                av dessa Användarvillkor genom att fortsätta använda
                Transvoicetjänsterna. Längst ned i dessa Användarvillkor framgår
                det när Användarvillkoren senast uppdaterades.
              </ContentParagraph>
              <ContentParagraph>
                Innan Transvoice genomför väsentliga ändringar av dessa
                Användarvillkor kommer Transvoice att informera Dig. Om Du
                fortsätter att använda Transvoicetjänsterna, efter att ha
                erhållit information om en väsentlig ändring av dessa
                Användarvillkor, blir Du bunden av den uppdaterade versionen av
                Användarvillkoren.
              </ContentParagraph>
            </ContentSection>

            <ContentSection>
              <ContentHeading>
                7. Tillhandahållandet av Transvoicetjänsterna
              </ContentHeading>
              <ContentParagraph>
                Transvoice tillhandahåller Transvoicetjänsterna på det sätt
                Transvoice bedömer som ändamålsenligt och lämpligt. Transvoice
                förbehåller sig rätten att helt eller delvis använda
                underleverantörer för att tillhandahålla Transvoicetjänsterna.
              </ContentParagraph>
            </ContentSection>

            <ContentSection>
              <ContentHeading>8. Personuppgiftshantering</ContentHeading>
              <ContentParagraph>
                När Du använder Transvoicetjänsterna godtar Du att Transvoice
                behandlar Dina personuppgifter i enlighet med Transvoices
                personuppgiftspolicy.
              </ContentParagraph>
            </ContentSection>

            <ContentSection>
              <ContentHeading>9. Överlåtelse</ContentHeading>
              <ContentParagraph>
                Transvoice äger rätt att överlåta Transvoicetjänsterna utan att
                inhämta ditt medgivande, förutsatt att en sådan överlåtelse inte
                strider mot en skriftlig överenskommelse eller ett skriftligt
                avtal mellan Dig och Transvoice.
              </ContentParagraph>
              <ContentParagraph>
                Du äger inte rätt att överlåta eller upplåta Ditt konto för
                användning av Transvoicetjänsterna utan att först inhämta
                Transvoices skriftliga godkännande.
              </ContentParagraph>
            </ContentSection>

            <ContentSection>
              <ContentHeading>10. Ansvarsbegränsning</ContentHeading>
              <ContentParagraph>
                Transvoice tillhandahåller Transvoicetjänsterna i befintligt
                skick och utan garantier.
              </ContentParagraph>
              <ContentParagraph>
                Transvoice vidtar skäliga åtgärder för att tillhandahålla
                Transvoicetjänsterna utan avbrott och störningar. Transvoice
                åtar sig dock inte att upprätthålla någon specificerad nivå av
                tillgänglighet och/eller service avseende Transvoicetjänsterna.
              </ContentParagraph>
              <ContentParagraph>
                Transvoice åtar sig följaktligen inget som helst ansvar för
                skador som direkt eller indirekt föranleds av att det förekommer
                avbrott eller andra störningar i Transvoicetjänsterna, såvida
                inte Du har träffat ett skriftligt avtal med Transvoice där
                Transvoice åtar sig att upprätthålla specificerade service- och
                tillgänglighetsnivåer.
              </ContentParagraph>
            </ContentSection>

            <ContentSection>
              <ContentHeading>
                11. Användarvillkorens giltighetstid
              </ContentHeading>
              <ContentParagraph>
                Dessa Användarvillkor träder i kraft när Du skapar ett konto för
                användningen av Transvoicetjänsterna och gäller intill dess att
                Du eller Transvoice avslutar Ditt konto.
              </ContentParagraph>
            </ContentSection>
            <ContentHeading>12. Bestämmelses ogiltighet</ContentHeading>

            <ContentSection>
              <ContentParagraph>
                Skulle någon bestämmelse i Användarvillkoren eller del därav
                befinnas ogiltig, ska detta inte innebära att Användarvillkoren
                i dess helhet är ogiltiga utan ska, i den mån ogiltigheten
                väsentligen påverkar Dina eller Transvoices rättigheter eller
                skyldigheter enligt Användarvillkoren, skälig jämkning i
                Användarvillkoren ske.
              </ContentParagraph>
            </ContentSection>

            <ContentSection>
              <ContentHeading>
                13. Tillämplig lag och behörig domstol
              </ContentHeading>
              <ContentParagraph>
                Svensk lag, utan tillämpning av dess lagvalsprinciper, ska
                tillämpas på dessa Användarvillkor.
              </ContentParagraph>
              <ContentParagraph>
                Tvister som uppstår i anledning av dessa Användarvillkor ska
                slutligt avgöras av svensk allmän domstol med Stockholms
                tingsrätt som första instans.
              </ContentParagraph>
            </ContentSection>
          </ContentPage>
          <ContentPage>
            <ContentParagraph>
              Transvoice personuppgiftspolicy senast uppdaterad 2019-11-08.
            </ContentParagraph>
          </ContentPage>
        </ContentPageWrap>
      </ContentPanel>
  );
}

Terms.propTypes = {};

export default Terms;
