/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import './style.scss';
import { localDayjs as dayjs } from 'utils/dateTimeUtils';
import sv from 'date-fns/locale/sv';

registerLocale('sv', sv);

const CalendarNavigator = ({
  onSelectDate,
  range,
  selectedCalendar,
  ...rest
}) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  useEffect(() => {
    const newSelectDate = range?.highlightedDay || dayjs();
    setSelectedDate(newSelectDate.toDate());
  }, [selectedCalendar, range]);

  return (
    <div className="tv-calendar-nav">
      <ReactDatePicker
        dateFormat="yyyy-MM-dd"
        onChange={date => {
          onSelectDate(date);
          setSelectedDate(date);
        }}
        locale="sv"
        disabledKeyboardNavigation
        // startDate={range.start.toDate()}
        // endDate={range.end.toDate()}
        calendarStartDay={1}
        selected={selectedDate}
        {...rest}
        inline
      />
    </div>
  );
};

CalendarNavigator.propTypes = {
  onSelectDate: PropTypes.func,
  range: PropTypes.shape({}),
  selectedCalendar: PropTypes.string,
};

CalendarNavigator.defaultProps = {
  onSelectDate: () => {},
  range: null,
  selectedCalendar: 'week',
};

export default CalendarNavigator;
