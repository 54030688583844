/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { IconButton, ContactCard } from 'components';
import parse from 'html-react-parser';
import faq from './faq';
import './style.scss';

const ContactPage = () => {
  const [showAccordian, setAccordianState] = useState({
    'rowOne-columnOne': false,
    'rowOne-columnTwo': false,
    'rowTwo-columnOne': false,
    'rowTwo-columnTwo': false,
    'rowThree-columnOne': false,
    'rowThree-columnTwo': false,
  });

  const handleShowAccordian = id => {
    setAccordianState({
      [id]: !showAccordian[id],
    });
  };

  const displayIcons = id => {
    if (showAccordian[id]) {
      return <IconButton iconName="chevron-up" />;
    }
    return <IconButton iconName="chevron-down" />;
  };
  return (
    <>
      <div className="page_content">
        <div className="page-title">
          <div className="title">Kontakta oss</div>
          {/* <div className="contact_now_button_wrapper">
            <button type="button" className="contact_now_button">
              {Translate({
                content: 'contact.sendTransvoiceAnEmail',
              })}
            </button>
          </div> */}
        </div>
        <div className="contact_card_wrapper">
          <div className="d-flex flex-column">
            <div className="d-flex w-100 flex-column flex-md-row">
              <ContactCard
                imageUrl="/images/stockholm-office.jpg"
                place="Stockholm"
                addressLine="Lindhagensgatan 126"
                addressCityPostCode="112 51 Stockholm"
                customerServiceNumber="08-120 800 05"
              />
              <ContactCard
                imageUrl="/images/Malmö-office.jpg"
                place="Malmö"
                addressLine="Grynbodgatan 14"
                addressCityPostCode="211 33 Malmö"
                customerServiceNumber="040-23 02 05"
              />
            </div>
          </div>
          <div className="page-title">Frågor och svar</div>
          <div className="faq_wrapper">
            {Object.keys(faq.faqData).map(row => (
              <div className="row" key={`${faq.faqData[row]}-${Math.random()}`}>
                {faq.faqData[row].map(column => (
                  <div className="col col-md-6 col-sm-12" key={column.id}>
                    <div className="drop_accordian">
                      <div className="question_content">
                        <div
                          className="heading"
                          onClick={() => handleShowAccordian(column.id)}
                          onKeyPress={() => handleShowAccordian(column.id)}
                          role="button"
                          tabIndex={0}
                        >
                          <div>{column.question}</div>
                          <div className="icon_wrapper">
                            {displayIcons(column.id)}
                          </div>
                        </div>
                        <div
                          className="body"
                          style={{
                            display: showAccordian[column.id] ? 'flex' : 'none',
                          }}
                        >
                          {column.answer &&
                            column.answer
                              .split('||')
                              .map(x => <p>{parse(x)}</p>)}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="page-footer">
        <div className="d-flex mt-auto">
          <img alt="transvoice-black" src="images/transvoice-black 1.png" />
        </div>
      </div>
    </>
  );
};

export default ContactPage;
