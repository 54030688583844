/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import PropTypes from 'prop-types';

const InputField = ({
  name,
  value,
  placeholder,
  type,
  fieldType,
  onChange,
  onBlur,
  onKeyUp,
  options,
  className,
  ...rest
}) => {
  if (fieldType === 'select') {
    return (
      <select
        defaultMenuIsOpen
        name={name}
        value={value}
        className={`${className}`}
        onChange={onChange}
      >
        {options}
      </select>
    );
  }
  return (
    <input
      name={name}
      value={value}
      className={`${className}`}
      placeholder={placeholder}
      type={type}
      onKeyUp={onKeyUp}
      onChange={onChange}
      onBlur={onBlur}
      {...rest}
    />
  );
};

InputField.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  fieldType: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyUp: PropTypes.func,
  options: PropTypes.node,
  style: PropTypes.shape({}),
  className: PropTypes.string,
};

InputField.defaultProps = {
  name: '',
  value: undefined,
  placeholder: '',
  fieldType: '',
  type: 'text',
  onChange: () => {},
  onBlur: () => {},
  onKeyUp: () => {},
  style: {},
  options: [<option value="">Vilaj</option>],
  className: 'tv-field__input',
};

export default InputField;
