import React from 'react';
import {
  CalendarContainer,
  WelcomeMessageModalContainer,
  WelcomeMessageContainer,
} from 'containers';
import TermsAndConditionsModalContainer from 'containers/TermsAndConditionsModalContainer';

function DashboardPage() {
  return (
    <>
      <div className="d-flex w-100">
        <CalendarContainer />
      </div>
      <WelcomeMessageContainer />
      <WelcomeMessageModalContainer />
      <TermsAndConditionsModalContainer />
    </>
  );
}

DashboardPage.propTypes = {};

export default DashboardPage;
