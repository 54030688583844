import React, { useEffect, useState } from 'react';
import {
  ForgotPasswordForm,
  Translate,
  Spinner,
  ChangePassword,
} from 'components';
import { fulfilled, rejected, pending, clean } from 'redux-saga-thunk';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  transvoiceResourceAuthenticateUsingId,
  transvoiceLoginSuccess,
  transvoiceLoginRequest,
} from 'store/actions';
import decrypt from 'utils/cryptoUtil';
import { useHistory } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import ResetPasswordContainer from './ResetPasswordContainer';

export const ForgotPasswordContainer = ({
  authenticateResourceUser,
  location,
  onCreateToken,
  onCleanToken,
  isFulfilled,
  isFailed,
  isAuthenticationFulfilled,
  isAuthenticationPending,
  cleanStoreProps,
  isPending,
}) => {
  const history = useHistory();
  const { addToast } = useToasts();
  const [showResetPasswordForm, setShowResetPasswordForm] = useState(false);

  useEffect(() => {
    const isUserLoginFromEmailLink = history.location?.state?.data || null;
    if (isUserLoginFromEmailLink) {
      const encodedData = decodeURIComponent(isUserLoginFromEmailLink);
      const encryptedData = encodedData.replace(/\s/g, '+');

      if (encryptedData) {
        const plaintextData = decrypt(encryptedData);
        const paramsParser = new URLSearchParams(plaintextData);

        const interpreterId = paramsParser.get('interpreter_id');
        const resourceIdentifier = paramsParser.get('resource_identifier');
        const timeStamp = paramsParser.get('timestamp');

        if (interpreterId && resourceIdentifier && timeStamp) {
          onCreateToken(interpreterId, resourceIdentifier, timeStamp);
        }
      }
    }
  }, [location]);

  useEffect(() => {
    if (isFulfilled) {
      setShowResetPasswordForm(true);
    }
    if (isFailed) {
      history.push('/forgot-password');
      setShowResetPasswordForm(false);
    }
    cleanStoreProps('loginRequest');
  }, [isFulfilled, isFailed]);

  useEffect(() => {
    if (isAuthenticationFulfilled) {
      addToast(Translate({ content: 'login.loginSuccessMessage' }), {
        appearance: 'success',
      });
      cleanStoreProps('resourceUserAuthenticateUsingId');
    }
  }, [isAuthenticationFulfilled]);

  const handlePassowrdResetSuccess = () => {
    history.push('/login');
    onCleanToken();
  };

  return (
    <>
      {(isPending || isAuthenticationPending) && <Spinner />}
      {!showResetPasswordForm ? (
        <ForgotPasswordForm onFormSubmit={authenticateResourceUser} />
      ) : (
        <ResetPasswordContainer
          onSuccess={handlePassowrdResetSuccess}
          render={({ onSetPassword, key }) => (
            <ChangePassword
              onSetPassword={onSetPassword}
              hideCurrentPassword
              className="tv-login-form__container "
              key={key}
            />
          )}
        />
      )}
    </>
  );
};

ForgotPasswordContainer.propTypes = {
  authenticateResourceUser: PropTypes.func,
  cleanStoreProps: PropTypes.func,
  onCreateToken: PropTypes.func,
  onCleanToken: PropTypes.func,
  location: PropTypes.shape({ search: PropTypes.string }),
  isFulfilled: PropTypes.bool,
  isPending: PropTypes.bool,
  isFailed: PropTypes.bool,
  isAuthenticationFulfilled: PropTypes.bool,
  isAuthenticationPending: PropTypes.bool,
};
ForgotPasswordContainer.defaultProps = {
  authenticateResourceUser: () => {},
  cleanStoreProps: () => {},
  onCreateToken: () => {},
  onCleanToken: () => {},
  location: { search: '' },
  isFulfilled: false,
  isPending: false,
  isAuthenticationFulfilled: false,
  isAuthenticationPending: false,
  isFailed: false,
};

const mapDispatchToProps = dispatch => ({
  authenticateResourceUser: data =>
    dispatch(transvoiceResourceAuthenticateUsingId(data)),
  cleanStoreProps: key => {
    dispatch(clean(key));
  },
  onCleanToken: () => {
    dispatch(transvoiceLoginSuccess({ AccessToken: null }, null, null));
  },
  onCreateToken: (interpreterId, resourceIdentifier, timeStamp) =>
    dispatch(
      transvoiceLoginRequest(interpreterId, resourceIdentifier, timeStamp),
    ),
});
const mapStateToProps = state => ({
  isFulfilled: fulfilled(state, 'loginRequest'),
  isPending: pending(state, 'loginRequest'),
  isAuthenticationFulfilled: fulfilled(
    state,
    'resourceUserAuthenticateUsingId',
  ),
  isAuthenticationPending: pending(state, 'resourceUserAuthenticateUsingId'),
  isFailed: rejected(state, 'loginRequest'),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ForgotPasswordContainer);
