/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  AssignmentTimeReportModal,
  Spinner,
  GeneralSuccessModal,
} from 'components';
import { timeSheetContentTypes } from 'constant';
import {
  convertToDayJsObject,
  isDayWithinTheRange,
  localDayjs,
} from 'utils/dateTimeUtils';
import { useTimeSheetReport } from 'hooks';

const initialtimeReportModel = {
  BreaktimeInSeconds: 0,
  ClientSignatureContentType: null,
  ClientSignatureFilename: null,
  ClientSignaturePicture: null,
  Comment: null,
  DatetimeOriginalFrom: null,
  DatetimeOriginalTo: null,
  DatetimeTravelFromAssignmentFrom: null,
  DatetimeTravelFromAssignmentTo: null,
  DatetimeTravelToAssignmentFrom: null,
  DatetimeTravelToAssignmentTo: null,
  DistanceFromAssignment: 0,
  DistanceToAssignment: 0,
  DowntimeInSeconds: 0,
  IsResourceRequestingKilometerCompensation: false,
  TraveltimeFromAssignment: 0,
  TraveltimeToAssignment: 0,
};

const AssignmentTimeReportEditContainer = ({
  item,
  isVisible,
  isEdit,
  onClose,
}) => {
  const {
    sendExpenseData,
    sendRatingData,
    sendTimeSheetData,
    timeReoprtRequestFulfilled,
    timeReoprtRequestPending,
  } = useTimeSheetReport(item);

  const [assignmentState, setAssignmentState] = useState(item);
  const [timeSheetData, setTimeSheetData] = useState({
    ...initialtimeReportModel,
    DatetimeOriginalFrom: item.DatetimeOrderFrom,
    DatetimeOriginalTo: item.DatetimeOrderTo,
  });
  const [showSuccessTimeReportModal, setShowSuccessTimeReportModal] = useState(
    false,
  );
  const [allExpenses, setAllExpenses] = useState([]);
  const [timeReportMode, setTimeReportMode] = useState(
    item.CanBeTimeReported ? 'edit' : 'view',
  );

  const [visibleEditTripInfo, setVisibleEditTripInfo] = useState(false);
  const [visibleParkingTicket, setVisibleParkingTicket] = useState(false);
  const [visibleOtherExpenses, setVisibleOtherExpenses] = useState(false);

  useEffect(() => {
    if (timeReoprtRequestFulfilled) {
      setShowSuccessTimeReportModal(true);
    }
  }, [timeReoprtRequestFulfilled]);

  const handleTimeSheet = event => {
    const {
      target: { name, value },
    } = event;
    const timeReport = {
      ...timeSheetData,
      [name]: value,
    };
    setTimeSheetData({ ...timeReport, validate: {} });
    setAssignmentState({
      ...assignmentState,
      TimeReport: timeReport,
    });
  };

  const handleRate = value => {
    setAssignmentState({ ...assignmentState, RatingResource: value });
  };

  const handleChange = (event, identifier) => {
    switch (identifier) {
      case timeSheetContentTypes.TIME_SHEET:
        handleTimeSheet(event);
        break;

      case timeSheetContentTypes.RATING:
        handleRate(event);
        break;

      case timeSheetContentTypes.TOTAL_TRAVEL_DISTANCE: {
        const {
          target: { value },
        } = event;
        const halfDistance = Math.abs(value / 2);
        setTimeSheetData({
          ...timeSheetData,
          DistanceToAssignment: halfDistance,
          DistanceFromAssignment: halfDistance,
          validate: {},
        });
        setAssignmentState({
          ...assignmentState,
          TimeReport: {
            ...timeSheetData,
            DistanceToAssignment: halfDistance,
            DistanceFromAssignment: halfDistance,
          },
        });
        break;
      }
      case timeSheetContentTypes.TIME_PICKER_START: {
        const processTime = event.format('YYYY-MM-DD HH:mm:ss');
        setTimeSheetData({
          ...timeSheetData,
          DatetimeOriginalFrom: processTime,
          validate: {},
        });
        setAssignmentState({
          ...assignmentState,
          TimeReport: {
            ...timeSheetData,
            DatetimeOriginalFrom: processTime,
          },
        });
        break;
      }
      case timeSheetContentTypes.TIME_PICKER_END: {
        const processTime = event.format('YYYY-MM-DD HH:mm:ss');
        setTimeSheetData({
          ...timeSheetData,
          DatetimeOriginalTo: processTime,
          validate: {},
        });
        setAssignmentState({
          ...assignmentState,
          TimeReport: {
            ...timeSheetData,
            DatetimeOriginalTo: processTime,
          },
        });
        break;
      }
      case timeSheetContentTypes.REST_TIME: {
        let processTime = event.target?.value || 0;
        processTime =
          Math.abs(processTime > 60 ? 60 : parseInt(processTime, 10)) * 60;
        setTimeSheetData({
          ...timeSheetData,
          BreaktimeInSeconds: processTime,
        });
        setAssignmentState({
          ...assignmentState,
          TimeReport: {
            ...timeSheetData,
            BreaktimeInSeconds: processTime,
          },
        });
        break;
      }
      case timeSheetContentTypes.USE_OWN_VEHICLE: {
        const ownCar = !timeSheetData.IsResourceRequestingKilometerCompensation;
        setTimeSheetData({
          ...timeSheetData,
          IsResourceRequestingKilometerCompensation: ownCar,
        });
        setAssignmentState({
          ...assignmentState,
          TimeReport: {
            ...timeSheetData,
            IsResourceRequestingKilometerCompensation: ownCar,
          },
        });
        break;
      }
      case timeSheetContentTypes.RESET_PARKING: {
        const filteredExpenseList = allExpenses.filter(
          x => x.ExpenseType !== 1,
        );
        setAllExpenses(filteredExpenseList);
        setAssignmentState({
          ...assignmentState,
          Expenses: filteredExpenseList,
        });
        break;
      }
      case timeSheetContentTypes.RESET_OTHER_EXPENSE: {
        const filteredExpenseList = allExpenses.filter(
          x => x.ExpenseType !== 2,
        );
        setAllExpenses(filteredExpenseList);
        setAssignmentState({
          ...assignmentState,
          Expenses: filteredExpenseList,
        });
        break;
      }
      case timeSheetContentTypes.RESET_TRAVEL: {
        const restTimeSheet = {
          ...timeSheetData,
          DistanceFromAssignment: 0,
          DistanceToAssignment: 0,
          TraveltimeFromAssignment: 0,
          TraveltimeToAssignment: 0,
          BreaktimeInSeconds: 0,
        };
        setAssignmentState({
          ...assignmentState,
          TimeReport: { ...timeSheetData },
        });
        setTimeSheetData(restTimeSheet);
        break;
      }

      default:
        break;
    }
  };

  const handleSectionSubmit = attachment => {
    setAllExpenses([...allExpenses, attachment]);
    setAssignmentState({
      ...assignmentState,
      Expenses: [...allExpenses, attachment],
    });
  };

  const removeReceipt = id => {
    const newReceipts = allExpenses.filter(
      attachmentItem => attachmentItem.id !== id,
    );
    setAllExpenses([...newReceipts]);
    setAssignmentState({
      ...assignmentState,
      Expenses: [...newReceipts],
    });
  };

  const validatTimeReport = () => {
    const {
      DistanceFromAssignment,
      DistanceToAssignment,
      DatetimeOriginalTo,
      DatetimeOriginalFrom,
      TraveltimeToAssignment,
      TraveltimeFromAssignment,
    } = timeSheetData;
    const validation = { isValid: true };
    if (
      DistanceFromAssignment +
        DistanceToAssignment +
        TraveltimeToAssignment +
        TraveltimeFromAssignment !==
        0 &&
      (TraveltimeToAssignment === 0 ||
        TraveltimeFromAssignment === 0 ||
        DistanceFromAssignment === 0 ||
        DistanceToAssignment === 0)
    ) {
      validation.travel = '* Fyll i alla fält';
      validation.isValid = false;
    }
    const [orderFrom, orderTo, timeReportOrderFrom, timeReportOrderTo] = [
      assignmentState.DatetimeOrderFrom,
      assignmentState.DatetimeOrderTo,
      convertToDayJsObject(DatetimeOriginalFrom),
      convertToDayJsObject(DatetimeOriginalTo),
    ];
    if (
      (timeReportOrderFrom.isBefore(orderFrom, 'minutes') ||
        timeReportOrderFrom.isAfter(orderTo, 'minutes')) &&
      (timeReportOrderTo.isAfter(orderTo, 'minutes') ||
        timeReportOrderTo.isBefore(orderFrom, 'minutes'))
    ) {
      validation.orderTime =
        '* Tidrapporten måste överlappa orderns utförandetid';
      validation.isValid = false;
    }
    if (
      localDayjs
        .duration(timeReportOrderTo.diff(timeReportOrderFrom))
        .asMinutes() < 0
    ) {
      validation.orderTime = '* Sluttiden måste vara högre än starttiden';
      validation.isValid = false;
    }

    return validation;
  };

  const onSubmit = () => {
    const valid = validatTimeReport();
    if (valid.isValid) {
      const timeReport = {
        ...timeSheetData,
        Comment: assignmentState.TimeReport.Comment || '',
        WorkAssignmentIdentifier: assignmentState.WorkAssignmentIdentifier,
      };
      const rate = {
        RatingResource: assignmentState.RatingResource || 0,
        WorkAssignmentIdentifier: assignmentState.WorkAssignmentIdentifier,
      };
      const expenses = {
        ExpensesToUpdate: allExpenses,
        WorkAssignmentIdentifier: assignmentState.WorkAssignmentIdentifier,
      };
      sendTimeSheetData(timeReport);
      if (
        assignmentState.RatingResource !== 0 &&
        assignmentState.RatingResource !== null
      )
        sendRatingData(rate);
      if (allExpenses.length > 0) sendExpenseData(expenses);
    } else {
      setTimeSheetData({ ...timeSheetData, validate: { ...valid } });
    }
  };

  const handleSwitchMode = (mode = 'edit') => {
    const valid = validatTimeReport();
    if (valid.isValid) {
      setTimeReportMode(mode);
      setAssignmentState({
        ...assignmentState,
        TimeReport: { ...timeSheetData },
      });
    } else {
      setTimeSheetData({ ...timeSheetData, validate: { ...valid } });
    }
  };

  return (
    <>
      {!showSuccessTimeReportModal && (
        <AssignmentTimeReportModal
          item={assignmentState}
          isVisible={isVisible}
          isEdit={isEdit}
          onClose={onClose}
          timeReportMode={timeReportMode}
          allExpenses={allExpenses}
          timeSheetData={timeSheetData}
          onSwitchMode={handleSwitchMode}
          handleChange={handleChange}
          handleSectionSubmit={handleSectionSubmit}
          removeReceipt={removeReceipt}
          onSubmit={onSubmit}
          collapseSection={{
            visibleEditTripInfo,
            visibleParkingTicket,
            visibleOtherExpenses,
          }}
          collapseSectionAction={{
            setVisibleEditTripInfo,
            setVisibleParkingTicket,
            setVisibleOtherExpenses,
          }}
        />
      )}
      {timeReoprtRequestPending && <Spinner />}
      {showSuccessTimeReportModal && (
        <GeneralSuccessModal
          title="Du har skickat in din tidrapport"
          body=" Du kan se din tidrapport för uppdraget, både i kalendern och på din uppdragssida"
          isVisible={showSuccessTimeReportModal}
          onClick={() => {
            setShowSuccessTimeReportModal(false);
            onClose();
          }}
        />
      )}
    </>
  );
};

AssignmentTimeReportEditContainer.propTypes = {
  item: PropTypes.shape({
    CanBeTimeReported: PropTypes.bool,
    workAssignmentIdentifier: PropTypes.string,
  }),
  isEdit: PropTypes.bool,
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
};
AssignmentTimeReportEditContainer.defaultProps = {
  item: {
    CanBeTimeReported: false,
    workAssignmentIdentifier: '',
  },
  isVisible: false,
  isEdit: false,
  onClose: () => {},
};

export default AssignmentTimeReportEditContainer;
