import React from 'react';
import PropTypes from 'prop-types'

import "./style.scss"

const NotificationIcon=({ count, onClick })=>  (
  <div className="tv-notification-icon__container">
      <button
        type="button"
      className="tv-notification-icon__button"
      onClick={onClick}
      >
        <i className={`material-icons ${count!==0 && 'tv-notification-icon-active'}`}>notifications_active</i>
      </button>
        {count!==0 && (<div className="tv-notification-icon__count">
          {count}
        </div>
      )}
  </div>
)

NotificationIcon.propTypes = {
  count:PropTypes.number,
  onClick:PropTypes.func,
};

NotificationIcon.defaultProps = {
  count:0,
  onClick:()=>{},
};

export default NotificationIcon;
