/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { convertToDayJsObject, localDayjs } from 'utils/dateTimeUtils';
import { useSelector } from 'react-redux';
import {
  Button,
  StarRating,
  AssignmentCancelModal,
  AssignmentReturnConfirmationModal,
  AssignmentReturnSuccessModal,
  AssignmentReturnFailedModal,
  AssignmentContactModal,
  AssignmentType,
  AssignmentStatus,
  AssignmentCustomerName,
  AssignmentNavigator,
} from 'components';
import { AssignmentTimeReportEditContainer } from 'containers';
import { fromResource } from 'store/selectors';
import './style.scss';
import { useWorkAssignment } from 'hooks';

function formatAddress(addressText, isDeviating) {
  return isDeviating ? `OBS! ${addressText}` : addressText;
}

function AssignmentOverviewContainer({
  assignmentId,
  children,
  onClickBack,
  onClickClose,
  NavigationClickHandler,
  onAssignmentEarlyCompleted,
}) {
  const overViewRef = useRef(null);

  return (
    <div className="tv-assignment-overview" ref={overViewRef}>
      <AssignmentNavigator
        onClickPrevious={() => {
          NavigationClickHandler('previous');
        }}
        onClickNext={() => {
          NavigationClickHandler('next');
        }}
        className="tv-mobile--hide"
      />
      <div className="tv-assignment-overview__outer-container">
        <div className="tv-assignment-overview__outer-container__header">
          <Button
            onClick={onClickBack}
            className="back-icon"
            palette="text"
            label={<i className="fa fa-chevron-left" />}
          />
          <div className="assignment-number">{assignmentId}</div>
          <Button
            onClick={onClickClose}
            className="close-icon"
            palette="text"
            label={<i className="fa fa-times" />}
          />
        </div>
        <hr />
        <div className="tv-assignment-overview__outer-container__wrapper">
          {children}
        </div>
        <AssignmentNavigator
          onClickPrevious={() => {
            NavigationClickHandler('previous');
          }}
          onClickNext={() => {
            NavigationClickHandler('next');
          }}
          className="tv-assignment-overview__mobile-navigator"
        />
      </div>
    </div>
  );
}

function AssignmentOverview({
  item,
  onClickClose,
  onHideAssignment,
  onAccept,
  onReject,
  NavigationClickHandler,
  onAssignmentEarlyCompleted,
}) {
  const {
    searchSingleWorkAssignment,
    cleanReturningToRequest,
    createReturnAssignment,
    createReturnAssignmentFulfilled,
    cancelReplanningRequest,
    cancelReplanningRequestFulfilled,
    cleanCancelReplanningRequest,
  } = useWorkAssignment();
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [
    showReturnConfirmationModal,
    setShowReturnConfirmationModal,
  ] = useState(false);
  const [showReturnSuccessModal, setShowReturnSuccessModal] = useState(false);
  const [showReturnFailedModal, setShowReturnFailedModal] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);
  const [showTimeSheetModal, setShowTimeSheetModal] = useState(false);
  const [disableFinishedEarly, setDisableFinishedEarly] = useState(true);

  const userProfile = useSelector(state =>
    fromResource.getDetail(state, 'userProfile'),
  );

  const modifiedStartTime = convertToDayJsObject(item.DatetimeOrderFrom).add(
    7,
    'minute',
  );

  let countdown = null;

  const checkCurrentTimePassedStartTime = () => {
    const currentTime = localDayjs();
    if (currentTime.isAfter(modifiedStartTime)) {
      setDisableFinishedEarly(false);
      clearInterval(countdown);
    }
  };
  useEffect(() => {
    if (item.OrderStatus === 'accept') {
      countdown = setInterval(checkCurrentTimePassedStartTime, 10000);
    }
    return countdown;
  }, []);

  const isInquiry = item.OrderStatus === 'inquiry';

  const matchingSkill = useMemo(() => {
    const { Profile } = userProfile || {};

    if (!Profile?.ActiveSkills?.length) return item.Skill;

    const matching = Profile.ActiveSkills.find(
      ({ Skill }) => Skill === item.Skill || Skill === item.SkillSubstitute,
    );

    if (matching) {
      return matching.Skill;
    }

    return item.Skill;
  }, [userProfile, item]);

  useEffect(() => {
    if (createReturnAssignmentFulfilled) {
      searchSingleWorkAssignment(item.WorkAssignmentIdentifier);
      cleanReturningToRequest();
    }
  }, [createReturnAssignmentFulfilled]);

  useEffect(() => {
    if (cancelReplanningRequestFulfilled) {
      searchSingleWorkAssignment(item.WorkAssignmentIdentifier);
      cleanCancelReplanningRequest();
    }
  }, [cancelReplanningRequestFulfilled]);

  return (
    <>
      <AssignmentOverviewContainer
        NavigationClickHandler={NavigationClickHandler}
        assignmentId={item.OrderNumber}
        onClickBack={() => {
          clearInterval(countdown);
          onClickClose();
        }}
        onClickClose={() => {
          clearInterval(countdown);
          onClickClose();
        }}
      >
        <div className="tv-assignment-overview__container">
          <div className="tv-assignment-overview__content">
            <AssignmentOverviewProperty className="tv-assignment-overview__assignment-type">
              <AssignmentCustomerName
                serviceName={item.ArticleName}
                status={item.OrderStatus}
                customerName={item.Customer?.CustomerName}
                isColoredIcon
              />
              <AssignmentStatus status={item.OrderStatus} />
            </AssignmentOverviewProperty>
          </div>
          <hr />
          <div className="tv-assignment-overview__content">
            <AssignmentOverviewProperty
              isDisplay={
                item.OrderStatus === 'accept' ||
                item.OrderStatus === 'inquiry' ||
                item.OrderStatus === 'performed' ||
                item.OrderStatus === 'reported' ||
                item.OrderStatus === 'cancelled'
              }
              propertyIcon="person"
              propertyText={item.Customer?.CustomerName}
            />
            <AssignmentOverviewProperty
              propertyIcon="language"
              propertyText={isInquiry ? matchingSkill : item.Skill}
            />
            {item.SkillSubstitute && !isInquiry && (
              <AssignmentOverviewProperty
                isLabelled={
                  item.IsBasedOnSkillSubstitute &&
                  item.OrderStatus !== 'inquiry'
                }
                propertyIcon=""
                propertyText={`Alternativt språk: ${item.SkillSubstitute}`}
              />
            )}
            <AssignmentOverviewProperty
              propertyIcon="watch_later"
              propertyText={`${convertToDayJsObject(
                item.DatetimeOrderFrom,
              ).format('HH:mm')} - ${convertToDayJsObject(
                item.DatetimeOrderTo,
              ).format('HH:mm, DD MMMM YYYY')}`}
            />
            <AssignmentOverviewProperty
              isDisplay={item.OrderStatus === 'inquiry'}
              className="tv-assignment-overview__amount"
              propertyText={`~ ${item.PreliminaryRemunerationAmountSEK} kr preliminär ersättning`}
              propertyIcon="attach_money"
            />
          </div>
          <hr />
          <div className="tv-assignment-overview__content">
            <AssignmentOverviewProperty>
              <AssignmentType
                serviceName={item.ArticleName}
                status={item.OrderStatus}
                isColoredIcon={false}
                className="tv-assignment-overview__assignment-type--small"
              />
            </AssignmentOverviewProperty>

            <AssignmentOverviewProperty
              isDisplay={item.OrderStatus === 'cancelled'}
              propertyIcon="watch_later"
              propertyText={`Uppdraget avbokades: ${convertToDayJsObject(
                item.DatetimeSubmitted,
              ).format('HH:mm, DD MMMM YYYY')}`}
            />
            <AssignmentOverviewProperty
              isDisplay={
                (item.OrderStatus === 'accept' ||
                  item.OrderStatus === 'performed' ||
                  item.OrderStatus === 'reported') &&
                item.CustomerReference
              }
              propertyIcon="assignment"
              propertyText={`Bokningsreferens: ${item.CustomerReference}`}
            />
            <AssignmentOverviewProperty
              isDisplay={
                (item.OrderStatus === 'accept' ||
                  item.OrderStatus === 'performed' ||
                  item.OrderStatus === 'reported') &&
                item.CustomerOrderNumber
              }
              propertyIcon="confirmation_number"
              propertyText={`Kundens bokningsnummer: ${item.CustomerOrderNumber}`}
            />
            <AssignmentOverviewProperty
              isDisplay={
                item.OrderStatus === 'inquiry' ||
                item.OrderStatus === 'accept' ||
                item.OrderStatus === 'performed' ||
                item.OrderStatus === 'reported'
              }
              propertyIcon="perm_contact_calendar"
              propertyText={`Tolkanvändare: ${item.ContactPerson?.ContactPersonName}`}
            />
            <AssignmentOverviewProperty
              isDisplay={item.OrderStatus !== 'cancelled' && item.NotesResource}
              propertyIcon="message"
              className="mobile-expand"
              propertyText={item.NotesResource}
            />
            <AssignmentOverviewProperty
              isDisplay={
                item.OrderStatus !== 'inquiry' &&
                item.OrderStatus === 'cancelled'
              }
              propertyIcon="message"
              className="mobile-expand"
              propertyText={item.RemunerationDetails}
            />
            <AssignmentOverviewProperty
              isDisplay={
                item.ArticleName.includes('Utgående') &&
                (item.OrderStatus === 'accept' ||
                  item.OrderStatus === 'performed')
              }
              propertyIcon="phone_in_talk"
              propertyText={
                item.MeetingDetails?.MeetingPhoneNumber || item.PhoneNumberOrder
              }
            />
            <AssignmentOverviewProperty
              isDisplay={
                item.ArticleName.includes('Video') &&
                (item.OrderStatus === 'accept' ||
                  item.OrderStatus === 'performed')
              }
              isLink
              propertyIcon="link"
              propertyText={item.MeetingDetails?.MeetingUrl}
            />
            <AssignmentOverviewProperty
              isDisplay={
                item.ArticleName.includes('Video') &&
                (item.OrderStatus === 'accept' ||
                  item.OrderStatus === 'performed') &&
                item.MeetingDetails?.DoRequireBankIdVerification
              }
            >
              <div className="tv-assignment-overview__text">
                <i
                  className="tv-assignment-overview--bankid-icon"
                  style={{
                    backgroundImage: 'url("/images/bankid.png")',
                  }}
                />
                <div className="tv-assignment-overview__text">BankID krävs</div>
              </div>
            </AssignmentOverviewProperty>
            <AssignmentOverviewProperty
              isDisplay={
                (item.ArticleName.includes('Video') ||
                  item.ArticleName.includes('Utgående')) &&
                (item.OrderStatus === 'accept' ||
                  item.OrderStatus === 'performed') &&
                item.MeetingDetails?.MeetingPassCode
              }
              propertyIcon="lock"
              propertyText={item.MeetingDetails?.MeetingPassCode}
            />
            <AssignmentOverviewProperty
              isDisplay={
                (item.ArticleName.includes('Kont') ||
                  item.ArticleName.includes('Simul')) &&
                (item.OrderStatus === 'accept' ||
                  item.OrderStatus === 'inquiry')
              }
              className={item.IsDeviatingAddressPerforming ? 'red-text' : ''}
              propertyIcon="business"
              propertyText={formatAddress(
                item.AddressPerformingEffective,
                item.IsDeviatingAddressPerforming,
              )}
            />
            <AssignmentOverviewProperty
              isDisplay={item.OrderStatus === 'reported' && item.RatingResource}
            >
              <div>
                <div className="tv-assignment-overview__text">Betyg</div>
                <StarRating
                  defaultValue={item.RatingResource || null}
                  viewMode
                />
              </div>
            </AssignmentOverviewProperty>
          </div>

          <div className="tv-assignment-overview__footer">
            {item.OrderStatus === 'inquiry' && (
              <>
                <Button
                  className="left-button"
                  label="Tacka nej"
                  palette="outline"
                  onClick={() =>
                    onReject(false, item.ResourceRequestIdentifier)
                  }
                />
                <Button
                  className="right-button"
                  label="Acceptera"
                  onClick={() => onAccept(true, item.ResourceRequestIdentifier)}
                />
              </>
            )}
            {item.OrderStatus === 'accept' && (
              <div className="button-group-wrapper">
                <div className="button-wrapper">
                  <Button
                    className="full-button"
                    label="Lämna tillbaka uppdrag"
                    palette="primary"
                    onClick={() => setShowReturnConfirmationModal(true)}
                  />
                  {/* <Button
                    className="full-button"
                    label="Letar efter en ersättare..."
                    palette="outline"
                    disabled
                  /> */}
                </div>
              </div>
            )}
            {item.OrderStatus === 'performed' && (
              <Button
                className="full-button"
                label="Fyll i tidrapport"
                palette="primary"
                onClick={() => {
                  setShowTimeSheetModal(true);
                }}
              />
            )}
            {item.OrderStatus === 'reported' && (
              <Button
                className="full-button"
                label="Se tidrapport"
                palette="primary"
                onClick={() => {
                  setShowTimeSheetModal(true);
                }}
              />
            )}
            {item.OrderStatus === 'cancelled' && (
              <>
                <Button
                  className="full-button "
                  label="Kontakta Transvoice"
                  palette="outline"
                  onClick={() => {
                    setShowContactModal(true);
                  }}
                />
                {item.IsVisible && (
                  <Button
                    className="full-button"
                    label="Ta bort från kalendern"
                    palette="primary"
                    onClick={() => {
                      onHideAssignment(item);
                    }}
                  />
                )}
              </>
            )}
            {item.OrderStatus === 'replanning' && (
              <div className="button-group-wrapper">
                <div className="button-wrapper">
                  <Button
                    className="full-button"
                    label="Avbryt Lämna tillbaka uppdrag"
                    palette="outline"
                    onClick={() =>
                      cancelReplanningRequest({
                        WorkAssignmentIdentifier: item.WorkAssignmentIdentifier,
                      })
                    }
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </AssignmentOverviewContainer>
      {showReturnConfirmationModal && (
        <AssignmentReturnConfirmationModal
          isVisible={showReturnConfirmationModal}
          onClose={() => setShowReturnConfirmationModal(false)}
          onClick={() => {
            // setShowReturnSuccessModal(true);
            setShowReturnConfirmationModal(false);
            createReturnAssignment({
              WorkAssignmentIdentifier: item.WorkAssignmentIdentifier,
            });
          }}
        />
      )}
      {showReturnSuccessModal && (
        <AssignmentReturnSuccessModal
          isVisible={showReturnSuccessModal}
          onClose={() => setShowReturnSuccessModal(false)}
          onClick={() => {
            setShowReturnSuccessModal(false);
            setShowReturnFailedModal(true);
          }}
        />
      )}
      {showReturnFailedModal && (
        <AssignmentReturnFailedModal
          isVisible={showReturnFailedModal}
          onClose={() => {
            setShowReturnFailedModal(false);
            setShowCancelModal(true);
          }}
          onClick={() => setShowReturnFailedModal(false)}
        />
      )}
      {showCancelModal && (
        <AssignmentCancelModal
          isVisible={showCancelModal}
          onClose={() => setShowCancelModal(false)}
          onClick={() => setShowCancelModal(false)}
        />
      )}
      {showContactModal && (
        <AssignmentContactModal
          isVisible={showContactModal}
          onClose={() => setShowContactModal(false)}
          onClick={() => setShowContactModal(false)}
        />
      )}
      {showTimeSheetModal && (
        <AssignmentTimeReportEditContainer
          item={item}
          isVisible={showTimeSheetModal}
          isEdit={item.CanBeTimeReported}
          onClose={() => setShowTimeSheetModal(false)}
        />
      )}
    </>
  );
}

AssignmentOverview.propTypes = {
  item: PropTypes.shape({}),
  onClickClose: PropTypes.func,
  onAccept: PropTypes.func,
  onReject: PropTypes.func,
  NavigationClickHandler: PropTypes.func,
  onHideAssignment: PropTypes.func,
  onAssignmentEarlyCompleted: PropTypes.func,
};
AssignmentOverview.defaultProps = {
  item: {},
  onClickClose: () => {},
  onAccept: () => {},
  onReject: () => {},
  NavigationClickHandler: () => {},
  onHideAssignment: () => {},
  onAssignmentEarlyCompleted: () => {},
};

export default AssignmentOverview;

const AssignmentOverviewProperty = ({
  children,
  isDisplay = true,
  propertyIcon,
  propertyText,
  className = '',
  isLink = false,
  isLabelled = false,
}) =>
  isDisplay && (
    <div
      className={`tv-assignment-overview__content-area ${
        isLabelled && 'labelled'
      } ${className}`}
    >
      {!children ? (
        <div className="tv-assignment-overview__text" title={propertyText}>
          <i className="material-icons">{propertyIcon}</i>
          {!isLink ? (
            <span>{propertyText}</span>
          ) : (
            <a href={propertyText} target="__blank">
              {propertyText}
            </a>
          )}
        </div>
      ) : (
        children
      )}
    </div>
  );
