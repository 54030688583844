/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Translate, Button, CheckboxCollapse, Checkbox } from 'components';
import './style.scss';

function PreferenceSettings({
  preferenceLevel,
  areaOfBusinessPreferences,
  articlePreferences,
  contractGroupPreferences,
  onArticleChange,
  onAreaOfBusinessChange,
  onContractGroupChange,
  onContractGroupArticleChange,
  checkArticleOfTypeDisable,
  onSubmit,
  showContent,
}) {
  const [secondView, setSecondView] = useState(false);
  const [previewState, setPreviewState] = useState(false);

  return (
    <>
      <div
        className={`tv-preference-setting__container ${
          previewState && 'tv-preference-setting__container--preview'
        }`}
      >
        <h4 className="tv-preference-setting__title">
          {Translate({ content: 'preference.title' })}
        </h4>
        <p className="tv-preference-setting__content">
          {Translate({ content: 'preference.description' })}
        </p>
        {showContent ? (
          <>
            {!secondView && (
              <div className="tv-preference-setting__wrapper">
                {previewState && (
                  <>
                    <h4 className="tv-preference-setting__title-2">
                      {Translate({ content: 'preference.previewTitle' })}
                    </h4>
                    <p className="tv-preference-setting__content">
                      {Translate({ content: 'preference.previewDescription' })}
                    </p>
                  </>
                )}

                <h4 className="tv-preference-setting__title-2">
                  {Translate({ content: 'preference.question1' })}
                </h4>

                {!previewState ? (
                  <ArticlePreferences
                    data={articlePreferences}
                    onChange={onArticleChange}
                  />
                ) : (
                  <ArticlePreferences
                    data={articlePreferences.filter(x => x.IsDesiredByResource)}
                    isPreview
                  />
                )}
              </div>
            )}

            {(preferenceLevel === 2 || preferenceLevel === 3) && !secondView && (
              <div className="tv-preference-setting__wrapper">
                <h4 className="tv-preference-setting__title-2">
                  {Translate({ content: 'preference.question2' })}
                </h4>

                {!previewState ? (
                  <AreaOfBusinessPreferences
                    data={areaOfBusinessPreferences}
                    onChange={onAreaOfBusinessChange}
                  />
                ) : (
                  <AreaOfBusinessPreferences
                    data={areaOfBusinessPreferences.filter(
                      x => x.IsDesiredByResource,
                    )}
                    isPreview
                  />
                )}
              </div>
            )}

            {preferenceLevel === 3 && secondView && (
              <div className="tv-preference-setting__wrapper">
                <h4 className="tv-preference-setting__title-2">
                  {Translate({ content: 'preference.question3' })}
                </h4>
                <CheckboxCollapse
                  data={contractGroupPreferences}
                  onArticleChange={onArticleChange}
                  onContractGroupChange={onContractGroupChange}
                  onContractGroupArticleChange={onContractGroupArticleChange}
                  checkArticleOfTypeDisable={checkArticleOfTypeDisable}
                />
              </div>
            )}
            {preferenceLevel === 3 && previewState && (
              <div className="tv-preference-setting__wrapper">
                <h4 className="tv-preference-setting__title-2">
                  {Translate({ content: 'preference.question3' })}
                </h4>
                <ContaractGroupPreview
                  data={contractGroupPreferences.filter(
                    x => x.IsDesiredByResource,
                  )}
                  checkArticleOfTypeDisable={checkArticleOfTypeDisable}
                />
              </div>
            )}
            <div className="tv-preference-setting__button-wrapper">
              {previewState ? (
                <Button
                  onClick={() => {
                    onSubmit();
                    setPreviewState(false);
                  }}
                  label={Translate({ content: 'preference.update' })}
                  className="tv-preference-setting__save"
                />
              ) : (
                <Button
                  onClick={() => {
                    setSecondView(false);
                    setPreviewState(true);
                  }}
                  label={Translate({ content: 'preference.save' })}
                  className="tv-preference-setting__save"
                />
              )}
              {preferenceLevel === 3 && !secondView && !previewState && (
                <Button
                  onClick={() => setSecondView(true)}
                  className="tv-preference-setting__text-button"
                  palette="text"
                  label="Avancerad uppdragsväljare"
                />
              )}
              {preferenceLevel === 3 && secondView && !previewState && (
                <Button
                  onClick={() => setSecondView(false)}
                  className="tv-preference-setting__text-button"
                  palette="text"
                  label="Gå tillbaka till"
                />
              )}
              {previewState && (
                <Button
                  onClick={() => setPreviewState(false)}
                  className="tv-preference-setting__text-button"
                  palette="text"
                  label="Gå tillbaka till"
                />
              )}
            </div>
          </>
        ) : (
          <span>Kontakta Transvoice to have preference activate.</span>
        )}
      </div>
    </>
  );
}

PreferenceSettings.propTypes = {
  preferenceLevel: PropTypes.number,
  areaOfBusinessPreferences: PropTypes.arrayOf(PropTypes.shape({})),
  articlePreferences: PropTypes.arrayOf(PropTypes.shape({})),
  contractGroupPreferences: PropTypes.arrayOf(PropTypes.shape({})),
};

PreferenceSettings.defaultProps = {
  preferenceLevel: 1,
  areaOfBusinessPreferences: [],
  articlePreferences: [],
  contractGroupPreferences: [],
};

export default PreferenceSettings;

const ArticlePreferences = ({
  data = [],
  onChange = () => {},
  isPreview = false,
}) => (
  <div>
    {data.map(subitem => (
      <Checkbox
        key={subitem.ArticleIdentifier}
        text={subitem.ArticleName}
        disabled={isPreview}
        inputName={subitem.ArticleIdentifier}
        checked={subitem.IsDesiredByResource || false}
        onClick={() => onChange(subitem)}
      />
    ))}
  </div>
);

const AreaOfBusinessPreferences = ({
  data = [],
  onChange = () => {},
  isPreview = false,
}) => (
  <div>
    {data.map(subitem => (
      <Checkbox
        key={subitem.AreaOfBusinessName}
        text={subitem.AreaOfBusinessName}
        disabled={isPreview}
        inputName={subitem.AreaOfBusinessIdentifier}
        checked={subitem.IsDesiredByResource || false}
        onClick={() => {
          onChange(subitem.AreaOfBusinessIdentifier);
        }}
      />
    ))}
  </div>
);

const ContaractGroupPreview = ({ data = [],checkArticleOfTypeDisable }) => (
  <>
    {data.map(item => (
      <div>
        <Checkbox
          text={item.ContractGroupName}
          disabled
          value={item.ContractGroupIdentifier}
          checked={item.IsDesiredByResource || false}
          onClick={e => {}}
        />
        <div className="tv-preference-setting__preview-article-container">
          {item.ContractGroupArticlePreferences.filter(
            x => x.IsDesiredByResource && !checkArticleOfTypeDisable(x),
          ).map(subitem => (
            <span>{subitem.ArticleName}</span>
          ))}
        </div>
      </div>
    ))}
  </>
);
