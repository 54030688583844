/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Translate from '../Translate';
import Button from '../Button';

import('./style.scss');

function AssignmentRequestHandlers({ item, className, onAccept, onReject, onShowInCalendar }) {
  const history = useHistory();
  return (
    <div className={`tv-assignment-request-handler__container ${className}`}>
      <p
        className="tv-assignment-request-handler__text my-auto"
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          onShowInCalendar(item.NotificationIdentifier);
          history.push({
            pathname: '/',
            state: { dateFocused: item.DatetimeOrderFrom, assigment: item },
          });
        }}
      >
        {Translate({ content: 'assignment.showInCalendar' })}
      </p>
      <Button
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          onReject(
            false,
            item.ResourceRequestIdentifier,
            item.WorkAssignmentIdentifier,
            item.NotificationIdentifier
          );
        }}
        className="tv-assignment-request-handler__btn tv-assignment-request-handler__btn-reject"
        label={<i className="material-icons">close</i>}
      />
      <Button
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          onAccept(
            true,
            item.ResourceRequestIdentifier,
            item.WorkAssignmentIdentifier,
            item.NotificationIdentifier
          );
        }}
        className="tv-assignment-request-handler__btn tv-assignment-request-handler__btn-accept"
        label={<i className="material-icons">check</i>}
      />
    </div>
  );
}

AssignmentRequestHandlers.propTypes = {
  className: PropTypes.string,
  onAccept: PropTypes.func,
  onReject: PropTypes.func,
  onShowInCalendar: PropTypes.func,
  item: PropTypes.shape({
    ResourceRequestIdentifier: PropTypes.string,
    WorkAssignmentIdentifier: PropTypes.string,
    NotificationIdentifier: PropTypes.string,
    DatetimeOrderFrom: PropTypes.string,
  }),
};

AssignmentRequestHandlers.defaultProps = {
  className: '',
  onAccept: () => {},
  onReject: () => {},
  onShowInCalendar: () => {},
  item: {
    ResourceRequestIdentifier: '',
    WorkAssignmentIdentifier: '',
    NotificationIdentifier: '',
    DatetimeOrderFrom: '',
  },
};

export default AssignmentRequestHandlers;
