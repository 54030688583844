import { initialState } from './selectors';
import {
  DISPLAY_RATED_ASSIGNMENT,
  HIDE_RATED_ASSIGNMENT,
  WORK_ASSIGNMENT_HIDE,
} from './actions';

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case WORK_ASSIGNMENT_HIDE:
      return {
        ...state,
        hiddenAssignment: payload,
      };
    case DISPLAY_RATED_ASSIGNMENT:
      return {
        ...state,
        isRatedAssignment: payload.isRatedAssignment,
        ratedAssignment: payload.ratedAssignment,
      };
    case HIDE_RATED_ASSIGNMENT:
      return {
        ...state,
        isRatedAssignment: initialState.isRatedAssignment,
        ratedAssignment: initialState.ratedAssignment,
      };
    default:
      return state;
  }
};
