/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import './style.scss';
import { useCalendar } from 'context/CalendarContext';
import {
  SidebarAssignmentList,
  CalendarNavigator,
  Button,
  Translate,
  Toggle,
  AssignmentRequestModal,
  StatusFilter,
  Spinner,
} from 'components';
import { modalTypes } from 'constant';
import { AssignmentOverviewContainer } from 'containers';

const DashboardSideBar = () => {
  const {
    selectedCalendarMonthPill,
    handleAssignmentPillClick,
    calendarDateRange,
    selectedCalendarType,
    onCalendarNavigatorChange,
    availabiltyFilterOptions,
    onAvailabilityFilter,
    isAvailabilityFilterApplied,
    onStatusFilterChange,
    selectedStatusFilter,
    onApplyAllFilter,
    activeAssignment,
    visibleOverview,
    onNavigationClick,
    onOverviewClose,
    onWorkAssignmentClick,
    replyToAssignmentRequest,
    searchSingleWorkAssignment,
    removeRequestReplied,
    assignmentRequestFulfilled,
    cleanReplyToRequest,
    isLoading,
    hideOverview,
    showFilter,
    toggleFilters,
    onAvailabilityExceptionClick,
  } = useCalendar();

  const [localSelectedStatus, setLocalSelectedStatus] = useState(
    selectedStatusFilter || [],
  );

  const [localSelectedAvilability, setLocalSelectedAvilability] = useState(
    availabiltyFilterOptions || [],
  );
  const [visibleAvailabilityOption, setVisibleAvailabilityOption] = useState(
    'Min tillgänglighet',
  );
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [assignmentRequestAction, setAssignmentRequestAction] = useState({});

  useEffect(() => {
    if (assignmentRequestFulfilled) {
      removeRequestReplied(assignmentRequestAction.requestId);
      if (assignmentRequestAction?.action) {
        searchSingleWorkAssignment(activeAssignment.WorkAssignmentIdentifier);
      } else {
        hideOverview();
      }
      setAssignmentRequestAction({});
      cleanReplyToRequest();
    }
  }, [assignmentRequestFulfilled]);

  const handleAssignmentRequestModalActions = (action, modalType) => {
    const query = {
      IsAcceptingRequest: assignmentRequestAction.action,
      ResourceRequestIdentifier: assignmentRequestAction.requestId,
    };

    if (modalType === modalTypes.APPROVE) {
      if (action === modalTypes.APPROVE) {
        replyToAssignmentRequest(query);
        setShowApproveModal(!showApproveModal);
      } else {
        setShowApproveModal(!showApproveModal);
      }
    } else if (action === modalTypes.APPROVE) {
      setShowRejectModal(!showRejectModal);
    } else {
      replyToAssignmentRequest(query);
      setShowRejectModal(!showRejectModal);
    }
  };

  const renderAssignmentRequestModals = () => {
    if (showApproveModal) {
      return (
        <AssignmentRequestModal
          isVisible={showApproveModal}
          modalType={modalTypes.APPROVE}
          onClose={() => setShowApproveModal(!showApproveModal)}
          title="Godkänn allmänna villkor"
          showCheckBox
          rejectButtonLabel="Avbryt"
          acceptanceButtonLabel="Acceptera uppdraget"
          onClick={action =>
            handleAssignmentRequestModalActions(action, modalTypes.APPROVE)
          }
        >
          <p>
            Genom att acceptera uppdraget godkänner du våra allmänna villkor och
            riktlinjer för tolkuppdrag
          </p>
        </AssignmentRequestModal>
      );
    }
    if (showRejectModal) {
      return (
        <AssignmentRequestModal
          isVisible={showRejectModal}
          modalType={modalTypes.REJECT}
          onClose={() => {
            setShowRejectModal(!showRejectModal);
          }}
          title="Neka förfrågan"
          acceptanceButtonLabel="Neka förfrågan"
          rejectButtonLabel="Avbryt"
          onClick={action => {
            handleAssignmentRequestModalActions(action, modalTypes.REJECT);
          }}
        >
          <p>
            Är du säker på att du vill neka uppdraget? Om du nekar många
            förfrågningar så kan det komma att påverka hur många framtida
            förfrågningar du får
          </p>
        </AssignmentRequestModal>
      );
    }
    return null;
  };

  useEffect(() => {
    let tempVisibleAvailabilityOption = 'Välj tillgänglighet';

    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < localSelectedAvilability.length; index++) {
      const element = localSelectedAvilability[index];
      if (element.isActive) {
        tempVisibleAvailabilityOption = element.displayText;
        break;
      }
    }
    setVisibleAvailabilityOption(tempVisibleAvailabilityOption);
  }, [localSelectedAvilability]);

  useEffect(() => {
    setLocalSelectedStatus(selectedStatusFilter);
  }, [selectedStatusFilter]);

  useEffect(() => {
    setLocalSelectedAvilability(availabiltyFilterOptions);
  }, [availabiltyFilterOptions]);

  const handleStatusChange = values => {
    if (showFilter) {
      setLocalSelectedStatus([...values]);
    } else {
      onStatusFilterChange(values);
    }
  };

  const handleApplyFilter = () => {
    onApplyAllFilter(localSelectedStatus, localSelectedAvilability);
    toggleFilters(false);
  };

  const handleCancelFilter = () => {
    setLocalSelectedAvilability(availabiltyFilterOptions);
    toggleFilters(false);

    // setLocalSelectedStatus(selectedStatusFilter);
  };

  const assignmentRequestHandler = (action, requestId) => {
    if (action) {
      setShowApproveModal(!showApproveModal);
    } else {
      setShowRejectModal(!showRejectModal);
    }
    setAssignmentRequestAction({ action, requestId });
  };

  const onAssignmentEarlyCompleted = assignmentId => {
    searchSingleWorkAssignment(assignmentId);
  };
  const toggleHandler = () => {
    onAvailabilityFilter(!isAvailabilityFilterApplied);
  };

  return (
    <div className="tv-dashboard_side_bar__container">
      {!(
        selectedCalendarMonthPill &&
        selectedCalendarMonthPill.list &&
        selectedCalendarMonthPill.date
      ) &&
        !visibleOverview && (
          <div
            className={
              showFilter ? 'tv-dashboard_side_bar__filter' : 'tv-mobile--hide'
            }
          >
            <MobileHeader toggleFilters={toggleFilters} />
            <CalendarNavigator
              range={calendarDateRange}
              selectedCalendar={selectedCalendarType}
              onSelectDate={onCalendarNavigatorChange}
            />
            {selectedCalendarType !== 'month' && (
              <div className="d-flex flex-column mt-2 mb-2">
                <span className="tv-dashboard_side_bar__sub-header">
                  Tillgänglighet
                </span>
                <div className="d-flex mt-3">
                  <div className="mr-2">
                    <Toggle
                      onChange={toggleHandler}
                      checked={isAvailabilityFilterApplied}
                    />
                  </div>
                  <span className="tv-dashboard_side_bar__filter-label">
                    Visa endast tillgänglig tid{' '}
                  </span>
                </div>
              </div>
            )}
            {(!isAvailabilityFilterApplied ||
              selectedCalendarType === 'month') && (
              <StatusFilter
                selectedFilters={localSelectedStatus}
                onClick={x => {
                  handleStatusChange(x);
                }}
              />
            )}
            <div className="tv-calendar__mobile-footer">
              <Button
                label={Translate({ content: 'general.cancel' })}
                palette="outline"
                onClick={handleCancelFilter}
              />
              <Button
                label={Translate({ content: 'calendar.filter' })}
                onClick={handleApplyFilter}
              />
            </div>
          </div>
        )}
      {selectedCalendarMonthPill &&
        selectedCalendarMonthPill.list &&
        selectedCalendarMonthPill.date &&
        !visibleOverview && (
          <SidebarAssignmentList
            onClose={() => handleAssignmentPillClick(null)}
            list={selectedCalendarMonthPill.list}
            date={selectedCalendarMonthPill.date}
            availabilityList={selectedCalendarMonthPill.availabilityList || []}
            onWorkAssignmentClick={onWorkAssignmentClick}
            onAvailabilityExceptionClick={onAvailabilityExceptionClick}
          />
        )}
      {visibleOverview && (
        <AssignmentOverviewContainer
          NavigationClickHandler={onNavigationClick}
          onClickClose={onOverviewClose}
          onAccept={assignmentRequestHandler}
          onReject={assignmentRequestHandler}
          item={activeAssignment}
          onAssignmentEarlyCompleted={onAssignmentEarlyCompleted}
        />
      )}
      {isLoading && <Spinner />}
      {renderAssignmentRequestModals()}
    </div>
  );
};

export default DashboardSideBar;

// eslint-disable-next-line react/prop-types
const MobileHeader = ({ toggleFilters }) => (
  <>
    <div className="tv-dashboard_side_bar__header">
      <div className="tv-dashboard_side_bar__header-title">
        {Translate({ content: 'calendar.filterMenue' })}
      </div>
      <div
        className="tv-dashboard_side_bar__header-close material-icons"
        onClick={() => toggleFilters(false)}
      >
        clear
      </div>
    </div>
  </>
);
