import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Logo } from 'components';
import './style.scss';

export const LoginLayout = ({ children }) => (
  <>
    <div className="tv-login-layout__container">
      <Logo />
      <div className="tv-login-layout__form-container">{children} </div>
    </div>
  </>
);

LoginLayout.propTypes = {
  children: PropTypes.node,
};
LoginLayout.defaultProps = {
  children: null,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(LoginLayout);
