import { ERROR_ADD_STORE, ERROR_REMOVE_STORE } from './actions';
import initialState from './selector';

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ERROR_ADD_STORE:
      return {
        activeError: payload.errorMessage,
      };
    case ERROR_REMOVE_STORE:
      return {
        activeError: null,
      };
    default:
      return state;
  }
};
