import React from 'react';
import { get24HoursInday } from 'utils/dateTimeUtils';
import './style.scss';

function WrappedCalendarTimeLine() {
  const hours = get24HoursInday();
  return (
    <div className="tv-calendar-timeline">
      {hours.map(hour => (
        <div
          className="tv-calendar-timeline__frame"
          key={`calendar-timeline-${hour}`}
        >
          <div className="tv-calendar-timeline__time">{hour}</div>
          <div className="tv-calendar-timeline__tick-container">
            <CalendarTimeLineTicks key="tick-container" />
          </div>
        </div>
      ))}
    </div>
  );
}
const CalendarTimeLine = React.memo(WrappedCalendarTimeLine);
export default CalendarTimeLine;

const CalendarTimeLineTicks = React.memo(() =>
  [...Array(15).keys()].map(colIndex => (
    <div
      className="tv-calendar-timeline__tick"
      key={`calendar-timeline-tick-${colIndex}`}
    />
  )),
);
