import React, { useContext, useEffect, useState } from 'react';
import {
  TabButtonRow,
  CalendarRangeNavigator,
  MonthCalendar,
  WeekCalendar,
  DayCalendar,
  Button,
  Loader,
  Translate,
  RemoveSickLeave,
  AvailabilityMenuDropdown,
  LeaveTypeMenuDropdown,
} from 'components';
import {
  ExceptionAvailabilityContainer,
  SemesterAllocationContainer,
} from 'containers';
import CalendarContext from 'context/CalendarContext';
import './style.scss';
import { currentDate } from 'utils/dateTimeUtils';
import { fromResource } from 'store/selectors';
import { connect, useSelector } from 'react-redux';
import {
  displayRemoveSickLeaveButton,
  resourceListReadRequest,
} from 'store/actions';
import PropTypes from 'prop-types';
import { resourceCreateRequest } from 'store/resource/actions';
import { fulfilled, pending, clean } from 'redux-saga-thunk';
import Spinner from 'components/atoms/Spinner';

const tabs = [
  { name: 'month', label: 'Månad' },
  { name: 'week', label: 'Vecka' },
  { name: 'day', label: 'Dag' },
];

const selectedCalendar = {
  month: <MonthCalendar key="monthCalendar-1" />,
  week: <WeekCalendar />,
  day: <DayCalendar singleDay />,
};

function CalendarLayout({
  onDisplayButton,
  onCancelIllness,
  isCancellingIllnessPending,
  isCancellingIllnessFulfilled,
  getResourceAvailability,
}) {
  const {
    selectedCalendarType,
    setCalendarType,
    calendarDateRange,
    handleCalendarTravel,
    isLoading,
    onTodayClick,
    toggleFilters,
    removeActiveExceptionAvailability,
    activeExceptionAvailability,
    activeSemesterAllocation,
    removeActiveSemesterAllocation,
  } = useContext(CalendarContext);

  const [navigatorText, setNavigatorText] = useState(null);
  const [
    displayExceptionAvailabilityModal,
    setDisplayExceptionAvailabilityModal,
  ] = useState(false);
  const [
    displaySemesterAllocationModal,
    setDisplaySemesterAllocationModal,
  ] = useState(false);
  const [displayCancelSickLeave, setDisplayCancelSickLeave] = useState(false);

  const handleCloseExceptionModal = () => {
    setDisplayExceptionAvailabilityModal(false);
    removeActiveExceptionAvailability();
  };

  const handleCloseSemesterAllocationModal = () => {
    setDisplaySemesterAllocationModal(false);
    removeActiveSemesterAllocation();
  };

  useEffect(() => {
    if (selectedCalendarType === 'week') {
      setNavigatorText(calendarDateRange.start.format('[Vecka] W YYYY'));
    } else if (selectedCalendarType === 'month') {
      setNavigatorText(calendarDateRange.startMonth.format('MMMM YYYY'));
    } else {
      setNavigatorText(calendarDateRange.start.format('D MMMM YYYY'));
    }
  }, [calendarDateRange]);

  useEffect(() => {
    if (activeExceptionAvailability?.exceptionIdentifier) {
      setDisplayExceptionAvailabilityModal(true);
    }
  }, [activeExceptionAvailability]);

  useEffect(() => {
    if (activeSemesterAllocation?.vacationDayIdentifier) {
      setDisplaySemesterAllocationModal(true);
    }
  }, [activeSemesterAllocation]);

  const handleDisplayAvailabilityModal = () => {
    setDisplayExceptionAvailabilityModal(true);
  };

  const updateCancelSickLeaveModal = () => {
    setDisplayCancelSickLeave(!displayCancelSickLeave);
  };

  const removeCancelSickLeave = () => {
    onDisplayButton();
    const params = {
      DateCancelFrom: currentDate().format('YYYY-MM-DD'),
    };
    onCancelIllness(params);
    setDisplayCancelSickLeave(!displayCancelSickLeave);
  };

  const onClickExceptionAvailability = () => {
    setDisplayExceptionAvailabilityModal(true);
  };

  const isInSickLeave = useSelector(state =>
    fromResource.getSickLeaveInfo(state),
  );

  useEffect(() => {
    if (isCancellingIllnessFulfilled) {
      getResourceAvailability();
    }
  }, [isCancellingIllnessFulfilled]);

  return (
    <div className="tv-calendar__container">
      <LeaveTypeMenuDropdown
        onClickExceptionAvailability={onClickExceptionAvailability}
      />
      <div className="tv-calendar__navigation-container">
        <CalendarRangeNavigator
          text={navigatorText}
          onForwardClick={() => handleCalendarTravel('forward')}
          onBackwardClick={() => handleCalendarTravel('backward')}
        />
        <TabButtonRow
          tabs={tabs}
          hideTabInMobileview={tabs[1]}
          activeTab={selectedCalendarType}
          onTabClick={setCalendarType}
        />
        <Button
          label={Translate({ content: 'calendar.today' })}
          className="tv-calendar__naviagte-today"
          palette="outline"
          onClick={() => onTodayClick()}
        />
        {isInSickLeave && (
          <Button
            label={Translate({ content: 'calendar.removeSickLeave' })}
            className="tv-calendar__remove-sick-leave"
            palette="outline"
            onClick={updateCancelSickLeaveModal}
          />
        )}
        {/* <Button
          label={Translate({ content: 'calendar.submitTimeForAvailability' })}
          className="tv-calendar__availability-exception-button"
          onClick={() => setDisplayExceptionAvailabilityModal(true)}
        /> */}
        <AvailabilityMenuDropdown
          displayAvailabilityModal={handleDisplayAvailabilityModal}
          onDisplayModal={updateCancelSickLeaveModal}
        />
      </div>
      {isLoading && <Loader />}
      {selectedCalendar[selectedCalendarType]}
      <div className="tv-calendar__mobile-footer">
        <Button
          label={Translate({ content: 'calendar.filter' })}
          palette="outline"
          onClick={() => toggleFilters(true)}
        />
        {/* <Button
          label={Translate({ content: 'calendar.submitTimeForAvailability' })}
          onClick={() => setDisplayExceptionAvailabilityModal(true)}
        /> */}
        {isInSickLeave && (
          <Button
            label={Translate({ content: 'calendar.removeSickLeave' })}
            className="tv-calendar__remove-sick-leave"
            palette="outline"
            onClick={updateCancelSickLeaveModal}
          />
        )}
      </div>
      {displayExceptionAvailabilityModal && (
        <ExceptionAvailabilityContainer
          enableModal
          isVisible={displayExceptionAvailabilityModal}
          onClose={handleCloseExceptionModal}
          editItem={activeExceptionAvailability}
        />
      )}
      {displaySemesterAllocationModal && (
        <SemesterAllocationContainer
          enableModal
          isModalVisible={displaySemesterAllocationModal}
          onModalClose={handleCloseSemesterAllocationModal}
          editItem={activeSemesterAllocation}
        />
      )}
      {displayCancelSickLeave && (
        <RemoveSickLeave
          onCancel={updateCancelSickLeaveModal}
          onClick={removeCancelSickLeave}
        />
      )}
      {isCancellingIllnessPending && <Spinner />}
    </div>
  );
}

CalendarLayout.propTypes = {
  onDisplayButton: PropTypes.func,
  onCancelIllness: PropTypes.func,
  isCancellingIllnessPending: PropTypes.bool,
  isCancellingIllnessFulfilled: PropTypes.bool,
  getResourceAvailability: PropTypes.func,
};

CalendarLayout.defaultProps = {
  onDisplayButton: () => {},
  onCancelIllness: () => {},
  isCancellingIllnessPending: false,
  isCancellingIllnessFulfilled: false,
  getResourceAvailability: () => {},
};

const STANDARD_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm';

const mapDispatchToProps = dispatch => ({
  onDisplayButton: () => {
    dispatch(displayRemoveSickLeaveButton(false));
  },
  onCancelIllness: params => {
    dispatch(resourceCreateRequest('cancelIllness', params));
  },
  getResourceAvailability: () => {
    const params = {
      DatetimeFrom: currentDate().format(STANDARD_DATE_TIME_FORMAT),
      DatetimeTo: currentDate().format(STANDARD_DATE_TIME_FORMAT),
    };
    dispatch(
      resourceListReadRequest(
        'resourceAvailability',
        'AvailabilityIntervals',
        params,
        true,
      ),
    );
  },
});

const mapStateToProps = state => ({
  isCancellingIllnessPending: pending(state, 'cancelIllnessCreate'),
  isCancellingIllnessFulfilled: fulfilled(state, 'cancelIllnessCreate'),
});

export default connect(mapStateToProps, mapDispatchToProps)(CalendarLayout);
