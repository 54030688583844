/* eslint-disable no-else-return */
/* eslint-disable react/prop-types */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  convertToDayJsObject,
  convertToSWDayJsObject,
} from 'utils/dateTimeUtils';
import { iconForTheServiceType } from 'utils/miscUtil';

function calculateHeightAndPosition(timeFrom, timeTo) {
  const diffFromDaySatrt = timeFrom.diff(timeFrom.startOf('day'), 'minutes');
  const diffInMinutes = timeTo.diff(timeFrom, 'minutes') || 0;
  const fifteenMinPortions = diffInMinutes / 15;
  const fullHourhieghtInPx = 128;
  return {
    height: `${(fullHourhieghtInPx * fifteenMinPortions) / 4}px`,
    yCoordinate: `${(fullHourhieghtInPx * diffFromDaySatrt) / 60}px`,
    diffInMinutes,
  };
}

function determineContactDetail(detail = {}) {
  const type = detail?.ArticleName.toLowerCase();

  let contactDetail = detail?.ArticleName || '';
  let contactDetailHighlight = false;
  if (type.includes('kontak')) {
    contactDetail = detail?.AddressPerformingEffective || '';
    contactDetailHighlight = true;
  } else if (type.includes('skype')) {
    contactDetail = detail?.MeetingDetails?.MeetingPhoneNumber || '';
    contactDetailHighlight = true;
    // eslint-disable-next-line no-empty
  } else if (type.includes('tele')) {
  } else if (type.includes('video')) {
    contactDetail = detail?.MeetingDetails?.MeetingUrl || '';
    contactDetailHighlight = true;
  }
  return {
    contactDetail,
    contactDetailHighlight,
  };
}
class WorkAssignmentItemContainer extends PureComponent {
  constructor(props) {
    super(props);

    const {
      DatetimeOrderFrom,
      DatetimeOrderTo,
      DatetimeExpiration,
      ArticleName,
      Customer,
      ContactPerson,
      status,
    } = props.detail;

    const [timeFrom, timeTo] = [
      convertToDayJsObject(DatetimeOrderFrom),
      convertToDayJsObject(DatetimeOrderTo),
    ];

    this.state = {
      renderDetail: {
        timeFrom,
        timeTo,
        type: ArticleName,
        typeIcon: iconForTheServiceType(ArticleName),
        expiryTime: DatetimeExpiration
          ? convertToSWDayJsObject(DatetimeExpiration)
          : null,
        client: Customer.CustomerName,
        contactPerson: ContactPerson.ContactPersonName,
        status: status || 'performed',
        payment: props.detail?.PreliminaryRemunerationAmountSEK,
        orderNumber: props.detail?.OrderNumber,
        ...determineContactDetail(props.detail),
      },
      ...calculateHeightAndPosition(timeFrom, timeTo),
      zIndex: props.detail?.status === 'cancelled' ? 6 : 7,
    };
  }

  render() {
    const { render: Render, index, isActive } = this.props;
    const {
      height,
      yCoordinate,
      diffInMinutes,
      renderDetail,
      zIndex,
    } = this.state;

    return (
      <Render
        height={height}
        yCoordinate={yCoordinate}
        zIndex={zIndex}
        diffInMinutes={diffInMinutes}
        detail={renderDetail}
        index={index}
        isActive={isActive}
      />
    );
  }
}

WorkAssignmentItemContainer.propTypes = {
  render: PropTypes.func,
  detail: PropTypes.shape({}),
};

WorkAssignmentItemContainer.defaultProps = {
  render: () => {},
  detail: null,
};

export default WorkAssignmentItemContainer;
