import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Translate, Button } from 'components';
import './style.scss';

const SickLeaveConfirmModal = ({ hideModal, onDisplaySuccess }) => (
  <Modal isVisible className="tv-sick-leave-confirmation__container" hideHeader>
    <div className="tv-sick-leave-confirmation__content">
      <div className="tv-sick-leave-confirmation__content__title">
        Bekräfta sjukanmälan
      </div>
      <div className="tv-sick-leave-confirmation__content__body">
        Alla dina uppdrag det närmaste dygnet kommer att tas bort och detta sker
        tillsvidare tills dess att du har friskanmält dig. Du får heller inga
        förfrågningar under den tid som du är sjukanmäld.
      </div>
    </div>
    <div className="tv-sick-leave-confirmation__actions">
      <Button
        label="Avbryt"
        className="tv-sick-leave-confirmation__actions-button"
        palette="outline"
        onClick={hideModal}
      />
      <Button
        label="Lägg till"
        className="tv-sick-leave-confirmation__actions-button"
        onClick={onDisplaySuccess}
      />
    </div>
  </Modal>
);

SickLeaveConfirmModal.propTypes = {
  hideModal: PropTypes.func,
  onDisplaySuccess: PropTypes.func,
};

SickLeaveConfirmModal.defaultProps = {
  hideModal: () => {},
  onDisplaySuccess: () => {},
};

export default SickLeaveConfirmModal;
