import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'components';

import './style.scss';

function AssignmentSuccessModal({
  isVisible,
  onOverlayClick,
  onClick,
  onClose,
}) {
  return (
    <Modal
      className="tv-assignment-return-success-modal__container"
      hideHeader
      onClose={onClose}
      onOverlayClick={onOverlayClick}
      isVisible={isVisible}
    >
      <p>
        <b className="tv-assignment-return-success-modal__title">
          Vi har lämnat tillbaka ditt uppdrag!
        </b>
      </p>
      <p className="tv-assignment-return-success-modal__description">
        Vi har hittat en ersättare till dig och avbokat ditt uppdrag.
      </p>

      <Button
        onClick={onClick}
        className="tv-assignment-return-success-modal__button"
        label="Toppen!"
      />
    </Modal>
  );
}

AssignmentSuccessModal.propTypes = {
  isVisible: PropTypes.bool,
  onOverlayClick: PropTypes.func,
  onClose: PropTypes.func,
  onClick: PropTypes.func,
};
AssignmentSuccessModal.defaultProps = {
  isVisible: false,
  onOverlayClick: () => {},
  onClose: () => {},
  onClick: () => {},
};

export default AssignmentSuccessModal;
