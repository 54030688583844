import React from 'react';
import PropTypes from 'prop-types';
import { Translate, Avatar } from 'components';
import './style.scss';
import { LOCAL_STORE_INTERPRETER_NUMBER } from 'constant';

const UserProfile = ({
  profile: {
    FirstName,
    LastName,
    MobilePhoneNumber,
    WorkPhoneNumber,
    PostalAddress,
    EmailAddress,
  },
}) => {
  const interpreterNumber = localStorage.getItem(
    LOCAL_STORE_INTERPRETER_NUMBER,
  );
  return (
    <div className="tv-myprofile tv-myprofile__wrapper">
      <div className="tv-myprofile__header-wrapper">
        <div className="tv-myprofile__avatar">
          <Avatar className="tv-myprofile__avatar-img" />
        </div>
        <div className="tv-myprofile__header-text">
          <div className="tv-myprofile__name">{`${FirstName} ${LastName}`}</div>
          <div className="tv-myprofile__client-number">
            <Translate content="yourProfile.clientNumber" />:{' '}
            {interpreterNumber || '-'}
          </div>
        </div>
      </div>
      <div className="tv-myprofile__content-wrapper">
        <div className="tv-myprofile__field-row">
          <div className="tv-myprofile__field">
            <Translate content="yourProfile.address" />
            <div className="tv-myprofile_value">
              <div>{PostalAddress.StreetAddress}</div>
              <div>{PostalAddress.PostalCode}</div>
              <div>{PostalAddress.City}</div>
            </div>
          </div>
          <div className="tv-myprofile__field">
            <div>
              <div className="tv-myprofile__field">
                <Translate content="yourProfile.phone" />
                <div className="tv-myprofile_value ls-2">
                  <div>{MobilePhoneNumber || '-'}</div>
                </div>
              </div>
            </div>
            <div className="mt-3">
              <div className="tv-myprofile__field">
                <Translate content="yourProfile.workPhoneNumber" />
                <div className="tv-myprofile_value ls-2">
                  <div>{WorkPhoneNumber || '-'}</div>
                </div>
              </div>
            </div>
          </div>

          <div className="tv-myprofile__field tv-mobile--hide">
            <Translate content="yourProfile.email" />
            <div className="tv-myprofile_value">
              <div>{EmailAddress}</div>
            </div>
          </div>
        </div>
        <hr className="tv-myprofile_hr" />
      </div>
    </div>
  );
};

UserProfile.propTypes = {
  profile: PropTypes.shape({
    FirstName: PropTypes.string,
    LastName: PropTypes.string,
    PostalAddress: PropTypes.shape({
      StreetAddress: PropTypes.string,
      PostalCode: PropTypes.number,
      City: PropTypes.string,
    }),
    WorkPhoneNumber: PropTypes.string,
    MobilePhoneNumber: PropTypes.string,
    customerNumber: PropTypes.string,
    EmailAddress: PropTypes.string,
  }),
};

UserProfile.defaultProps = {
  profile: {
    FirstName: '',
    LastName: '',
    PostalAddress: {
      StreetAddress: '',
      PostalCode: 0,
      City: '',
    },
    WorkPhoneNumber: '-',
    MobilePhoneNumber: '-',
    customerNumber: null,
    EmailAddress: '-',
  },
};

export default UserProfile;
