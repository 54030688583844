import React from 'react';
import PropTypes from 'prop-types';
import { TableList, CalendarRangeNavigator, Translate } from 'components';
import { compareObjectBasedOnDate } from 'utils/dateTimeUtils';

import('./style.scss');

function Compensation({
  list,
  navigatorText,
  handleCalendarTravel,
  onDownloadClick,
}) {
  return (
    <div className="tv-compensation__container">
      <h2 className="tv-compensation_title">
        {Translate({ content: 'settings.compensation' })}
      </h2>
      <div>
        <p>OBS! Ingen lönespecifikation i app eller portal denna månad </p>

        <p>
          För att ta del av din lönespecifikation denna månad så gäller
          följande:
          <br />
          Om du är aktiv lönetolk så har du fått ett mail från MyPayslip. <br />
          Läs noga hur du gör för att aktivera ditt konto och ladda ner appen.
          Du kan också läsa mer{' '}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.hogia.se/affarssystem/artiklar/login-guide-mypayslip"
          >
            här
          </a>
          .
        </p>

        <p>
          Vi beklagar besväret och jobbar för att ha tillbaka
          lönespecifikationen i vår app och portal snarast möjligt. Har du några
          frågor så kontakta oss på 08-120 800 75.
        </p>
      </div>
      <div className="tv-compensation_navigator">
        <CalendarRangeNavigator
          text={navigatorText}
          onForwardClick={() => handleCalendarTravel('forward')}
          onBackwardClick={() => handleCalendarTravel('backward')}
        />
      </div>
      <div className="tv-compensation_table">
        {Object.keys(list).map(key => (
          <>
            <div className="tv-compensation__month-block">{key}</div>
            <TableList
              list={list[key].sort((a, b) =>
                compareObjectBasedOnDate(a, b, 'FileCreatedDateTime'),
              )}
              key={key}
              onDownloadClick={onDownloadClick}
            />
          </>
        ))}
      </div>
    </div>
  );
}

Compensation.propTypes = {
  list: PropTypes.shape({}),
  navigatorText: PropTypes.string,
  handleCalendarTravel: PropTypes.func,
  onDownloadClick: PropTypes.func,
};
Compensation.defaultProps = {
  list: {},
  navigatorText: '',
  handleCalendarTravel: () => {},
  onDownloadClick: () => {},
};

export default Compensation;
