/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import TermsAndConditionsModal from 'components/organisms/TermsAndConditionsModal';
import { ReconfirmTermsAndConditions } from 'components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { transvoiceLogout } from 'store/authenticate/actions';
import { useHistory } from 'react-router-dom';

const TermsAndConditionsModalContainer = ({ onLogout }) => {
  const [displayProceedModal, setDisplayProceedModal] = useState(false);

  const rejectTermsAndCondition = () => {
    setDisplayProceedModal(!displayProceedModal);
  };

  const history = useHistory();

  const logout = () => {
    history.push('/login');
    onLogout();
  };
  return (
    <div>
      <TermsAndConditionsModal reject={rejectTermsAndCondition} />
      {displayProceedModal && (
        <ReconfirmTermsAndConditions
          cancel={rejectTermsAndCondition}
          accept={logout}
        />
      )}
    </div>
  );
};

TermsAndConditionsModalContainer.propTypes = {
  onLogout: PropTypes.func,
};

TermsAndConditionsModalContainer.defaultProps = {
  onLogout: () => {},
};

const mapDispatchToProps = dispatch => ({
  onLogout: () => {
    dispatch(transvoiceLogout());
  },
});

export default connect(
  null,
  mapDispatchToProps,
)(TermsAndConditionsModalContainer);
