// https://github.com/diegohaz/arc/wiki/Actions
// https://github.com/diegohaz/arc/wiki/Example-redux-modules#resource
export const RESOURCE_CREATE_REQUEST = 'RESOURCE_CREATE_REQUEST';
export const RESOURCE_CREATE_SUCCESS = 'RESOURCE_CREATE_SUCCESS';
export const RESOURCE_CREATE_FAILURE = 'RESOURCE_CREATE_FAILURE';

export const resourceCreateRequest = (resource, params) => ({
  type: RESOURCE_CREATE_REQUEST,
  payload: { params },
  meta: {
    resource,
    tokenRequired: true,
    // https://github.com/diegohaz/arc/wiki/Actions#async-actions
    thunk: `${resource}Create`,
  },
});

export const resourceCreateSuccess = (resource, detail, request, thunk) => ({
  type: RESOURCE_CREATE_SUCCESS,
  payload: detail,
  meta: {
    request,
    thunk,
    resource,
    // https://github.com/diegohaz/arc/wiki/Example-redux-modules#entities
    entities: resource,
  },
});

export const resourceCreateFailure = (resource, error, request, thunk) => ({
  type: RESOURCE_CREATE_FAILURE,
  error: true,
  payload: error,
  meta: {
    request,
    resource,
    // https://github.com/diegohaz/arc/wiki/Actions#async-actions
    thunk,
  },
});

export const RESOURCE_LIST_READ_REQUEST = 'RESOURCE_LIST_READ_REQUEST';
export const RESOURCE_LIST_READ_SUCCESS = 'RESOURCE_LIST_READ_SUCCESS';
export const RESOURCE_LIST_READ_FAILURE = 'RESOURCE_LIST_READ_FAILURE';

export const resourceListReadRequest = (
  resource,
  resourceKey,
  params,
  isRefresh = false,
) => ({
  type: RESOURCE_LIST_READ_REQUEST,
  payload: { params },
  meta: {
    resource,
    resourceKey,
    isRefresh,
    tokenRequired: true,
    thunk: `${resource}ListRead`,
  },
});

export const resourceListReadSuccess = (
  resource,
  resourceKey,
  list,
  request,
  thunk,
  isRefresh,
) => ({
  type: RESOURCE_LIST_READ_SUCCESS,
  payload: list,
  meta: {
    request,
    thunk,
    resourceKey,
    isRefresh,
    resource,
    entities: resource,
  },
});

export const resourceListReadFailure = (resource, error, request, thunk) => ({
  type: RESOURCE_LIST_READ_FAILURE,
  error: true,
  payload: error,
  meta: {
    request,
    thunk,
    resource,
  },
});

export const RESOURCE_DETAIL_READ_REQUEST = 'RESOURCE_DETAIL_READ_REQUEST';
export const RESOURCE_DETAIL_READ_SUCCESS = 'RESOURCE_DETAIL_READ_SUCCESS';
export const RESOURCE_DETAIL_READ_FAILURE = 'RESOURCE_DETAIL_READ_FAILURE';

export const resourceDetailReadRequest = (resource, params) => ({
  type: RESOURCE_DETAIL_READ_REQUEST,
  payload: { params },
  meta: {
    resource,
    tokenRequired: true,
    thunk: `${resource}DetailRead`,
  },
});

export const resourceDetailReadSuccess = (
  resource,
  detail,
  request,
  thunk,
) => ({
  type: RESOURCE_DETAIL_READ_SUCCESS,
  payload: detail,
  meta: {
    request,
    thunk,
    resource,
    entities: resource,
  },
});

export const resourceDetailReadFailure = (resource, error, request, thunk) => ({
  type: RESOURCE_DETAIL_READ_FAILURE,
  error: true,
  payload: error,
  meta: {
    request,
    thunk,
    resource,
  },
});

export const RESOURCE_UPDATE_REQUEST = 'RESOURCE_UPDATE_REQUEST';
export const RESOURCE_UPDATE_SUCCESS = 'RESOURCE_UPDATE_SUCCESS';
export const RESOURCE_UPDATE_FAILURE = 'RESOURCE_UPDATE_FAILURE';

export const resourceUpdateRequest = (resource, needle, data) => ({
  type: RESOURCE_UPDATE_REQUEST,
  payload: { needle, data },
  meta: {
    resource,
    thunk: `${resource}Update`,
  },
});

export const resourceUpdateSuccess = (resource, detail, request, thunk) => ({
  type: RESOURCE_UPDATE_SUCCESS,
  payload: detail,
  meta: {
    request,
    thunk,
    resource,
    entities: resource,
  },
});

export const resourceUpdateFailure = (resource, error, request, thunk) => ({
  type: RESOURCE_UPDATE_FAILURE,
  error: true,
  payload: error,
  meta: {
    request,
    thunk,
    resource,
  },
});

export const RESOURCE_DELETE_REQUEST = 'RESOURCE_DELETE_REQUEST';
export const RESOURCE_DELETE_SUCCESS = 'RESOURCE_DELETE_SUCCESS';
export const RESOURCE_DELETE_FAILURE = 'RESOURCE_DELETE_FAILURE';

export const resourceDeleteRequest = (resource, params) => ({
  type: RESOURCE_DELETE_REQUEST,
  payload: { params },
  meta: {
    resource,
    tokenRequired: true,
    thunk: `${resource}Delete`,
  },
});

export const resourceDeleteSuccess = (resource, request, thunk) => ({
  type: RESOURCE_DELETE_SUCCESS,
  meta: {
    request,
    thunk,
    resource,
  },
});

export const resourceDeleteFailure = (resource, error, request, thunk) => ({
  type: RESOURCE_DELETE_FAILURE,
  error: true,
  payload: error,
  meta: {
    request,
    thunk,
    resource,
  },
});

export const RESOURCE_ORDER_AVAILABILITY_REQUEST =
  'RESOURCE_ORDER_AVAILABILITY_REQUEST';
export const RESOURCE_ORDER_AVAILABILITY_REQUEST_SUCCESS =
  'RESOURCE_ORDER_AVAILABILITY_REQUEST_SUCCESS';
export const RESOURCE_ORDER_AVAILABILITY_REQUEST_FAILURE =
  'RESOURCE_ORDER_AVAILABILITY_REQUEST_FAILURE';

export const resourceAutoAssignOrderAvailabilityRequest = (
  resource,
  params,
) => ({
  type: RESOURCE_ORDER_AVAILABILITY_REQUEST,
  payload: { params },
  meta: {
    resource,
    tokenRequired: true,
    thunk: `${resource}`,
  },
});

export const resourceAutoAssignOrderAvailabilitySuccess = (
  resource,
  request,
  response,
  thunk,
) => ({
  type: RESOURCE_ORDER_AVAILABILITY_REQUEST_SUCCESS,
  // payload:response,
  meta: {
    request,
    thunk,
    resource,
  },
});

export const resourceAutoAssignOrderAvailabilityFailure = (
  resource,
  error,
  request,
  thunk,
) => ({
  type: RESOURCE_ORDER_AVAILABILITY_REQUEST_FAILURE,
  error: true,
  payload: error,
  meta: {
    request,
    thunk,
    resource,
  },
});

export const RESOURCE_ORDER_AVAILABILITY_DELETE_REQUEST =
  'RESOURCE_ORDER_AVAILABILITY_DELETE_REQUEST';
export const RESOURCE_ORDER_AVAILABILITY_DELETE_REQUEST_SUCCESS =
  'RESOURCE_ORDER_AVAILABILITY_DELETE_REQUEST_SUCCESS';
export const RESOURCE_ORDER_AVAILABILITY_DELETE_REQUEST_FAILURE =
  'RESOURCE_ORDER_AVAILABILITY_DELETE_REQUEST_FAILURE';

export const resourceAutoAssignOrderAvailabilityDeleteRequest = resource => ({
  type: RESOURCE_ORDER_AVAILABILITY_DELETE_REQUEST,
  payload: {},
  meta: {
    resource,
    tokenRequired: true,
    thunk: `${resource}Delete`,
  },
});

export const resourceAutoAssignOrderAvailabilityDeleteRequestSuccess = (
  resource,
  request,
  response,
  thunk,
) => ({
  type: RESOURCE_ORDER_AVAILABILITY_DELETE_REQUEST_SUCCESS,
  meta: {
    request,
    thunk,
    resource,
  },
});

export const resourceAutoAssignOrderAvailabilityDeleteRequestFailure = (
  resource,
  error,
  request,
  thunk,
) => ({
  type: RESOURCE_ORDER_AVAILABILITY_DELETE_REQUEST_FAILURE,
  error: true,
  payload: error,
  meta: {
    request,
    thunk,
    resource,
  },
});

export const RESOURCE_UPDATE_SICK_LEAVE = 'RESOURCE_UPDATE_SICK_LEAVE';

export const displayRemoveSickLeaveButton = params => ({
  type: RESOURCE_UPDATE_SICK_LEAVE,
  payload: params,
});
