import React, { useEffect, useState } from 'react';
import {
  TabContainer,
  TabContent,
  TermsAndCondition,
  GeneralCondition,
} from 'components';
import {
  CompensationContainer,
  FeeListContainer,
  DefaultAvailablityContainer,
  PreferenceSettingContainer,
  ProfileContainer,
  SemesterAllocationContainer,
  SickLeaveContainer,
  ExceptionAvailabilityContainer,
} from 'containers';
import { useLocation } from 'react-router';
import './style.scss';
import { currentVersion } from 'config';

const SettingPage = () => {
  const tabs = [
    {
      name: 'Min profil',
      label: 'Min profil',
      slugName: '#profile',
      displayBorderBottom: false,
    },
    {
      name: 'Uppdragsväljaren',
      label: 'Uppdragsväljaren',
      slugName: '#preferences',
      displayBorderBottom: true,
    },
    {
      name: 'Ersättning',
      label: 'Ersättning',
      slugName: '#payout',
      displayBorderBottom: false,
    },
    {
      name: 'Arvodeslistor',
      label: 'Arvodeslistor',
      slugName: '#fees',
      displayBorderBottom: true,
    },
    {
      name: 'Tillgänglig / Upptagen',
      label: 'Tillgänglig / Upptagen',
      slugName: '#shorter-availability',
      displayBorderBottom: false,
    },
    {
      name: 'Schemalagd tillgänglighet',
      label: 'Schemalagd tillgänglighet',
      slugName: '#default-availability',
      displayBorderBottom: false,
    },
    {
      name: 'Lägg till längre ledighet',
      label: 'Lägg till längre ledighet',
      slugName: '#semesters-allocation',
      displayBorderBottom: false,
    },
    {
      name: 'Sjukanmälan',
      label: 'Sjukanmälan',
      slugName: '#sick-leave',
      displayBorderBottom: true,
    },
    {
      name: 'T&C Personuppgiftspolicy',
      label: 'T&C Personuppgiftspolicy',
      slugName: '#term-and-condition',
      displayBorderBottom: false,
    },
    {
      name: 'Allmänna villkor för Tolkuppdrag',
      label: 'Allmänna villkor för Tolkuppdrag',
      slugName: '#general-condition',
      displayBorderBottom: false,
    },
  ];

  const [defaultTab, setDefaultTab] = useState('Min profil');

  const { hash: slug } = useLocation();

  useEffect(() => {
    const selectedTab = tabs.filter(x => x.slugName === slug);
    setDefaultTab(selectedTab.length > 0 ? selectedTab[0].name : 'Min profil');
  }, []);

  return (
    <div className="tv-setting__container">
      <TabContainer tabs={tabs} defaultTab={defaultTab}>
        {activeTab => (
          <>
            <TabContent name="Min profil" activeTab={activeTab} className="">
              <ProfileContainer />
            </TabContent>
            <TabContent name="Ersättning" activeTab={activeTab}>
              <CompensationContainer />
            </TabContent>
            <TabContent name="Uppdragsväljaren" activeTab={activeTab}>
              <PreferenceSettingContainer />
            </TabContent>
            <TabContent name="Tillgänglig / Upptagen" activeTab={activeTab}>
              <ExceptionAvailabilityContainer />
            </TabContent>
            <TabContent name="Schemalagd tillgänglighet" activeTab={activeTab}>
              <DefaultAvailablityContainer />
            </TabContent>
            <TabContent name="Lägg till längre ledighet" activeTab={activeTab}>
              <SemesterAllocationContainer />
            </TabContent>
            <TabContent name="Sjukanmälan" activeTab={activeTab}>
              <SickLeaveContainer />
            </TabContent>
            <TabContent name="Arvodeslistor" activeTab={activeTab}>
              <FeeListContainer />
            </TabContent>
            <TabContent name="T&C Personuppgiftspolicy" activeTab={activeTab}>
              <TermsAndCondition />
            </TabContent>
            <TabContent
              name="Allmänna villkor för Tolkuppdrag"
              activeTab={activeTab}
            >
              <GeneralCondition />
            </TabContent>
          </>
        )}
      </TabContainer>
      <div className="tv-settings__footer">
        <span>{currentVersion}</span>
      </div>
    </div>
  );
};

SettingPage.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
};

SettingPage.defaultProps = {};

export default SettingPage;
