/* eslint-disable no-console */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { FeeList, Spinner } from 'components';
import { fromResource } from 'store/selectors';
import { fulfilled, rejected, pending } from 'redux-saga-thunk';
import { localDayjs } from 'utils/dateTimeUtils';
import { resourceListReadRequest } from 'store/actions';
import { useGetFile } from 'hooks';

function FeeListContainer({
  feeList,
  onSearchResourceFiles,
  isPending,
  isfulfilled,
}) {
  const { GetFile } = useGetFile();

  useEffect(() => {
    const params = {
      DatetimeFrom: localDayjs().subtract(1, 'year').toDate(),
      DatetimeTo: new Date(),
    };
    if (!isfulfilled) {
      onSearchResourceFiles(params);
    }
  }, []);

  return (
    <>
      {' '}
      <FeeList list={feeList} onDownloadClick={GetFile} visibleDate={false} />
      {isPending && <Spinner />}
    </>
  );
}

FeeListContainer.propTypes = {
  feeList: PropTypes.arrayOf(PropTypes.shape({})),
  onSearchResourceFiles: PropTypes.func,
  isPending: PropTypes.bool,
  isfulfilled: PropTypes.bool,
};
FeeListContainer.defaultProps = {
  feeList: [],
  isPending: false,
  isfulfilled: false,
  onSearchResourceFiles: () => {},
};

const mapDispatchToProps = dispatch => ({
  onSearchResourceFiles: params => {
    dispatch(
      resourceListReadRequest('feeListSearchResource', 'Files', params, true),
    );
  },
});
const mapStateToProps = state => {
  const feeListResources = fromResource.getList(state, 'feeListSearchResource');
  const filteredFeeList =
    feeListResources && feeListResources.length
      ? feeListResources.filter(x => x.FileType === 'Arvodeslista')
      : [];

  const sortedFeeList = filteredFeeList.sort((a, b) =>
    a.FileName.localeCompare(b.FileName, 'sv'),
  );

  return {
    isPending: pending(state, 'feeListSearchResourceListRead'),
    isfulfilled: fulfilled(state, 'feeListSearchResourceListRead'),
    feeList: sortedFeeList,
    isfailed: rejected(state, 'feeListSearchResourceListRead'),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FeeListContainer);
