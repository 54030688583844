import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';
import { iconForTheServiceType } from 'utils/miscUtil';

function AssignmentCustomerName({
  status,
  serviceName,
  customerName,
  className,
  isColoredIcon,
}) {
  return (
    <div className={`tv-assignment-customer__container ${className}`}>
      <i
        className={` icon tv-assignment-customer-article-icon material-icons  tv-assignment-customer-article-icon--${status} ${
          isColoredIcon &&
          `tv-assignment-customer-article-icon-color--${status}`
        }`}
      >
        {iconForTheServiceType(serviceName)}
      </i>
      <div className="tv-assignment-customer-article-name">{customerName}</div>
    </div>
  );
}
AssignmentCustomerName.propTypes = {
  status: PropTypes.string,
  serviceName: PropTypes.string,
  customerName: PropTypes.string,
  className: PropTypes.string,
  isColoredIcon: PropTypes.bool,
};
AssignmentCustomerName.defaultProps = {
  status: 'performed',
  serviceName: 'Telefontolkning',
  customerName: '',
  className: '',
  isColoredIcon: false,
};

export default AssignmentCustomerName;
