/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Translate, TimePicker, Button } from 'components';
import { currentDate, convertToDayJsObject } from 'utils/dateTimeUtils';
import './style.scss';
import { NavLink } from 'react-router-dom';

const AvailabilityNowModal = ({
  onClose,
  onConfirm,
  isVisible,
  openingHours,
  autoAssignIsActive,
  autoAssignSet,
}) => {
  const start = currentDate();
  const [availableTo, setAvailableTo] = useState(start);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    const remainder = 30 - (start.minute() % 30);
    const dateTime = convertToDayJsObject(start).add(remainder + 15, 'minutes');
    setAvailableTo(dateTime);
  }, [isVisible]);
  useEffect(() => {
    if (
      currentDate() > availableTo ||
      openingHours.to < availableTo.subtract(1, 'minute')
    ) {
      setIsError(true);
    } else {
      setIsError(false);
    }
  }, [availableTo]);
  const timePickerHandler = date => {
    setAvailableTo(date);
  };

  const onSubmit = () => {
    if (!isError) {
      onConfirm(availableTo);
    }
  };

  if (
    currentDate() < openingHours.from ||
    currentDate() > openingHours.to ||
    !autoAssignIsActive
  ) {
    return (
      <Modal
        mainTitle={Translate({ content: 'availabilityNow.title' })}
        isVisible={isVisible}
        className="tv-availability-now-modal__container--empty"
        onClose={onClose}
      >
        <div className="tv-availability-now-modal__content--empty">
          <p className="tv-availability-now-modal__text">
            Det är ej möjligt att använda verktyget TolkaDirekt vid denna
            tidpunkt.
          </p>
          <br />

          <p className="tv-availability-now-modal__text">
            Öppettiderna är i dagsläget
            <b>
              {'  '}
              {`${openingHours.from.format('HH:mm')}-${openingHours.to.format(
                'HH:mm',
              )}.`}
            </b>
          </p>

          <div className="tv-availability-now-modal__actions">
            <Button
              label={Translate({ content: 'availabilityNow.cancel' })}
              className="tv-availability-now-modal__actions-button"
              palette="outline"
              onClick={onClose}
            />
          </div>
        </div>
      </Modal>
    );
  }
  if (!autoAssignSet || !autoAssignIsActive) {
    return (
      <Modal
        mainTitle={Translate({ content: 'availabilityNow.title' })}
        isVisible={isVisible}
        className="tv-availability-now-modal__container--empty"
        onClose={onClose}
      >
        <div className="tv-availability-now-modal__content--empty">
          <p className="tv-availability-now-modal__text">
            Det är ej möjligt att använda verktyget TolkaDirekt vid denna
            tidpunkt.
          </p>
          <br />
          <p className="tv-availability-now-modal__text">
            Öppettiderna är i dagsläget
            <b>
              {'  '}
              mån-fre {'  '}
              {`${openingHours.from.format('HH:mm')}-${openingHours.to.format(
                'HH:mm',
              )}`}
            </b>
          </p>
          <div className="tv-availability-now-modal__actions">
            <Button
              label={Translate({ content: 'availabilityNow.cancel' })}
              className="tv-availability-now-modal__actions-button"
              palette="outline"
              onClick={onClose}
            />
          </div>
        </div>
      </Modal>
    );
  }

  return (
    <Modal
      mainTitle={Translate({ content: 'availabilityNow.title' })}
      isVisible={isVisible}
      className="tv-availability-now-modal__container"
      onClose={onClose}
    >
      <>
        <div className="tv-availability-now-modal__content">
          <p className="tv-availability-now-modal__text">
            TolkaDirekt är akut telefontolkning med automattillsättning utan
            förfrågan. TolkaDirekt innebär att du lägger dig tillgänglig för
            akuta uppdrag från och med nu fram till den sluttid du väljer. Du
            kommer att automattillsättas på akuta uppdrag som kommer in direkt
            från kund. Du får ingen förfrågan. När du blir tillsatt på ett
            uppdrag så får du en notis och ett sms och kan se uppdraget i portal
            och app.
            <NavLink to="/releases-page">
              <span
                className="tv-exception-avilability__link"
                onClick={onClose}
              >
                Läs mer här.
              </span>
            </NavLink>
          </p>
          <br />
          <p className="tv-availability-now-modal__text">
            TolkaDirekt telefontolkning idag{' '}
            <b> {openingHours.to.format('ddd DD MMMM')}</b>, öppettider{' '}
            <b>
              {' '}
              {`${openingHours.from.format('HH:mm')}-${openingHours.to.format(
                'HH:mm',
              )}`}
            </b>
          </p>
          <hr className="tv-availability-now-modal__seperator" />

          <div className="tv-availability-now-modal__form">
            <div className="tv-availability-now-modal__field_group">
              <p className="tv-availability-now-modal__field__label">
                Jag är TolkaDirekt-tillgänglig nu fram till
              </p>
              <TimePicker
                className="tv-availability-now-modal__field"
                defaultValue={availableTo}
                onChange={timePickerHandler}
                minuteStep={15}
                maxTime={openingHours.to}
              />
            </div>
            {currentDate() > availableTo && (
              <div className="tv-availability-now-modal__error">
                *Välj en framtida tidsram
              </div>
            )}
            {openingHours.to < availableTo.subtract(1, 'minute') && (
              <div className="tv-availability-now-modal__error">
                *Välj en sluttid som är inom öppettiderna
              </div>
            )}
          </div>

          <div className="tv-availability-now-modal__actions">
            <Button
              label={Translate({ content: 'availabilityNow.cancel' })}
              className="tv-availability-now-modal__actions-button"
              palette="outline"
              onClick={onClose}
            />
            <Button
              label={Translate({ content: 'availabilityNow.submit' })}
              className="tv-availability-now-modal__actions-button"
              onClick={onSubmit}
              disabled={isError}
            />
          </div>
        </div>
      </>
    </Modal>
  );
};

AvailabilityNowModal.propTypes = {
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  isVisible: PropTypes.bool,
  selectedFromDate: PropTypes.shape({}),
  selectedToDate: PropTypes.shape({}),
  openingHours: PropTypes.shape({
    from: PropTypes.shape({}),
    to: PropTypes.shape({}),
  }),
};

AvailabilityNowModal.defaultProps = {
  onClose: () => {},
  onConfirm: () => {},
  isVisible: false,
  selectedFromDate: new Date(),
  selectedToDate: new Date(),
  openingHours: {
    from: currentDate().startOf('day').add(7, 'hours').add(30, 'minutes'),
    to: currentDate().startOf('day').add(19, 'hours'),
  },
};

export default AvailabilityNowModal;
