/* eslint-disable arrow-body-style */
import { v4 as uuidv4 } from 'uuid';

const convertResourceRequests = (list = []) => {
  if (!list) return list;
  return list.map(item => {
    return {
      ...item,
      ...item.WorkAssignmentReference,
      status: 'inquiry',
      OrderStatus: 'inquiry',
      key: uuidv4(),
    };
  });
};

const convertWorkAssignmentS = (list = []) => {
  if (!list) return list;
  return list
    .filter(y => y !== undefined)
    .map(item => {
      const status =
        item.WorkAssignmentConsolidatedStatusIdentifier || 'accept';
      return {
        ...item,
        status,
        key: uuidv4(),
        OrderStatus: status,
      };
    });
};

// eslint-disable-next-line no-unused-vars
const workAssignmentMiddleware = store => next => action => {
  const { payload, meta } = action;
  const { resource, resourceKey } = meta || {};
  if (resource && resource === 'assignmentRequest') {
    const newPayload = { ...payload };
    const newProcessedlist = convertResourceRequests(payload[resourceKey]);
    newPayload[resourceKey] = newProcessedlist;
    const newAction = { ...action, payload: newPayload };
    return next(newAction);
  }
  if (resourceKey && resourceKey === 'WorkAssignments') {
    const newPayload = { ...payload };
    const newProcessedlist = convertWorkAssignmentS(payload[resourceKey]);
    newPayload[resourceKey] = newProcessedlist;
    const newAction = { ...action, payload: newPayload };
    return next(newAction);
  }
  return next(action);
};

export default workAssignmentMiddleware;
