import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'components';

import './style.scss';

function AssignmentCancelModal({
  isVisible,
  onOverlayClick,
  onClick,
  onClose,
}) {
  return (
    <Modal
      className="tv-assignment-cancel-modal__container"
      hideHeader
      onClose={onClose}
      onOverlayClick={onOverlayClick}
      isVisible={isVisible}
    >
      <p>
        Har något inträffat som gör att du ej kan genomföra ditt accepterade
        uppdrag ?
      </p>
      <p>Kontakta i så fall din förmedlare eller ring tolklinjen direkt på</p>
      <p>
        <b>08-120 800 05</b>
      </p>

      <Button
        onClick={onClick}
        className="tv-assignment-cancel-modal__button"
        label="Ok"
      />
    </Modal>
  );
}

AssignmentCancelModal.propTypes = {
  isVisible: PropTypes.bool,
  onOverlayClick: PropTypes.func,
  onClose: PropTypes.func,
  onClick: PropTypes.func,
};
AssignmentCancelModal.defaultProps = {
  isVisible: false,
  onOverlayClick: () => {},
  onClose: () => {},
  onClick: () => {},
};

export default AssignmentCancelModal;
