import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  SickLeave,
  SickLeaveConfirmModal,
  Spinner,
  RemoveSickLeave,
} from 'components';
import { currentDate, localDayjs as dayjs } from 'utils/dateTimeUtils';
import {
  displayRemoveSickLeaveButton,
  resourceListReadRequest,
} from 'store/actions';
import { connect, useSelector } from 'react-redux';
import { resourceCreateRequest } from 'store/resource/actions';
import { fulfilled, pending, clean } from 'redux-saga-thunk';
import { fromResource } from 'store/selectors';
import { useWorkAssignment } from 'hooks';

const initialState = {
  DateFrom: currentDate().format('YYYY-MM-DD'),
  DateEstimatedTo: null,
};

const SickLeaveContainer = ({
  onDisplayButton,
  onCreateRegisterIllness,
  isRegisterPending,
  isRegisterFulfilled,
  onCleanState,
  getResourceAvailability,
  onCancelIllness,
  isCancellingIllnessPending,
  isCancellingIllnessFulfilled,
}) => {
  const { resourceAvailability } = useWorkAssignment();
  const [showLeaveConfirmationModal, setShowLeaveConfirmationModal] = useState(
    false,
  );
  const [earlistSickDate, setEarlistSickDate] = useState(null);

  const isInSickLeave = useSelector(state =>
    fromResource.getSickLeaveInfo(state),
  );
  const [displaySuccess, setDisplaySuccess] = useState(isInSickLeave);
  const [datePicker, setDatePicker] = useState(null);
  const [formData, setFormData] = useState(initialState);
  const [isChecked, setIsChecked] = useState(false);
  const [showRemoveSickLeave, setShowRemoveSickLeave] = useState(false);

  const displayConfimationModal = () => {
    setShowLeaveConfirmationModal(!showLeaveConfirmationModal);
  };

  const onDisplaySuccess = () => {
    onCreateRegisterIllness(formData);
  };

  const handleDatePickerChange = date => {
    const changedDate = dayjs(date);
    setDatePicker(changedDate);
    setFormData({
      ...formData,
      DateEstimatedTo: dayjs(date).format('YYYY-MM-DD'),
    });
  };

  const handleChangeCheckBox = () => {
    setIsChecked(!isChecked);
    if (isChecked) {
      setFormData({
        ...formData,
        DateEstimatedTo: null,
      });
      setDatePicker(null);
    }
  };

  useEffect(() => {
    if (isRegisterFulfilled) {
      onCleanState('registerIllnessCreate');
      getResourceAvailability();
      setShowLeaveConfirmationModal(!showLeaveConfirmationModal);
      onDisplayButton(true);
    }
  }, [isRegisterFulfilled]);

  useEffect(() => {
    if (isInSickLeave) {
      if (resourceAvailability.length > 0) {
        const filteredData = resourceAvailability.filter(
          entry =>
            entry.AvailabilityType === 3 &&
            entry.DefaultAvailabilityExceptionIdentifier === null,
        );

        if (filteredData.length > 0) {
          const earliestEntry = filteredData.reduce(
            (earliest, current) =>
              dayjs(current.DatetimeFrom).isBefore(dayjs(earliest.DatetimeFrom))
                ? current
                : earliest,
            filteredData[0],
          );

          const earliestDate = dayjs(earliestEntry.DatetimeFrom).format(
            'YYYY-MM-DD',
          );
          setEarlistSickDate(earliestDate);
          setDisplaySuccess(true);
        }
      }
    }
  }, [isInSickLeave, resourceAvailability]);

  useEffect(() => {
    getResourceAvailability();
  }, []);

  const updateRemoveSickLeave = () => {
    setShowRemoveSickLeave(!showRemoveSickLeave);
  };

  const removeCancelSickLeave = () => {
    onDisplayButton(false);
    const params = {
      DateCancelFrom: currentDate().format('YYYY-MM-DD'),
    };
    onCancelIllness(params);
    setShowRemoveSickLeave(!showRemoveSickLeave);
  };

  useEffect(() => {
    if (isCancellingIllnessFulfilled) {
      getResourceAvailability();
      setDisplaySuccess(false);
    }
  }, [isCancellingIllnessFulfilled]);

  return (
    <div>
      <SickLeave
        onDisplay={displayConfimationModal}
        displaySuccess={displaySuccess}
        onDatePickerChange={handleDatePickerChange}
        selectedDate={datePicker ? datePicker.toDate() : datePicker}
        isChecked={isChecked}
        handleChangeCheckBox={handleChangeCheckBox}
        earlistSickDate={earlistSickDate}
        onRemove={updateRemoveSickLeave}
      />
      {showLeaveConfirmationModal && (
        <SickLeaveConfirmModal
          hideModal={displayConfimationModal}
          onDisplaySuccess={onDisplaySuccess}
        />
      )}
      {showRemoveSickLeave && (
        <RemoveSickLeave
          onCancel={updateRemoveSickLeave}
          onClick={removeCancelSickLeave}
        />
      )}
      {(isRegisterPending || isCancellingIllnessPending) && <Spinner />}
    </div>
  );
};

SickLeaveContainer.propTypes = {
  onDisplayButton: PropTypes.func,
  onCreateRegisterIllness: PropTypes.func,
  isRegisterPending: PropTypes.bool,
  isRegisterFulfilled: PropTypes.bool,
  onCleanState: PropTypes.func,
  getResourceAvailability: PropTypes.func,
  onCancelIllness: PropTypes.func,
  isCancellingIllnessPending: PropTypes.bool,
  isCancellingIllnessFulfilled: PropTypes.bool,
};

SickLeaveContainer.defaultProps = {
  onDisplayButton: () => {},
  onCreateRegisterIllness: () => {},
  isRegisterPending: false,
  isRegisterFulfilled: false,
  onCleanState: () => {},
  getResourceAvailability: () => {},
  onCancelIllness: () => {},
  isCancellingIllnessPending: false,
  isCancellingIllnessFulfilled: false,
};

const STANDARD_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm';

const mapDispatchToProps = dispatch => ({
  onDisplayButton: param => {
    dispatch(displayRemoveSickLeaveButton(param));
  },
  onCreateRegisterIllness: params => {
    dispatch(resourceCreateRequest('registerIllness', params));
  },
  onCleanState: resource => {
    dispatch(clean(resource));
  },
  getResourceAvailability: () => {
    const params = {
      DatetimeFrom: currentDate().format(STANDARD_DATE_TIME_FORMAT),
      DatetimeTo: currentDate().format(STANDARD_DATE_TIME_FORMAT),
    };
    dispatch(
      resourceListReadRequest(
        'resourceAvailability',
        'AvailabilityIntervals',
        params,
        true,
      ),
    );
  },
  onCancelIllness: params => {
    dispatch(resourceCreateRequest('cancelIllness', params));
  },
});

const mapStateToProps = state => ({
  isRegisterPending: pending(state, 'registerIllnessCreate'),
  isRegisterFulfilled: fulfilled(state, 'registerIllnessCreate'),
  isCancellingIllnessPending: pending(state, 'cancelIllnessCreate'),
  isCancellingIllnessFulfilled: fulfilled(state, 'cancelIllnessCreate'),
});

export default connect(mapStateToProps, mapDispatchToProps)(SickLeaveContainer);
