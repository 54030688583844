/* eslint-disable no-nested-ternary */
// https://github.com/diegohaz/arc/wiki/Reducers
// https://github.com/diegohaz/arc/wiki/Example-redux-modules#resource
import findIndex from 'lodash/findIndex';
import { get, unionBy } from 'lodash';
import {
  initialState,
  getResourceState,
  getList,
  getDetail,
} from './selectors';
import {
  RESOURCE_CREATE_SUCCESS,
  RESOURCE_LIST_READ_REQUEST,
  RESOURCE_LIST_READ_SUCCESS,
  RESOURCE_DETAIL_READ_REQUEST,
  RESOURCE_DETAIL_READ_SUCCESS,
  RESOURCE_UPDATE_SUCCESS,
  RESOURCE_DELETE_SUCCESS,
  RESOURCE_ORDER_AVAILABILITY_REQUEST,
  RESOURCE_ORDER_AVAILABILITY_REQUEST_SUCCESS,
  RESOURCE_ORDER_AVAILABILITY_REQUEST_FAILURE,
  RESOURCE_UPDATE_SICK_LEAVE,
} from './actions';

const updateOrDeleteReducer = (state, { type, payload, meta }) => {
  const resource = get(meta, 'resource');
  const needle = get(meta, 'request.needle');
  const needleIsObject = typeof needle === 'object';
  const list = getList(state, resource);
  const index = needleIsObject ? findIndex(list, needle) : list.indexOf(needle);

  if (index < 0) {
    return state;
  }

  switch (type) {
    case RESOURCE_UPDATE_SUCCESS:
      return {
        ...state,
        [resource]: {
          ...getResourceState(state, resource),
          list: [
            ...list.slice(0, index),
            needleIsObject ? { ...list[index], ...payload } : payload,
            ...list.slice(index + 1),
          ],
        },
      };
    case RESOURCE_DELETE_SUCCESS:
      return {
        ...state,
        [resource]: {
          ...getResourceState(state, resource),
          list: [...list.slice(0, index), ...list.slice(index + 1)],
        },
      };
    // istanbul ignore next
    default:
      return state;
  }
};

const updateResourceList = (
  state,
  { resource, resourceKey, payload, meta },
) => {
  const resourceState = getResourceState(state, resource);
  const isRefresh = get(meta, 'isRefresh');

  let list = [];
  if (
    (resource === 'calendarAssignment' ||
      resource === 'resourceAvailability') &&
    !isRefresh
  ) {
    list = [...resourceState.list, ...(payload[resourceKey] || [])];
    list =
      resource === 'calendarAssignment'
        ? unionBy(list, 'WorkAssignmentIdentifier')
        : list;
  } else list = payload[resourceKey];
  return {
    ...state,
    [resource]: {
      ...resourceState,
      list: list || [],
    },
  };
};

export default (state = initialState, { type, payload, meta }) => {
  const resource = get(meta, 'resource');
  const resourceKey = get(meta, 'resourceKey');
  if (!resource) {
    switch (type) {
      case RESOURCE_UPDATE_SICK_LEAVE:
        return {
          ...state,
          isInSickLeave: payload,
        };

      default:
        return state;
    }
  }
  switch (type) {
    case RESOURCE_CREATE_SUCCESS:
      return {
        ...state,
        [resource]: {
          ...getResourceState(state, resource),
          list: [payload, ...getList(state, resource)],
        },
      };

    case RESOURCE_LIST_READ_REQUEST:
      return {
        ...state,
        [resource]: {
          ...getResourceState(state, resource),
          list:
            resource === 'calendarAssignment' ||
            resource === 'resourceAvailability' ||
            resource === 'semesterAllocations'
              ? getList(state, resource)
              : getList(initialState, resource),
        },
      };
    case RESOURCE_LIST_READ_SUCCESS:
      return updateResourceList(state, {
        resource,
        resourceKey,
        payload,
        meta,
      });

    case RESOURCE_DETAIL_READ_REQUEST:
      return {
        ...state,
        [resource]: {
          ...getResourceState(state, resource),
          detail: getDetail(initialState, resource),
        },
      };
    case RESOURCE_DETAIL_READ_SUCCESS:
      return {
        ...state,
        [resource]: {
          ...getResourceState(state, resource),
          detail: payload,
        },
      };

    case RESOURCE_UPDATE_SUCCESS:
    case RESOURCE_DELETE_SUCCESS:
      return updateOrDeleteReducer(state, { type, payload, meta });
    case RESOURCE_ORDER_AVAILABILITY_REQUEST:
      return {
        ...state,
      };
    case RESOURCE_ORDER_AVAILABILITY_REQUEST_SUCCESS:
      return {
        ...state,
        [resource]: {
          ...getResourceState(state, resource),
          resourceAutoAssignOrderAvailability: payload,
        },
      };
    case RESOURCE_ORDER_AVAILABILITY_REQUEST_FAILURE:
      return {
        ...state,
      };

    default:
      return state;
  }
};
