/* eslint-disable no-debugger */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useReducer, useState } from 'react';
import { useWorkAssignment } from 'hooks';
import { useHistory } from 'react-router-dom';
import CalendarContext, {
  calendarReducer,
  shiftCalendarDateRange,
  filterAvailabilityPerDay,
  calendarInitialState,
  CALENDAR_VIEW,
  scheduleAvailabilityBasedOnFilters,
  filterAssignmnentsdBasedOnStatus,
  filterEventsForTheDateRange,
} from 'context/CalendarContext';
import {
  convertToDayJsObject,
  currentDate,
  localDayjs as dayjs,
  isDayWithinTheRange,
  calculateAssignmentSearchParameters,
  currentSWTime,
  convertToSWDayJsObject,
} from 'utils/dateTimeUtils';
import { CalendarLayout, DashboardSideBar } from 'components';
import { status } from 'constant';

const activeSemesterAllocationInit = {
  dateFrom: dayjs(new Date()),
  dateTo: dayjs(new Date()),
  doAllowResourceRequests: true,
};

// eslint-disable-next-line no-empty-pattern
export const CalendarContainer = () => {
  const {
    workAssignmentList,
    resourceAvailability,
    semesterAllocations,
    assignmentRequestList,
    searchWorkAssignment,
    workAssignment: singleAssignment,
    getInterpreterRequests,
    getResourceAvailability,
    searchSingleWorkAssignment,
    replyToAssignmentRequest,
    cleanReplyToRequest,
    isLoading,
    assignmentRequestFulfilled,
    removeRequestReplied,
    getAllNotifications,
  } = useWorkAssignment();

  const [state, dispatch] = useReducer(calendarReducer, calendarInitialState);

  const {
    searchDateRange,
    calendarDateRange,
    selectedCalendarTab,
    visibleWorkAssignment,
    sortedAssignmentList,
    visibleAssignmentRequestList,
    visibleSchedule,
    visibleSemesterAllocations,
    selectedCalendarMonthPill,
    availabiltyFilterOptions,
    selectedStatusFilter,
  } = state;
  const [showFilter, setShowFilter] = useState(false);
  const [
    isAvailabilityFilterApplied,
    setIsAvailabilityFilterApplied,
  ] = useState(false);
  const [activeAssignment, setActiveAssignment] = useState({});
  const [visibleOverview, setVisibleAssignmentOverview] = useState(false);
  const [
    activeExceptionAvailability,
    setActiveExceptionAvailability,
  ] = useState({
    selectedDate: currentDate(),
    startTime: currentDate().startOf('hour').add(1, 'hour'),
  });
  const [activeSemesterAllocation, setActiveSemesterAllocation] = useState(
    activeSemesterAllocationInit,
  );
  const history = useHistory();

  const handleAssignmentPillClick = (date, list) => {
    dispatch([
      'setSelectedCalendarMonthPill',
      {
        date,
        list,
        availabilityList: date
          ? filterAvailabilityPerDay(resourceAvailability, date)
          : [],
      },
    ]);
    setVisibleAssignmentOverview(false);
  };

  useEffect(() => {
    const dateFocused = history.location.state?.dateFocused;
    if (!dateFocused) {
      searchWorkAssignment(false, searchDateRange, true);
      getInterpreterRequests();
      const params = {
        start: currentDate(),
        end: currentDate(),
      };
      getResourceAvailability(params, true);
    } else {
      // eslint-disable-next-line no-use-before-define
      handleCalendarNavigatorChange(convertToDayJsObject(dateFocused));
      if (selectedCalendarTab === CALENDAR_VIEW.MONTH) {
        setVisibleAssignmentOverview(false);
        const assignment = history.location.state?.assigment;
        const { DatetimeOrderFrom } = assignment;
        const date = convertToDayJsObject(DatetimeOrderFrom).startOf('day');
        const list = filterEventsForTheDateRange(
          visibleAssignmentRequestList,
          convertToDayJsObject(date).startOf('day'),
          convertToDayJsObject(date).endOf('day'),
        );
        handleAssignmentPillClick(date, list);
      } else {
        setActiveAssignment(history.location.state?.assigment);
        setVisibleAssignmentOverview(true);
      }
      history.replace();
    }

    /*  const getRequestIntervalHandle = setInterval(() => {
      getInterpreterRequests();
    }, 600000);
    return () => {
      clearInterval(getRequestIntervalHandle);
    }; */
  }, [history.location.state]);

  useEffect(() => {
    dispatch(['setVisibleWorkAssignment', workAssignmentList]);
  }, [workAssignmentList]);

  useEffect(() => {
    const filteredAvailabilitySchedule = scheduleAvailabilityBasedOnFilters(
      resourceAvailability,
      availabiltyFilterOptions,
    );
    dispatch(['setVisibleSchedule', filteredAvailabilitySchedule]);
    if (selectedCalendarMonthPill) {
      dispatch([
        'setSelectedCalendarMonthPill',
        {
          ...selectedCalendarMonthPill,
          availabilityList: selectedCalendarMonthPill.date
            ? filterAvailabilityPerDay(
                resourceAvailability,
                selectedCalendarMonthPill.date,
              )
            : [],
        },
      ]);
    }
  }, [resourceAvailability]);

  useEffect(() => {
    dispatch(['setVisibleSemesterAllocations', semesterAllocations]);
  }, [semesterAllocations]);

  useEffect(() => {
    dispatch([
      'setVisibleAssignmentRequestList',
      assignmentRequestList.filter(
        x =>
          convertToSWDayJsObject(x.DatetimeExpiration).diff(
            currentSWTime(),
            'seconds',
          ) > 0,
      ),
    ]);
  }, [assignmentRequestList]);

  const setCalendarDateRange = newCalendarDateRange => {
    dispatch([
      'setCalendarDateRange',
      {
        calendarDateRange: newCalendarDateRange,
        visibleWorkAssignment: workAssignmentList || [],
        visibleSchedule: [],
      },
    ]);
  };

  useEffect(() => {
    const resourceRequestCleanUpInterval = setInterval(
      list => {
        if (list) {
          const filteredRequest = list.filter(
            x =>
              convertToSWDayJsObject(x.DatetimeExpiration).diff(
                currentSWTime(),
                'seconds',
              ) > 0,
          );
          if (list.length !== filteredRequest.length) {
            dispatch(['setVisibleAssignmentRequestList', filteredRequest]);
          }
          if (filteredRequest.length === 0) {
            clearInterval(resourceRequestCleanUpInterval);
          }
        }
      },
      60000,
      visibleAssignmentRequestList,
    );
    return () => {
      clearInterval(resourceRequestCleanUpInterval);
    };
  }, [visibleAssignmentRequestList]);

  const handleSetCalendarType = tab => {
    // eslint-disable-next-line prefer-const
    let [startDate, endDate, startMonth, highlightedDay] = [
      calendarDateRange.start,
      calendarDateRange.end,
      calendarDateRange.startMonth,
      calendarDateRange.highlightedDay,
    ];

    startMonth = highlightedDay.startOf('month');

    if (CALENDAR_VIEW.MONTH === tab.name) {
      startDate = startMonth.startOf('week');
      endDate = startMonth.endOf('month').endOf('week');
    } else if (CALENDAR_VIEW.WEEK === tab.name) {
      startDate = highlightedDay.startOf('week');
      endDate = startDate.endOf('week');
    } else if (CALENDAR_VIEW.DAY === tab.name) {
      startDate = highlightedDay.startOf('day');
      endDate = startDate.endOf('day');
    }

    const newCalendarDateRange = {
      start: startDate,
      end: endDate,
      startMonth,
      highlightedDay,
    };

    const isCalendarRangeStartDateWithin = isDayWithinTheRange(
      newCalendarDateRange.start,
      searchDateRange.start,
      searchDateRange.end,
    );
    const isCalendarRangeEndDateWithin = isDayWithinTheRange(
      newCalendarDateRange.end,
      searchDateRange.start,
      searchDateRange.end,
    );
    if (!(isCalendarRangeStartDateWithin && isCalendarRangeEndDateWithin)) {
      const {
        param: newSearchRangeParms,
        dateRange: newSearchRange,
        isRefresh,
      } = calculateAssignmentSearchParameters(
        searchDateRange,
        newCalendarDateRange,
      );

      dispatch(['setSearchDateRange', newSearchRange]);
      searchWorkAssignment(false, newSearchRangeParms, isRefresh);
    }
    setCalendarDateRange({
      ...newCalendarDateRange,
    });
    dispatch(['setselectedCalendarTab', tab.name]);
  };

  const traverseCalendar = (direction, tab, focusDate) => {
    // get next search date range end date based on the foucs date
    let nextSearchDateRangeStart = focusDate;
    if (direction === 'forward') {
      if (tab === CALENDAR_VIEW.MONTH)
        nextSearchDateRangeStart = focusDate.add(1, tab);
      if (tab === CALENDAR_VIEW.WEEK)
        nextSearchDateRangeStart = focusDate.add(2, 'week').startOf('isoWeek');
      if (tab === CALENDAR_VIEW.DAY)
        nextSearchDateRangeStart = focusDate.add(2, tab).startOf(tab);
    } else {
      if (tab === CALENDAR_VIEW.MONTH)
        nextSearchDateRangeStart = focusDate.subtract(1, tab);
      if (tab === CALENDAR_VIEW.WEEK)
        nextSearchDateRangeStart = focusDate
          .subtract(2, 'week')
          .startOf('isoWeek');
      if (tab === CALENDAR_VIEW.DAY)
        nextSearchDateRangeStart = focusDate.subtract(2, tab).startOf(tab);
    }

    const currentSearchDateRange = searchDateRange;

    // check wheather next search date range end date is within currentSearchRange
    const isNextSearchDateRangeStartWithinCurrent = isDayWithinTheRange(
      nextSearchDateRangeStart,
      currentSearchDateRange.start,
      currentSearchDateRange.end,
    );

    // if it is not then we initiate a searchwork assignmnent 'from' :the cureentSearchRange end date 'to': next search date range end date
    if (!isNextSearchDateRangeStartWithinCurrent) {
      const newSearchRange = {
        start:
          direction === 'backward'
            ? nextSearchDateRangeStart.startOf('isoWeek')
            : searchDateRange.end,
        end:
          direction === 'backward'
            ? searchDateRange.start
            : nextSearchDateRangeStart.endOf(tab).endOf('isoWeek'),
      };
      dispatch([
        'setSearchDateRange',
        {
          start:
            direction === 'backward'
              ? newSearchRange.start
              : searchDateRange.start,
          end:
            direction === 'backward' ? searchDateRange.end : newSearchRange.end,
        },
      ]);
      searchWorkAssignment(false, newSearchRange);
      // getResourceAvailability(newSearchRange);
    }
  };

  const handleCalendarTravel = (direction = 'forward') => {
    const newCalendarDateRange = shiftCalendarDateRange(
      direction,
      selectedCalendarTab,
      calendarDateRange,
    );

    traverseCalendar(
      direction,
      selectedCalendarTab,
      // eslint-disable-next-line no-nested-ternary
      selectedCalendarTab !== 'month'
        ? direction === 'backward'
          ? newCalendarDateRange.start
          : newCalendarDateRange.end
        : newCalendarDateRange.startMonth,
    );

    setCalendarDateRange(newCalendarDateRange);
  };

  const handleCalendarNavigatorChange = date => {
    const selectedDate = dayjs(date);
    let [startDate, endDate] = [
      selectedDate.startOf('day'),
      selectedDate.endOf('day'),
    ];
    if (CALENDAR_VIEW.MONTH === selectedCalendarTab) {
      startDate = startDate.startOf('month').startOf('week');
      endDate = endDate.endOf('month').endOf('week');
    } else if (CALENDAR_VIEW.WEEK === selectedCalendarTab) {
      startDate = startDate.startOf('week');
      endDate = endDate.endOf('week');
    }
    const newCalendarDateRange = {
      start: startDate,
      end: endDate,
      startMonth: selectedDate.startOf('month'),
      highlightedDay: selectedDate,
    };

    setCalendarDateRange({
      ...newCalendarDateRange,
    });
    const isCalendarRangeStartDateWithin = isDayWithinTheRange(
      newCalendarDateRange.start,
      searchDateRange.start,
      searchDateRange.end,
    );
    const isCalendarRangeEndDateWithin = isDayWithinTheRange(
      newCalendarDateRange.end,
      searchDateRange.start,
      searchDateRange.end,
    );
    if (!(isCalendarRangeStartDateWithin && isCalendarRangeEndDateWithin)) {
      const {
        param: newSearchRangeParms,
        dateRange: newSearchRange,
        isRefresh,
      } = calculateAssignmentSearchParameters(
        searchDateRange,
        newCalendarDateRange,
      );

      dispatch(['setSearchDateRange', newSearchRange]);
      searchWorkAssignment(false, newSearchRangeParms, isRefresh);
    }
  };

  const handleNavigateToCurrentDay = () => {
    let [startDate, endDate, startMonth, highlightedDay] = [
      dayjs().startOf('day'),
      dayjs().endOf('day'),
      dayjs().startOf('month'),
      calendarDateRange.highlightedDay,
    ];
    startMonth = startDate.startOf('month');
    if (CALENDAR_VIEW.MONTH === selectedCalendarTab) {
      startDate = startMonth.startOf('week');
      endDate = startMonth.endOf('month').endOf('week');
    } else if (CALENDAR_VIEW.WEEK === selectedCalendarTab) {
      startDate = startDate.startOf('week');
      endDate = startDate.endOf('week');
    }
    highlightedDay = currentDate();

    const newCalendarDateRange = {
      start: startDate,
      end: endDate,
      startMonth,
      highlightedDay,
    };

    setCalendarDateRange({
      ...newCalendarDateRange,
    });

    const isCalendarRangeStartDateWithin = isDayWithinTheRange(
      newCalendarDateRange.start,
      searchDateRange.start,
      searchDateRange.end,
    );
    const isCalendarRangeEndDateWithin = isDayWithinTheRange(
      newCalendarDateRange.end,
      searchDateRange.start,
      searchDateRange.end,
    );
    if (!(isCalendarRangeStartDateWithin && isCalendarRangeEndDateWithin)) {
      const {
        param: newSearchRangeParms,
        dateRange: newSearchRange,
        isRefresh,
      } = calculateAssignmentSearchParameters(
        searchDateRange,
        newCalendarDateRange,
      );

      dispatch(['setSearchDateRange', newSearchRange]);
      searchWorkAssignment(false, newSearchRangeParms, isRefresh);
    }
  };

  const handleAvailabilityFilterChange = option => {
    setIsAvailabilityFilterApplied(option);
    // eslint-disable-next-line no-use-before-define
    handleStatusFilterChange([
      status.reported.name,
      status.accept.name,
      status.performed.name,
      status.cancelled.name,
      status.inquiry.name,
      status.replanning.name,
    ]);
  };

  const handleStatusFilterChange = optionList => {
    const filters = optionList.filter(x => x);
    const { assignmentList, requestList } = filterAssignmnentsdBasedOnStatus(
      filters,
      workAssignmentList,
      assignmentRequestList,
    );
    dispatch(['setStatusFilter', { filters, assignmentList, requestList }]);
  };

  const handleApplyAllFilter = (localStatusFilter, localAvailabilityFilter) => {
    const filteredAvailabilitySchedule = scheduleAvailabilityBasedOnFilters(
      resourceAvailability,
      localAvailabilityFilter,
    );
    const { assignmentList, requestList } = filterAssignmnentsdBasedOnStatus(
      localStatusFilter,
      workAssignmentList,
      assignmentRequestList,
    );
    dispatch([
      'setAllFilters',
      {
        statusFilter: localStatusFilter,
        availabilityFilters: localAvailabilityFilter,
        filteredAvailabilitySchedule,
        assignmentList,
        requestList,
      },
    ]);
  };

  const handleNavigationClick = action => {
    if (action === 'next') {
      const index = sortedAssignmentList.findIndex(
        item =>
          activeAssignment?.WorkAssignmentIdentifier ===
          item.WorkAssignmentIdentifier,
      );
      const nextIndex = index + 1 < sortedAssignmentList.length ? index + 1 : 0;
      setActiveAssignment(sortedAssignmentList[nextIndex]);
    }
    if (action === 'previous') {
      const index = sortedAssignmentList.findIndex(
        item =>
          activeAssignment?.WorkAssignmentIdentifier ===
          item.WorkAssignmentIdentifier,
      );
      const previousIndex =
        index - 1 >= 0 ? index - 1 : sortedAssignmentList.length - 1;
      setActiveAssignment(sortedAssignmentList[previousIndex]);
    }
  };

  const handleOnOverviewClose = () => {
    setActiveAssignment({});
    setVisibleAssignmentOverview(false);
  };

  const handleWorkAssignmentClick = item => {
    setVisibleAssignmentOverview(true);
    setActiveAssignment(item);
  };

  const handleAvailabilityExceptionClick = item => {
    const tempActiveItem = {
      selectedDate: item.DatetimeFrom
        ? dayjs(item.DatetimeFrom)
        : currentDate(),
      startTime: item.DatetimeFrom
        ? dayjs(item.DatetimeFrom)
        : currentDate().startOf('hour').add(1, 'hour'),
      endTime: item.DatetimeTo ? dayjs(item.DatetimeTo) : null,
      exceptionIdentifier: item.DefaultAvailabilityExceptionIdentifier || null,
      type: item.AvailabilityType || 2,
    };
    setActiveExceptionAvailability(tempActiveItem);
  };

  const handleResetActiveAvailabilityException = () => {
    setActiveExceptionAvailability({
      selectedDate: currentDate(),
      startTime: currentDate().startOf('hour').add(1, 'hour'),
    });
  };

  const handleActiveSemesterAllocationClick = item => {
    const tempActiveItem = {
      dateFrom: dayjs(item.FirstDayOfVacationPeriod),
      dateTo: dayjs(item.LastDayOfVacationPeriod),
      doAllowResourceRequests: item.DoAllowResourceRequests,
      vacationDayIdentifier: item.VacationDayIdentifier,
    };
    setActiveSemesterAllocation(tempActiveItem);
  };

  const handleResetActiveSemesterAllocation = () => {
    setActiveSemesterAllocation(activeSemesterAllocationInit);
  };

  return (
    <>
      <CalendarContext.Provider
        value={{
          list: visibleWorkAssignment.filter(x => x.IsVisible),
          availabilityList: visibleSchedule,
          semesterAllocationList: visibleSemesterAllocations,
          selectedCalendarType: selectedCalendarTab,
          assignmentRequestList: visibleAssignmentRequestList,
          calendarDateRange,
          selectedCalendarMonthPill,
          setCalendarType: handleSetCalendarType,
          handleAssignmentPillClick,
          onCalendarNavigatorChange: handleCalendarNavigatorChange,
          onTodayClick: handleNavigateToCurrentDay,
          handleCalendarTravel,
          isLoading,
          showFilter,
          toggleFilters: toogle => setShowFilter(toogle),
          availabiltyFilterOptions,
          selectedStatusFilter,
          onAvailabilityFilter: handleAvailabilityFilterChange,
          onStatusFilterChange: handleStatusFilterChange,
          onApplyAllFilter: handleApplyAllFilter,
          activeAssignment,
          visibleOverview,
          onNavigationClick: handleNavigationClick,
          onOverviewClose: handleOnOverviewClose,
          onWorkAssignmentClick: handleWorkAssignmentClick,
          replyToAssignmentRequest,
          removeRequestReplied,
          searchSingleWorkAssignment,
          cleanReplyToRequest,
          assignmentRequestFulfilled,
          hideOverview: () => setVisibleAssignmentOverview(false),
          activeExceptionAvailability,
          onAvailabilityExceptionClick: handleAvailabilityExceptionClick,
          removeActiveExceptionAvailability: handleResetActiveAvailabilityException,
          activeSemesterAllocation,
          onActiveSemesterAllocationClick: handleActiveSemesterAllocationClick,
          removeActiveSemesterAllocation: handleResetActiveSemesterAllocation,
          isAvailabilityFilterApplied,
        }}
      >
        <DashboardSideBar />
        <CalendarLayout />
      </CalendarContext.Provider>
    </>
  );
};

export default CalendarContainer;
