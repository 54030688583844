/* eslint-disable no-useless-escape */
/* eslint-disable no-console */
import { createBrowserHistory } from 'history';
import { isDev } from 'config';
import Translate from 'components/atoms/Translate';
import { errorCodeMap } from 'constant';
import { logApiLevel, serviceLog } from './logUtil';

export const history = createBrowserHistory();

export function consoleLog(message, type = 'log') {
  if (isDev) {
    const fn = console[type];
    if (console && fn) {
      fn(message);
    }
  }
}

export function transvoiceLog(message, type = logApiLevel.info) {
  if (type === logApiLevel.info) {
    consoleLog(message, 'log');
  } else if (type === logApiLevel.error) {
    serviceLog(message, type);
  }
}

export const getErrorMessage = error =>
  errorCodeMap[error.ErrorCode]
    ? Translate({
        content: `errors.${errorCodeMap[error.ErrorCode]}`,
      })
    : error.ErrorMessage || error;

export const iconForTheServiceType = service => {
  let iconClass = 'fa fa-map-marker';
  if (service.toLowerCase().includes('tele')) {
    iconClass = 'phone';
  }
  if (service.includes('Utgående')) {
    iconClass = 'phone_forwarded';
  }
  if (service.includes('Kont')) {
    iconClass = 'directions_walk';
  }
  if (service.includes('Video')) {
    iconClass = 'videocam';
  }
  if (service.includes('Skype')) {
    iconClass = 'fab fa-skype';
  }
  if (service.includes('Simult')) {
    iconClass = 'hearing';
  }
  if (service.includes('Översä')) {
    iconClass = 'translate';
  }
  return iconClass;
};

export const validPasswordFormat = passwordStr => {
  const errorMessage = [];
  if (passwordStr === undefined || !passwordStr)
    return { isValid: true, message: '' };

  if (passwordStr.length < 8) {
    return { isValid: false, message: 'minst 8 tecken' };
  }
  if (passwordStr.length > 256) {
    return { isValid: false, message: 'maximum 256 characters' };
  }
  if (passwordStr.search(/[0-9]/) < 0) {
    return { isValid: false, message: 'minst en siffra' };
  }
  if (
    passwordStr.search(/[A-Z]/) < 0 &&
    !/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/.test(passwordStr)
  ) {
    return {
      isValid: false,
      message: 'minst en stor bokstav eller specialtecken',
    };
  }

  // if (errorMessage.length > 0) {
  //   return { isValid: false, message: errorMessage.join(', ') };
  // }

  return { isValid: true, message: '' };
};

export const notificationHandler = location => {
  const searchParameter = location?.search;
  let notificationId;
  if (searchParameter && searchParameter.includes('notification_id')) {
    const notificationSearchParameter = /notification_id=(.*)/gm.exec(
      searchParameter,
    ) || [null];
    // eslint-disable-next-line prefer-destructuring
    notificationId = notificationSearchParameter[1];
    return notificationId;
  }
  return null;
};

export const scrollElementTop = (
  scrollElement,
  scrollContainer,
  maxDistance = 200,
) => {
  if (scrollElement && scrollContainer) {
    // eslint-disable-next-line no-param-reassign
    scrollContainer.scrollTop =
      scrollElement.offsetTop - maxDistance < 0
        ? scrollElement.offsetTop
        : scrollElement.offsetTop - maxDistance;
  }
};

export const isNumFieldValidate = e => {
  if (e.key === '-' || e.key === '+' || e.key === 'e') {
    e.preventDefault();
    return false;
  }
  return true;
};

export const sleepTime = ms => new Promise(resolve => setTimeout(resolve, ms));
export const isMobile = {
  Android() {
    return navigator.userAgent.match(/Android/i);
  },
  BlackBerry() {
    return navigator.userAgent.match(/BlackBerry/i);
  },
  iOS() {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i);
  },
  Opera() {
    return navigator.userAgent.match(/Opera Mini/i);
  },
  Windows() {
    return navigator.userAgent.match(/IEMobile/i);
  },
  any() {
    return (
      isMobile.Android() ||
      isMobile.BlackBerry() ||
      isMobile.iOS() ||
      isMobile.Opera() ||
      isMobile.Windows()
    );
  },
};
