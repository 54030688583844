import React from 'react';
import { Modal, Button } from 'components';
import PropTypes from 'prop-types';

const RemoveSickLeave = ({ onCancel, onClick }) => (
  <Modal isVisible className="tv-sick-leave-confirmation__container" hideHeader>
    <div className="tv-sick-leave-confirmation__content">
      <div className="tv-sick-leave-confirmation__content__title">
        Friskanmälan
      </div>
      <div className="tv-sick-leave-confirmation__content__body">
        Bekräfta att du är frisk och redo att ta emot uppdrag genom att klicka
        på knappen nedanför.
      </div>
    </div>
    <div className="tv-sick-leave-confirmation__actions">
      <Button
        label="Avbryt"
        className="tv-sick-leave-confirmation__actions-button"
        palette="outline"
        onClick={onCancel}
      />
      <Button
        label="Bekräfta"
        className="tv-sick-leave-confirmation__actions-button"
        onClick={onClick}
      />
    </div>
  </Modal>
);

RemoveSickLeave.propTypes = {
  onCancel: PropTypes.func,
  onClick: PropTypes.func,
};

RemoveSickLeave.defaultProps = {
  onCancel: () => {},
  onClick: () => {},
};

export default RemoveSickLeave;
