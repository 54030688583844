/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { Translate, AssignmentItem } from 'components';

import './style.scss';

const AssignmentList = ({
  list,
  screen,
  onClick,
  isFiltering,
  dateTimeSorted,
  sortDateTime,
  selectedItem,
}) => (
  <div className="tv-assignment-list__container">
    <h2 className="tv-assignment-list-title tv-mobile--hide">
      {Translate({ content: 'assignment.assignment' })}
    </h2>
    <div className="tv-assignment-list__assignment-list">
      <div className="row tv-assignment-list-header">
        <div className="tv-assignment-list-header__item tv-assignment-list-header__item-r order-0">
          {Translate({ content: 'assignment.typeOfBooking' })}
        </div>
        <div
          onClick={() => sortDateTime(!dateTimeSorted, 'sort')}
          className="tv-assignment-list-header__item   order-1 "
          role="button"
        >
          <div className="sort-column">
            {Translate({ content: 'assignment.dateAndTime' })}
            <div
              className={dateTimeSorted ? 'sort-icon' : 'sort-icon-transform'}
              style={{
                background: "url('/images/arrow-down.svg')",
                backgroundRepeat: 'no-repeat',
              }}
            />
          </div>
        </div>
        <div className="tv-assignment-list-header__item  order-2">
          {Translate({ content: 'assignment.assignmentNumber' })}
        </div>
        <div className="tv-assignment-list-header__item  order-3">
          {Translate({ content: 'assignment.customerName' })}
        </div>
        <div className="tv-assignment-list-header__item  order-4">
          {Translate({ content: 'assignment.language' })}
        </div>
        <div className="tv-assignment-list-header__item  order-5 text-md-right pr-5 mr-md-4">
          {Translate({ content: 'assignment.status' })}
        </div>
      </div>
      <hr className="tv-mobile--hide" />

      {isEmpty(list) && !isFiltering && (
        <p className="tv-assignment-list-empty-para d-flex justify-content-center">
          Du har inga uppdrag att överblicka efter valda sökkriterier.
        </p>
      )}
      {isEmpty(list) && isFiltering && (
        <p>
          <div className="tv-assignment-list-empty d-flex justify-content-center flex-column">
            <div
              className="tv-assignment-list-empty-img"
              style={{
                background: "url('/images/empty-search.svg')",
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
              }}
            />
            <p>
              Tyvärr gav din sökning inget resultat. Försök igen med att ändra
              sökkriterier.
            </p>
          </div>
        </p>
      )}
      {list.map((item, index) => (
        <AssignmentItem
          isSelected={
            selectedItem?.WorkAssignmentIdentifier ===
            item.WorkAssignmentIdentifier
          }
          key={item.key}
          onClick={onClick}
          screen={screen}
          item={item}
          className={index % 2 === 1 ? 'tv-assignment-list-bg-white' : ''}
        />
      ))}
    </div>
  </div>
);

AssignmentList.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({})),
  onClick: PropTypes.func,
  screen: PropTypes.string,
  isFiltering: PropTypes.bool,
  sortDateTime: PropTypes.func,
  dateTimeSorted: PropTypes.bool,
  selectedItem: PropTypes.shape({ WorkAssignmentIdentifier: '' }),
};
AssignmentList.defaultProps = {
  list: [],
  onClick: () => {},
  screen: 'assignment',
  isFiltering: false,
  sortDateTime: () => {},
  dateTimeSorted: true,
  selectedItem: { WorkAssignmentIdentifier: '' },
};

export default AssignmentList;
