import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const IconButton = ({ iconName, className, onClick }) => (
  <button
    type="button"
    onClick={onClick}
    className={`tv-icon_button ${className}`}
  >
    <i className={`fa fa-${iconName}`} />
  </button>
);

IconButton.propTypes = {
  iconName: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

IconButton.defaultProps = {
  iconName: '',
  className: '',
  onClick: () => {},
};

export default IconButton;
