/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useState } from 'react';
import { ReactReduxContext, useSelector } from 'react-redux';
import { resourceCreateRequest, workAssignmentHidden } from 'store/actions';
import { AssignmentOverview, Spinner, GeneralSuccessModal } from 'components';
import { fulfilled, pending, clean } from 'redux-saga-thunk';
import { useWorkAssignment } from 'hooks';

function AssignmentOverviewContainer({ item = {}, ...props }) {
  const { store } = useContext(ReactReduxContext);
  const { dispatch } = store;
  const resource = 'assignmentVisibility';
  const {
    searchSingleWorkAssignment,
    workAssignment,
    singleAssignmentRetriveFulfilled,
  } = useWorkAssignment();
  const isAssignmentHidden = useSelector(state =>
    fulfilled(state, `${resource}Create`),
  );
  const isAssignmentHiddingPending = useSelector(state =>
    pending(state, `${resource}Create`),
  );
  const [itemState, setItemState] = useState(item);
  const [showCancelSuccessModal, setShowCancelSuccessModal] = useState(false);

  useEffect(() => {
    setItemState(item);
  }, [item]);

  useEffect(() => {
    if (
      singleAssignmentRetriveFulfilled &&
      workAssignment.WorkAssignmentIdentifier ===
        itemState.WorkAssignmentIdentifier
    ) {
      setItemState(workAssignment);
    }
  }, [singleAssignmentRetriveFulfilled]);

  useEffect(() => {
    if (isAssignmentHidden) {
      dispatch(workAssignmentHidden(item.WorkAssignmentIdentifier));
      setShowCancelSuccessModal(true);
      searchSingleWorkAssignment(item.WorkAssignmentIdentifier);
      dispatch(clean(`${resource}Create`));
      setItemState({ ...item, IsVisible: false });
    }
  }, [isAssignmentHidden]);

  const handleHideAssignment = ({ WorkAssignmentIdentifier }) => {
    const params = {
      IsVisible: false,
      WorkAssignmentIdentifier,
    };
    dispatch(resourceCreateRequest(resource, params));
  };

  return (
    <>
      <AssignmentOverview
        {...props}
        item={itemState}
        onHideAssignment={handleHideAssignment}
      />
      {showCancelSuccessModal && (
        <GeneralSuccessModal
          title="Du har tagit bort ett avbokat uppdrag från kalendern."
          body="Du kan fortfarande se ditt avbokade uppdrag på din uppdragssida"
          isVisible={showCancelSuccessModal}
          onClick={() => {
            setShowCancelSuccessModal(false);
          }}
        />
      )}
      {isAssignmentHiddingPending && <Spinner />}
    </>
  );
}

AssignmentOverviewContainer.propTypes = {};

export default AssignmentOverviewContainer;
