import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import { Translate, Checkbox, Button, DatePicker } from 'components';
import dayjs from 'dayjs';

const SickLeave = ({
  onDisplay,
  displaySuccess,
  onDatePickerChange,
  selectedDate,
  isChecked,
  handleChangeCheckBox,
  earlistSickDate,
  onRemove,
}) => (
  <div className="tv-sick-leave__container">
    <div className="tv-sick-leave__title">
      {Translate({ content: 'sickLeave.title' })}
    </div>

    {!displaySuccess && (
      <div className="tv-sick-leave__wrapper">
        <div className="tv-sick-leave__wrapper__content">
          {Translate({ content: 'sickLeave.content' })}
        </div>
        <div className="tv-sick-leave__wrapper-control">
          <Checkbox
            text={Translate({ content: 'sickLeave.endDate' })}
            checked={isChecked}
            onClick={handleChangeCheckBox}
            controlClassName="tv-checkbox__control-color"
          />
        </div>
        {isChecked && (
          <div className="tv-sick-leave__wrapper-control-date">
            <label htmlFor="Datum">
              {Translate({ content: 'sickLeave.selectEndDate' })}
            </label>
            <DatePicker
              // minDate={new Date()}
              label="Datum"
              name="Datum"
              placeholderText="Datum"
              onChange={onDatePickerChange}
              selectedDate={selectedDate}
            />
          </div>
        )}
        <div className="tv-sick-leave__actions">
          <div className="tv-sick-leave__actions__row">
            <Button
              label={Translate({ content: 'sickLeave.submit' })}
              className="tv-sick-leave__actions-button"
              onClick={onDisplay}
            />
          </div>
        </div>
      </div>
    )}
    {displaySuccess && (
      <div className="tv-sick-leave__wrapper">
        <div className="tv-sick-leave__wrapper__content">
          <div>
            Du är sjukanmäld sedan{' '}
            {dayjs(earlistSickDate).format('DD MMMM YYYY')}.
          </div>
          <div>Glöm ej att friskanmäla dig när du mår bättre. Krya på dig!</div>
        </div>
        <div className="tv-sick-leave__actions">
          <div className="tv-sick-leave__actions__row">
            <Button
              label={Translate({ content: 'sickLeave.successButton' })}
              className="tv-sick-leave__actions-button"
              onClick={onRemove}
            />
          </div>
        </div>
      </div>
    )}
  </div>
);

SickLeave.propTypes = {
  onDisplay: PropTypes.func,
  displaySuccess: PropTypes.bool,
  onDatePickerChange: PropTypes.func,
  selectedDate: PropTypes.shape({}),
  isChecked: PropTypes.bool,
  handleChangeCheckBox: PropTypes.func,
  earlistSickDate: PropTypes.string,
  onRemove: PropTypes.func,
};

SickLeave.defaultProps = {
  onDisplay: () => {},
  displaySuccess: false,
  onDatePickerChange: () => {},
  selectedDate: null,
  isChecked: false,
  handleChangeCheckBox: () => {},
  earlistSickDate: '',
  onRemove: () => {},
};

export default SickLeave;
