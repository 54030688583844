/* eslint-disable react/prop-types */
import React, { useState, useEffect, useReducer } from 'react';
import {
  AssignmentList,
  AssignmentOverview,
  FilterPanel,
  Field,
  Translate,
  Button,
  AssignmentRequestList,
  Dropdown,
  AssignmentRequestModal,
  Spinner,
  GeneralSuccessModal,
} from 'components';
import { useWorkAssignment } from 'hooks';
import {
  localDayjs as dayJS,
  currentDate,
  extendedDayJs,
  convertToDayJsObject,
  currentSWTime,
  convertToSWDayJsObject,
} from 'utils/dateTimeUtils';
import AssignmentListContext, {
  assignmentListReducer,
  assignmentListInitialState,
  filterDataSet,
  generateMeetingTypes,
  sortAssignmentRequestList,
  CALENDAR_VIEW,
} from 'context/AssignmentListContext';
import { AssignmentOverviewContainer } from 'containers';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import { modalTypes, status } from 'constant';
import { useHistory } from 'react-router-dom';
import { notificationHandler } from 'utils/miscUtil';
import { connect } from 'react-redux';
import {
  displayRatedAssignmentModal,
  resourceDetailReadRequest,
} from 'store/actions';
import PropTypes from 'prop-types';

const assignmentListTabs = [
  {
    name: 'requests',
    label: 'Förfrågningar',
  },
  {
    name: 'assignments',
    label: 'Uppdrag',
  },
];

const AssignmentListPageContainer = ({
  onDisplayRatedAssignment,
  getRatedWorkAssignments,
}) => {
  const {
    workAssignmentList,
    workAssignment,
    assignmentRequestList,
    searchWorkAssignment,
    searchSingleWorkAssignment,
    replyToAssignmentRequest,
    assignmentRequestFulfilled,
    isLoading,
    cleanReplyToRequest,
    // newNotifications,
    removeRequestReplied,
    ratedWorkAssignmentList,
  } = useWorkAssignment();

  const [state, dispatch] = useReducer(
    assignmentListReducer,
    assignmentListInitialState,
  );
  const history = useHistory();
  const { location } = history;
  const locationState = location.state;

  const {
    searchDateRange,
    visibleWorkAssignment,
    visibleAssignmentRequestList,
    typeFilterValue,
    selectedFilter,
    isFiltering,
    dateTimeSorted,
    dateTimeSortedAssignmentRequests,
  } = state;

  const [searchQuery, setSearchQuery] = useState('');
  const [assignment, setAssignment] = useState({});
  const [visibleOverview, setVisibleAssignmentOverview] = useState(false);
  const [meetingTypes, setMeetingTypes] = useState([]);
  const [activeTabView, setActiveTabView] = useState(assignmentListTabs[0]);
  const [
    displayAssignmentFilterPanel,
    setDisplayAssignmentFilterPanel,
  ] = useState(false);
  const [
    showAssignmentUnavailabilityModal,
    setShowAssignmentUnavailabilityModal,
  ] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [assignmentRequestAction, setAssignmentRequestAction] = useState({});
  const [assignmentRequestConsent, setAssignmentRequestConsent] = useState(
    false,
  );
  const [updateLocation, setUpdateLocation] = useState(null);

  // useEffect(() => {
  //   if (location.search) {
  //     const notificationId = notificationHandler(location);
  //     const matchNotification = newNotifications.find(
  //       x => x.NotificationIdentifier === notificationId,
  //     );

  //     if (matchNotification) {
  //       // eslint-disable-next-line no-use-before-define
  //       pushNotificationClick(notificationId, matchNotification);
  //     }
  //   }
  //   console.log(newNotifications);
  // }, [newNotifications]);

  useEffect(() => {
    if (locationState) {
      if (locationState?.status === 'Utförd') {
        setActiveTabView(assignmentListTabs[1]);
        dispatch(['setStatusFilter', [locationState.status]]);
        history.replace();
      }
      if (locationState?.status === 'Avbokad') {
        setActiveTabView(assignmentListTabs[1]);

        const selectedAssignment = workAssignmentList.find(
          x => x.WorkAssignmentIdentifier === locationState?.workAssignmentId,
        );
        if (selectedAssignment) {
          setVisibleAssignmentOverview(selectedAssignment);
          setAssignment(selectedAssignment);
          history.replace();
        } else {
          searchSingleWorkAssignment(locationState.workAssignmentId);
        }
      }
      if (locationState?.status === 'inquiry') {
        setActiveTabView(assignmentListTabs[0]);
        const selectedAssignmentRequest = assignmentRequestList.find(
          x =>
            x.WorkAssignmentReference.WorkAssignmentIdentifier ===
            locationState?.workAssignmentId,
        );
        if (selectedAssignmentRequest) {
          setVisibleAssignmentOverview(selectedAssignmentRequest);
          setAssignment(selectedAssignmentRequest);
          history.replace();
        }
      }
      if (locationState?.status === 'generalRequest') {
        setActiveTabView(assignmentListTabs[1]);
        const selectedAssignmentRequestFulfilled = workAssignmentList.find(
          x => x.WorkAssignmentIdentifier === locationState?.workAssignmentId,
        );
        if (selectedAssignmentRequestFulfilled) {
          setVisibleAssignmentOverview(selectedAssignmentRequestFulfilled);
          setAssignment(selectedAssignmentRequestFulfilled);
          history.replace();
        } else {
          setShowAssignmentUnavailabilityModal(true);
          setVisibleAssignmentOverview(false);
          setAssignment({});
          history.replace();
        }
      }
      if (locationState?.status === 'ratedAssignments') {
        setActiveTabView(assignmentListTabs[1]);

        const selectedAssignment = workAssignmentList.find(
          x => x.WorkAssignmentIdentifier === locationState?.workAssignmentId,
        );
        if (selectedAssignment) {
          setVisibleAssignmentOverview(selectedAssignment);
          setAssignment(selectedAssignment);
          setUpdateLocation(locationState);
          getRatedWorkAssignments();
          history.replace();
        } else {
          searchSingleWorkAssignment(locationState.workAssignmentId);
          getRatedWorkAssignments();
        }
      }
    }

    if (locationState === null || locationState?.status !== 'Avbokad') {
      searchWorkAssignment(false, searchDateRange, true);
    }

    setAssignmentRequestConsent(false);
  }, [locationState]);

  useEffect(() => {
    if (
      locationState?.status === 'Avbokad' &&
      workAssignment?.WorkAssignmentIdentifier
    ) {
      setActiveTabView(assignmentListTabs[1]);
      setVisibleAssignmentOverview(workAssignment);
      setAssignment(workAssignment);
      history.replace();
    }
  }, [workAssignment?.WorkAssignmentIdentifier]);

  useEffect(() => {
    if (
      locationState?.status === 'ratedAssignments' &&
      workAssignment?.WorkAssignmentIdentifier
    ) {
      setActiveTabView(assignmentListTabs[1]);
      setVisibleAssignmentOverview(workAssignment);
      setAssignment(workAssignment);
      history.replace();
    }
  }, [workAssignment?.WorkAssignmentIdentifier]);

  useEffect(() => {
    if (ratedWorkAssignmentList) {
      const { RatedWorkAssignments } = ratedWorkAssignmentList;
      let ratedAssignmentDetail;
      if (updateLocation) {
        ratedAssignmentDetail = RatedWorkAssignments?.find(
          i => i.WorkAssignmentIdentifier === updateLocation?.workAssignmentId,
        );
      } else {
        ratedAssignmentDetail = RatedWorkAssignments?.find(
          i => i.WorkAssignmentIdentifier === locationState?.workAssignmentId,
        );
      }

      if (ratedAssignmentDetail) {
        const params = {
          isRatedAssignment: true,
          ratedAssignment: ratedAssignmentDetail,
        };
        onDisplayRatedAssignment(params);
        // history.replace();
      }
    }
   
  }, [ratedWorkAssignmentList]);

  useEffect(() => {
    if (assignmentRequestFulfilled) {
      removeRequestReplied(assignmentRequestAction.requestId);
      if (assignmentRequestAction.action) {
        searchSingleWorkAssignment(assignmentRequestAction.workAssignmentId);
      } else {
        setVisibleAssignmentOverview(false);
      }
      setAssignmentRequestAction({});
      cleanReplyToRequest();
    }
  }, [assignmentRequestFulfilled]);

  useEffect(() => {
    const assignments = [];
    assignments.push(...workAssignmentList);
    dispatch([
      'setVisibleWorkAssignment',
      filterDataSet(
        typeFilterValue,
        selectedFilter,
        assignments,
        dateTimeSorted,
      ),
    ]);
    setMeetingTypes([
      {
        name: 'Välj',
        value: '',
      },
      ...generateMeetingTypes(assignments),
    ]);
    dispatch(['setIsFiltering', false]);
  }, [workAssignmentList]);

  useEffect(() => {
    dispatch([
      'setVisibleAssignmentRequestList',
      sortAssignmentRequestList(
        assignmentRequestList.filter(
          x =>
            convertToSWDayJsObject(x.DatetimeExpiration).diff(
              currentSWTime(),
              'seconds',
            ) > 0,
        ),
        dateTimeSortedAssignmentRequests,
      ),
    ]);

    dispatch(['setIsFiltering', false]);
  }, [assignmentRequestList]);

  useEffect(() => {
    const resourceRequestCleanUpInterval = setInterval(
      list => {
        if (list) {
          const filteredRequest = list.filter(
            x =>
              convertToSWDayJsObject(x.DatetimeExpiration).diff(
                currentSWTime(),
                'seconds',
              ) > 0,
          );
          if (list.length !== filteredRequest.length) {
            dispatch(['setVisibleAssignmentRequestList', filteredRequest]);
          }
          if (filteredRequest.length === 0) {
            clearInterval(resourceRequestCleanUpInterval);
          }
        }
      },
      60000,
      visibleAssignmentRequestList,
    );
    if (locationState?.status === 'inquiry') {
      setActiveTabView(assignmentListTabs[0]);
      const selectedAssignment = visibleAssignmentRequestList.find(
        x => x.WorkAssignmentIdentifier === locationState?.workAssignmentId,
      );
      if (selectedAssignment) {
        setVisibleAssignmentOverview(selectedAssignment);
        setAssignment(selectedAssignment);
        history.replace();
      }
    }
    return () => {
      clearInterval(resourceRequestCleanUpInterval);
    };
  }, [visibleAssignmentRequestList]);

  const handleDateRangeChange = event => {
    const [startDate, endDate] = event;

    if (endDate) {
      const newSearchRange = {
        start: dayJS(startDate).startOf('day'),

        end: dayJS(endDate).endOf('day'),
      };
      dispatch(['setSearchDateRange', newSearchRange]);
      searchWorkAssignment(false, newSearchRange, true);
    }
  };

  const searchHandler = event => {
    const {
      target: { value },
    } = event;
    const searchValue = value.trim();

    if (!isEqual(searchValue, searchQuery)) {
      searchWorkAssignment(
        false,
        searchDateRange,
        true,
        searchValue === '' ? null : searchValue,
      );
      setSearchQuery(value);
    }
  };

  const assignmentRequestHandler = (action, requestId, workAssignmentId) => {
    if (action) {
      setShowApproveModal(!showApproveModal);
    } else {
      setShowRejectModal(!showRejectModal);
    }
    setAssignmentRequestAction({ action, requestId, workAssignmentId });
    setAssignmentRequestConsent(true);
  };

  const onKeyPress = event => {
    const { keyCode } = event;
    if (keyCode === 13) {
      searchHandler(event);
    }
  };

  const handleTypeFilter = event => {
    const { value } = event;
    dispatch([
      'setVisibleWorkAssignment',
      filterDataSet(value, selectedFilter, workAssignmentList, dateTimeSorted),
    ]);
    dispatch(['setTypeFilter', value]);
  };

  const handleStatusFilter = event => {
    dispatch([
      'setVisibleWorkAssignment',
      filterDataSet(typeFilterValue, event, workAssignmentList, dateTimeSorted),
    ]);
    dispatch(['setStatusFilter', event]);
  };

  const handleSort = event => {
    dispatch([
      'setVisibleWorkAssignment',
      filterDataSet(typeFilterValue, selectedFilter, workAssignmentList, event),
    ]);
    dispatch(['sortDateTime', event]);
  };

  const handleSortRequestAssignments = event => {
    if (assignmentRequestList && assignmentRequestList.length > 0) {
      dispatch([
        'setVisibleAssignmentRequestList',
        sortAssignmentRequestList(assignmentRequestList, event),
      ]);
    }
    dispatch(['sortDateTimeAssignmentRequests', event]);
  };

  const handleFilterChange = (event, identifier) => {
    switch (identifier) {
      case 'datePicker':
        handleDateRangeChange(event);

        break;

      case 'searchText':
        searchHandler(event);
        break;

      case 'type':
        handleTypeFilter(event);
        dispatch(['setIsFiltering', true]);
        break;

      case 'status':
        handleStatusFilter(event);
        dispatch(['setIsFiltering', true]);

        break;
      case 'sort':
        handleSort(event);
        break;
      case 'sortRequestAssignments':
        handleSortRequestAssignments(event);
        break;

      default:
        break;
    }
  };

  const onRowClick = item => {
    setVisibleAssignmentOverview(item);
    setAssignment(item);
  };

  const NavigationCloseHandler = () => {
    setAssignment({});
    setVisibleAssignmentOverview(false);
  };

  const handleTabSwitching = tab => {
    setActiveTabView(tab);
  };

  const handleClearFilter = () => {
    const newSearchRange = {
      start: currentDate()
        .startOf(CALENDAR_VIEW.MONTH)
        .subtract(1, CALENDAR_VIEW.MONTH),
      end: currentDate().endOf(CALENDAR_VIEW.MONTH).add(1, CALENDAR_VIEW.MONTH),
    };

    if (!isEqual(newSearchRange, searchDateRange)) {
      searchWorkAssignment(false, newSearchRange, true);
      dispatch(['setTypeFilter', '']);
      dispatch([
        'setStatusFilter',
        [
          status.reported.name,
          status.accept.name,
          status.performed.name,
          status.cancelled.name,
          status.inquiry.name,
          status.replanning.name,
        ],
      ]);
      dispatch(['setSearchDateRange', newSearchRange]);
    } else {
      dispatch(['setTypeFilter', '']);
      dispatch(['setSearchDateRange', newSearchRange]);
      dispatch([
        'setStatusFilter',
        [
          status.reported.name,
          status.accept.name,
          status.performed.name,
          status.cancelled.name,
          status.inquiry.name,
          status.replanning.name,
        ],
      ]);
      dispatch(['sortDateTime', true]);
      dispatch([
        'setVisibleWorkAssignment',
        filterDataSet(
          '',
          [
            status.reported.name,
            status.accept.name,
            status.performed.name,
            status.cancelled.name,
            status.inquiry.name,
          ],
          workAssignmentList,
          true,
        ),
      ]);
    }
  };

  const handleAllFilters = (statusFilter, typeFilter, dateRange) => {
    const [selectedFromDate, selectedToDate] = dateRange;

    const newSearchRange = {
      start: dayJS(selectedFromDate).startOf('day'),

      end: dayJS(selectedToDate).endOf('day'),
    };

    if (!isEqual(newSearchRange, searchDateRange)) {
      searchWorkAssignment(false, newSearchRange, true);
      dispatch(['setTypeFilter', typeFilter]);
      dispatch(['setStatusFilter', statusFilter]);
      dispatch(['setSearchDateRange', newSearchRange]);
    } else {
      dispatch([
        'setVisibleWorkAssignment',
        filterDataSet(typeFilter, statusFilter, workAssignmentList, true),
      ]);
      dispatch(['setTypeFilter', typeFilter]);
      dispatch(['setStatusFilter', statusFilter]);
    }
  };

  const NavigationClickHandler = action => {
    const navigateList = [
      ...visibleWorkAssignment,
      ...visibleAssignmentRequestList,
    ];
    if (action === 'next') {
      const index = navigateList.findIndex(
        item =>
          assignment?.WorkAssignmentIdentifier ===
          item.WorkAssignmentIdentifier,
      );
      const nextIndex = index + 1 < navigateList.length ? index + 1 : 0;
      setAssignment(navigateList[nextIndex]);
    }
    if (action === 'previous') {
      const index = navigateList.findIndex(
        item =>
          assignment?.WorkAssignmentIdentifier ===
          item.WorkAssignmentIdentifier,
      );
      const previousIndex =
        index - 1 >= 0 ? index - 1 : navigateList.length - 1;
      setAssignment(navigateList[previousIndex]);
    }
  };

  const handleAssignmentRequestModalActions = (action, modalType) => {
    const query = {
      IsAcceptingRequest: assignmentRequestAction.action,
      ResourceRequestIdentifier: assignmentRequestAction.requestId,
    };

    if (modalType === modalTypes.APPROVE) {
      if (action === modalTypes.APPROVE) {
        replyToAssignmentRequest(query);
        setShowApproveModal(!showApproveModal);
      } else {
        setShowApproveModal(!showApproveModal);
      }
    } else if (action === modalTypes.APPROVE) {
      setShowRejectModal(!showRejectModal);
    } else {
      replyToAssignmentRequest(query);
      setShowRejectModal(!showRejectModal);
    }
  };

  const renderAssignmentRequestModals = () => {
    if (showApproveModal) {
      return (
        <AssignmentRequestModal
          isVisible={showApproveModal}
          modalType={modalTypes.APPROVE}
          onClose={() => setShowApproveModal(!showApproveModal)}
          title="Godkänn allmänna villkor"
          showCheckBox
          rejectButtonLabel="Avbryt"
          acceptanceButtonLabel="Acceptera uppdraget"
          onClick={action =>
            handleAssignmentRequestModalActions(action, modalTypes.APPROVE)
          }
        >
          <p>
            Genom att acceptera uppdraget godkänner du våra allmänna villkor och
            riktlinjer för tolkuppdrag
          </p>
        </AssignmentRequestModal>
      );
    }
    if (showRejectModal) {
      return (
        <AssignmentRequestModal
          isVisible={showRejectModal}
          modalType={modalTypes.REJECT}
          onClose={() => setShowRejectModal(!showRejectModal)}
          title="Neka förfrågan"
          acceptanceButtonLabel="Neka förfrågan"
          rejectButtonLabel="Avbryt"
          onClick={action =>
            handleAssignmentRequestModalActions(action, modalTypes.REJECT)
          }
        >
          <p>
            Är du säker på att du vill neka uppdraget? Om du nekar många
            förfrågningar så kan det komma att påverka hur många framtida
            förfrågningar du får
          </p>
        </AssignmentRequestModal>
      );
    }
    return null;
  };
  const onAssignmentEarlyCompleted = assignmentId => {
    searchSingleWorkAssignment(assignmentId);
  };
  return (
    <>
      {showAssignmentUnavailabilityModal && (
        <GeneralSuccessModal
          title="Det här uppdraget är inte längre tillgängligt."
          body="Kontakta Transvoice vid eventuella frågor"
          isVisible={showAssignmentUnavailabilityModal}
          onClick={() => {
            setShowAssignmentUnavailabilityModal(false);
          }}
        />
      )}
      <div className="tv-assignment-list__side-panel">
        {visibleOverview && (
          <AssignmentOverviewContainer
            NavigationClickHandler={NavigationClickHandler}
            onClickClose={NavigationCloseHandler}
            onAccept={assignmentRequestHandler}
            onReject={assignmentRequestHandler}
            item={assignment}
            onAssignmentEarlyCompleted={onAssignmentEarlyCompleted}
          />
        )}
        <ListSwitcher
          activeTab={activeTabView}
          onTabClick={handleTabSwitching}
        />
        {activeTabView && activeTabView.label === assignmentListTabs[1].label && (
          <Field
            label=""
            placeholder={Translate({ content: 'filterPanel.search' })}
            name="searchText"
            icon="search"
            showIcon
            onBlur={event => handleFilterChange(event, 'searchText')}
            onKeyUp={onKeyPress}
            className={{
              containerClass: 'filter-panel__search tv-mobile--show',
            }}
          />
        )}
        {!visibleOverview && (
          <FilterPanel
            onChange={handleFilterChange}
            onClose={() => setDisplayAssignmentFilterPanel(false)}
            showPanel={displayAssignmentFilterPanel}
            onKeyPress={onKeyPress}
            options={meetingTypes}
            selectedFilter={selectedFilter}
            typeFilterValue={typeFilterValue}
            searchDateRange={searchDateRange}
            handleClearFilter={handleClearFilter}
            handleAllFilters={handleAllFilters}
          />
        )}
      </div>
      <div className="d-flex flex-column w-100">
        {isLoading && <Spinner />}
        {renderAssignmentRequestModals()}
        <div
          className={`tv-assignment-list__assignments ${
            activeTabView.label === assignmentListTabs[0].label
              ? ''
              : 'tv-mobile--hide'
          }`}
        >
          <AssignmentRequestList
            list={visibleAssignmentRequestList}
            screen="availableRequest"
            onAccept={assignmentRequestHandler}
            onReject={assignmentRequestHandler}
            dateTimeSortedAssignmentRequests={dateTimeSortedAssignmentRequests}
            sortDateTime={handleFilterChange}
            isLoading={isLoading}
            selectedItem={assignment}
            onClick={onRowClick}
            assignmentRequestConsent={assignmentRequestConsent}
          />
        </div>
        <div
          className={`tv-assignment-list__assignments ${
            activeTabView.label === assignmentListTabs[1].label
              ? ''
              : 'tv-mobile--hide'
          }`}
        >
          <AssignmentList
            list={visibleWorkAssignment}
            selectedItem={assignment}
            onClick={onRowClick}
            screen="assignment"
            isFiltering={isFiltering}
            dateTimeSorted={dateTimeSorted}
            sortDateTime={handleFilterChange}
          />
          <div className="tv-assignment-list__mobile-footer">
            <Button
              palette="outline"
              label={Translate({ content: 'assignment.filter' })}
              onClick={() => {
                setDisplayAssignmentFilterPanel(true);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

AssignmentListPageContainer.propTypes = {
  onDisplayRatedAssignment: PropTypes.func,
  getRatedWorkAssignments: PropTypes.func,
};
AssignmentListPageContainer.defaultProps = {
  onDisplayRatedAssignment: () => {},
  getRatedWorkAssignments: () => {},
};

const mapDispatchToProps = dispatch => ({
  onDisplayRatedAssignment: params => {
    dispatch(displayRatedAssignmentModal(params));
  },
  getRatedWorkAssignments: () => {
    dispatch(resourceDetailReadRequest('ratedWorkAssignments'));
  },
});

export default connect(null, mapDispatchToProps)(AssignmentListPageContainer);

const ListSwitcher = ({ onTabClick, activeTab }) => (
  <>
    <div className="w-100 tv-mobile--show mb-2">
      <Dropdown
        className="tv-tab_list__container-tabs-mobile"
        value={activeTab}
        options={assignmentListTabs}
        onChange={tab => {
          onTabClick(tab);
        }}
      />
    </div>
  </>
);
