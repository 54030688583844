import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

const DropdownMenuItem = props => {
  const { leftSideImage, heading, content, navigateTo, onClick } = props;
  return (
    <NavLink to={navigateTo} onClick={onClick}>
      <div className="tv-availability-list__menu-item">
        <img
          src={leftSideImage}
          className="tv-availability-list__menu-item__image"
          alt="left background"
        />
        <div className="tv-availability-list__menu-item__text">
          <div className="tv-availability-list__menu-item__text-heading">
            {heading}
          </div>
          <div className="tv-availability-list__menu-item__text-content">
            {content}
          </div>
        </div>
        <img
          src="../../images/keyboard_arrow_right.png"
          className="tv-availability-list__menu-item__arrow"
          alt="logo"
        />
      </div>
    </NavLink>
  );
};

DropdownMenuItem.propTypes = {
  leftSideImage: PropTypes.string,
  heading: PropTypes.string,
  content: PropTypes.string,
  navigateTo: PropTypes.string,
  onClick: PropTypes.func,
};

DropdownMenuItem.defaultProps = {
  leftSideImage: '',
  heading: '',
  content: '',
  navigateTo: '',
  onClick: () => {},
};

export default DropdownMenuItem;
