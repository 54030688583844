/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';
import { Button, DatePicker, TabButtonRow } from 'components';
import { localDayjs as dayjs } from 'utils/dateTimeUtils';
import './style.scss';

const tomorrowDate = dayjs().add(1, 'd').toDate();
function SemesterAllocation({
  onSave,
  allowResourceRequestChange,
  onAllowResourceRequestChange,
  tabs,
  selectedStartDate,
  selectedEndDate,
  onStartDatePickerChange,
  onEndDatePickerChange,
  validationError,
}) {
  return (
    <div className="tv-semester-allocation__container">
      <h4 className="tv-semester-allocation__title">Längre ledighet</h4>
      <p className="tv-semester-allocation__content">
        Här kan du lägga in längre ledighet eller semester. Fördelen med denna
        är att du kan lägga in att du ska vara borta en längre period utan att
        påverka din Schemalagda tillgänglighet, dvs din period av tillgänglighet
        efter din semester. Du kan också välja om du vill få förfrågningar för
        perioden efter din semester, under din semester.
      </p>
      <div className="tv-semester-allocation__wrapper">
        <div className="tv-semester-allocation__tab-container">
          <span>
            Jag vill ha förfrågningar under min ledighet, gällande perioden
            efter ledigheten
          </span>
          <TabButtonRow
            className="tab"
            tabs={tabs}
            activeTab={allowResourceRequestChange}
            onTabClick={onAllowResourceRequestChange}
          />
        </div>
        <div className="tv-semester-allocation__datepicker-container">
          <label htmlFor="startDate">Startdatum</label>
          <DatePicker
            selectedDate={selectedStartDate}
            minDate={tomorrowDate}
            label="Startdatum"
            name="startDate"
            placeholderText="Önskat datum"
            onChange={onStartDatePickerChange}
          />
        </div>
        <div className="tv-semester-allocation__datepicker-container">
          <label htmlFor="endDate">Slutdatum</label>
          <DatePicker
            selectedDate={selectedEndDate}
            minDate={tomorrowDate}
            label="Slutdatum"
            name="endDate"
            placeholderText="Önskat datum"
            onChange={onEndDatePickerChange}
          />
        </div>
      </div>
      {!validationError.isValid && (
        <div className="tv-semester-allocation__error">
          {validationError.errorMessage}
        </div>
      )}
      <div className="tv-semester-allocation__button-wrapper">
        <Button
          onClick={onSave}
          label="Lägg till"
          className="tv-semester-allocation__save"
        />
      </div>
    </div>
  );
}

SemesterAllocation.propTypes = {
  onSave: PropTypes.func,
  onAllowResourceRequestChange: PropTypes.func,
  onStartDatePickerChange: PropTypes.func,
  onEndDatePickerChange: PropTypes.func,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({ name: PropTypes.bool, label: PropTypes.string }),
  ),
  allowResourceRequestChange: PropTypes.bool,
  selectedStartDate: PropTypes.shape({}),
  selectedEndDate: PropTypes.shape({}),
  validationError: PropTypes.shape({
    isValid: PropTypes.bool,
    errorMessage: PropTypes.string,
  }),
};
SemesterAllocation.defaultProps = {
  onSave: () => {},
  onAllowResourceRequestChange: () => {},
  onStartDatePickerChange: () => {},
  onEndDatePickerChange: () => {},
  tabs: [
    { name: true, label: 'Ja' },
    { name: false, label: 'Nej' },
  ],
  allowResourceRequestChange: true,
  selectedStartDate: new Date(),
  selectedEndDate: new Date(),
  validationError: {
    isValid: true,
    errorMessage: '',
  },
};

export default SemesterAllocation;
