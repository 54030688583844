import { status } from 'constant';
import { createContext, useContext } from 'react';
import { currentDate, localDayjs as dayJS } from 'utils/dateTimeUtils';
import isEmpty from 'lodash/isEmpty';
import { clone } from 'lodash';

const AssignmentListContext = createContext();

export default AssignmentListContext;

// Assignment List Context consuming hook
export const useAssignmentList = () => {
  const context = useContext(AssignmentListContext);
  if (context === undefined) {
    throw new Error(
      'useAssignmentList must be used within a AssignmentListContext.Provider',
    );
  }
  return context;
};

// Calendar related Constants

export const CALENDAR_VIEW = {
  MONTH: 'month',
  WEEK: 'week',
  DAY: 'day',
};

/* export const meetingTypes = [
  {
    name: 'Vilaj',
    value: '',
  },
  {
    name: 'Kontakttolk',
    value: 'Kontakttolk',
  },
  {
    name: 'Telefontolk',
    value: 'Telefontolk',
  },
  {
    name: 'Videotolk',
    value: 'Videotolk',
  },
  {
    name: 'Utgående telefontolk',
    value: 'Utgående telefontolk',
  },
];
 */

export const generateMeetingTypes = assignmentList => {
  const types = [];
  assignmentList.map(item => {
    if (!types.some(type => type.name === item.ArticleName)) {
      types.push({ name: item.ArticleName, value: item.ArticleName });
    }
    return null;
  });
  return types;
};

// Assignment List Reducer
export const assignmentListInitialState = {
  searchDateRange: {
    start: currentDate()
      .startOf(CALENDAR_VIEW.MONTH)
      .subtract(1, CALENDAR_VIEW.MONTH),
    end: currentDate().endOf(CALENDAR_VIEW.MONTH).add(1, CALENDAR_VIEW.MONTH),
  },

  visibleWorkAssignment: [],
  visibleAssignmentRequestList: [],
  visibleSchedule: [],
  selectedFilter: [
    status.reported.name,
    status.accept.name,
    status.performed.name,
    status.cancelled.name,
    status.inquiry.name,
    status.replanning.name,
  ],
  searchTerm: '',
  typeFilterValue: '',
  isFiltering: false,
  dateTimeSorted: true,
  resetStatusFilter: false,
  dateTimeSortedAssignmentRequests: true,
};

const mapTranslateStatusToOriginal = filter => {
  let originalStatus = '';
  switch (filter) {
    case 'Förfrågan':
      originalStatus = 'inquiry';
      break;
    case 'Accepterad':
      originalStatus = 'accept';
      break;
    case 'Avbokad':
      originalStatus = 'cancelled';
      break;
    case 'Rapporterad':
      originalStatus = 'reported';
      break;
    case 'Utförd':
      originalStatus = 'performed';
      break;
    case 'Letar ersättare':
      originalStatus = 'replanning';
      break;

    default:
      break;
  }
  return originalStatus;
};

export const filterDataSet = (
  typeFilter = '',
  statusFilter = [],
  data,
  sortedAsc,
) => {
  let filteredAssignmentList = [];
  statusFilter.map(filter => {
    if (filter !== 'all-assignments') {
      const filteredAssignmentItems = data.filter(
        item => item.OrderStatus === mapTranslateStatusToOriginal(filter),
      );

      filteredAssignmentItems.forEach(filterItems => {
        filteredAssignmentList.push(filterItems);
      });
    } else {
      filteredAssignmentList = [];
    }
    return null;
  });
  let typeFilteredAssignmentList = [];
  if (typeFilter !== '') {
    const typeFilteredAssignments = filteredAssignmentList.filter(
      item => typeFilter === item.ArticleName,
    );
    typeFilteredAssignments.forEach(filterItems => {
      typeFilteredAssignmentList.push(filterItems);
    });
  } else {
    typeFilteredAssignmentList = filteredAssignmentList;
  }
  const sortedTypeFilteredAssignmentList = typeFilteredAssignmentList.sort(
    (a, b) => {
      if (sortedAsc) {
        return dayJS(b.DatetimeOrderFrom).diff(a.DatetimeOrderFrom);
      }
      return dayJS(a.DatetimeOrderFrom).diff(b.DatetimeOrderFrom);
    },
  );

  return sortedTypeFilteredAssignmentList;
};

export const sortAssignmentRequestList = (data, sortedAsc) => {
  if (!isEmpty(data)) {
    const sortedAssignmentRequestList = data.sort((a, b) => {
      if (sortedAsc) {
        return dayJS(b.DatetimeOrderFrom).diff(a.DatetimeOrderFrom);
      }
      return dayJS(a.DatetimeOrderFrom).diff(b.DatetimeOrderFrom);
    });

    return sortedAssignmentRequestList;
  }
  return [];
};

export const assignmentListReducer = (state, [type, payload]) => {
  switch (type) {
    case 'setSearchDateRange':
      return {
        ...state,
        searchDateRange: payload,
      };
    case 'setVisibleWorkAssignment':
      return {
        ...state,
        visibleWorkAssignment: payload,
      };
    case 'setVisibleAssignmentRequestList':
      return {
        ...state,
        visibleAssignmentRequestList: payload,
      };
    case 'setTypeFilter':
      return {
        ...state,
        typeFilterValue: payload,
      };
    case 'setStatusFilter':
      return {
        ...state,
        selectedFilter: payload,
      };
    case 'setSearchTerm':
      return {
        ...state,
        searchTerm: payload,
      };
    case 'setIsFiltering':
      return {
        ...state,
        isFiltering: payload,
      };
    case 'setClearFilter':
      return {
        ...state,
        searchDateRange: assignmentListInitialState.searchDateRange,
        typeFilterValue: assignmentListInitialState.typeFilterValue,
        selectedFilter: assignmentListInitialState.selectedFilter,
      };
    case 'sortDateTime':
      return {
        ...state,
        dateTimeSorted: payload,
      };
    case 'sortDateTimeAssignmentRequests':
      return {
        ...state,
        dateTimeSortedAssignmentRequests: payload,
      };
    default:
      return state;
  }
};
