/* eslint-disable*/
import React from 'react';
import PropTypes from 'prop-types';
import { Translate } from 'components';
import './style.scss';

const ContentPanel = props => (
  <div className="tv-general-condition single">{props.children}</div>
);

const ContentPageWrap = props => (
  <div className="tv-general-condition__page-wrap">{props.children}</div>
);

const ContentPage = props => (
  <div className="tv-general-condition__page">{props.children}</div>
);

const ContentTitle = props => (
  <h4 className="tv-general-condition__heading1">{props.children}</h4>
);

const ContentHeading = props => (
  <p className="tv-general-condition__heading2">{props.children}</p>
);

const ContentParagraph = props => (
  <p className="tv-general-condition__paragraph">{props.children}</p>
);

const ContentSeperatePara = props => (
  <div className="tv-general-condition__separate-paragraph">
    {props.children}
  </div>
);

const ContentSection = props => (
  <div className="tab-panel-section">{props.children}</div>
);

const ContentSubSection = props => (
  <div className="tab-panel-section__sub">{props.children}</div>
);

function GeneralCondition(props) {
  return (
    <div className="tv-general-condition">
      <div className="tv-general-condition__title">
        {Translate({ content: 'settings.generalCondition' })}
      </div>
      <ContentPanel>
        <ContentPageWrap>
          <ContentPage>
            <ContentSection>
              <ContentHeading>1. Inledning</ContentHeading>
              <ContentSeperatePara>
                Dessa allmänna villkor gäller för tolkar som utför tolkuppdrag
                för Transvoice Sweden AB, org. nr: 556653- 6370 samt Transvoice
                AB, org.nr. 556482-8654, Lindhagensgatan 126, 112 51 Stockholm.
              </ContentSeperatePara>
              <ContentSeperatePara>
                Transvoice-bolagen benämns härefter gemensamt "Transvoice".
                Dessa allmänna villkor reglerar vad som ska gälla mellan
                parterna vid utförande av tolkuppdrag för Transvoice.
              </ContentSeperatePara>
            </ContentSection>

            <ContentSection>
              <ContentHeading> 2. Tolken som uppdragstagare</ContentHeading>
              <ContentSeperatePara>
                Tolkuppdraget faller inte inom ramen för anställning, dock
                betalar Transvoice de sociala avgifter som gäller för
                uppdragstagaren med A-skattsedel. Sjuk-och friskanmälan till
                Försäkringskassan ansvarar tolken själv för. Antal tolkuppdrag
                som kommer in till Transvoice per dag beror på den efterfrågan
                som för tillfället finns på marknaden och Transvoice kan därför
                inte förbinda sig att ge några tolkuppdrag till tolken. Tolken
                arbetar därmed på frilansbasis med ersättning per timme.
              </ContentSeperatePara>
              <ContentSeperatePara>
                Utbetalning av arvode sker efter att tolken rapporterat sina
                utförda uppdrag till Transvoice. I det fall tolken är godkänd
                för F-skatt utbetalas lön efter att tolken fakturerat Transvoice
                för utförda tolktjänster. Tolken ska alltid lämna in
                registerutdrag för innevarande år till Transvoice.
              </ContentSeperatePara>
              <ContentSeperatePara>
                Som tolk får man ej finnas upptagen i polisens
                belastningsregister. Transvoice förbehåller sig rätten att göra
                kontroller mot Sveriges domstolar för alla aktiva
                uppdragstagare. Detta görs en gång per år via tjänsten
                Bakgrundskollen.se. Vid accept av Transvoice allmänna villkor så
                accepteras också att denna kontroll görs en gång per år.
              </ContentSeperatePara>
            </ContentSection>

            <ContentSection>
              <ContentHeading>
                3. Tolkens rättigheter och skyldigheter
              </ContentHeading>
              <ContentSeperatePara>
                Tolken har som individuell uppdragstagare stor frihet och
                valmöjlighet främst då det gäller planering och åtagande av
                arbete. Tolken är inte bunden till fasta tider eller ärenden,
                utan kan självständigt välja att åta sig eller avböja uppdrag.
                Tolken är skyldig att iaktta Transvoice allmänna villkor och
                riktlinjer inklusive arbetsinstruktion och utföra varje tolkning
                i enlighet med dessa.
              </ContentSeperatePara>
              <ContentSeperatePara>
                Tolken <b>ska</b> rapportera uppdrag i enlighet med bilaga 1.
              </ContentSeperatePara>
              <ContentSeperatePara>
                Tolken bör inneha en ansvarsförsäkring som är avsedd för
                utförande av tolkuppdrag.
              </ContentSeperatePara>
              <ContentSeperatePara>
                Tolken <b>ska</b> utföra sitt uppdrag i enlighet med
                Kammarkollegiets föreskrift God tolksed. Med God tolksed menas
                det regelverk som finns på området, den yrkeskodex som
                utvecklats av tolkar och den praxis som skapats av
                Kammarkollegiet på tolkområdet.
              </ContentSeperatePara>
            </ContentSection>

            <ContentSection>
              <ContentHeading>4. Tilldelning av tolkuppdrag</ContentHeading>
              <ContentParagraph>
                Transvoice har en tydlig policy att alltid tillsätta uppdraget
                med den högsta tillgängliga kompetensen för sammanhanget i
                kombination med anmäld tillgänglighet i Transvoice app/portal
                Tolka!. Det innebär att auktoriserade tolkar med speciell
                kompetens prioriteras först och därefter auktoriserade tolkar.
                Därefter förmedlas jobben till utbildade tolkar enligt
                Kammarkollegiets definition och till sist övriga tolkar, det
                vill säga tolkar som har genomgått minst en
                introduktionsutbildning, har erfarenhet av yrket och sedan är
                testade och godkända i Transvoice rekryteringstest.
              </ContentParagraph>
              <ContentSeperatePara>
                Utöver detta prioriteras tolkar även baserat på kvalitet på
                utförande och acceptansgrad, det vill säga utförda uppdrag utan
                anmärkningar och hur många förfrågningar man accepterat under
                anmäld tillgänglighet, samt enligt närhetsprincipen vid
                kontakttolkningsuppdrag.
              </ContentSeperatePara>
              <ContentSeperatePara>
                Tolkuppdragen förmedlas av Transvoice förmedlingssystem WorkBit
                efter gällande tillsättningsprinciper. När en tolk accepterat
                ett uppdrag är det tolkens skyldighet att genomföra åtagandet.
              </ContentSeperatePara>
            </ContentSection>

            <ContentSection>
              <ContentHeading>
                5. Princip vid åtagande av uppdrag
              </ContentHeading>
              <ContentSeperatePara>
                När uppdragstagaren accepterat ett åtagande är han/hon skyldig
                att utföra tolkningen. Innan tolken åtar sig och fullföljer ett
                uppdrag, <b>ska</b> denne noga överväga om han/hon är lämplig
                för ärendet och därmed kan utföra tolkningen på ett
                tillfredställande sätt. Om tolken anser sig ej vara kompetent
                för uppdragets innehåll eller av annan anledning olämplig,{' '}
                <b>ska</b> han/hon inte åta sig uppdraget.
              </ContentSeperatePara>
              <ContentSeperatePara>
                Tolken <b>ska</b> endast åta sig uppdrag som han/hon finner sig
                lämpad för.
              </ContentSeperatePara>
            </ContentSection>

            <ContentSection>
              <ContentHeading>
                6. När betraktas åtagandet vara accepterat?
              </ContentHeading>
              <ContentSeperatePara>
                När en tolk accepterat en uppdragsförfrågan via Transvoice
                app/portal Tolka! är tolken skyldig att genomföra åtagandet.
              </ContentSeperatePara>
              <ContentSeperatePara>
                Uppdragsförfrågningar som inkommer via telefonsamtal från
                förmedlingen anses som accepterade när tolken har tackat ja,
                samt tilldelats uppdragsuppgifter och uppdragsnummer. Om/när
                uppdragsuppgifter inte distribueras via app/portal utan via
                e-post är detta en bekräftelse på tidigare muntlig
                överenskommelse där tolk accepterat uppdrag på förhand. Tolk och
                förmedlingen har då kommit överens om via telefon eller mejl om
                vilka dagar och tider tolken är tillgänglig för uppdrag.
              </ContentSeperatePara>
            </ContentSection>

            <ContentSection>
              <ContentHeading>7. Om kunden avbokar</ContentHeading>
              <ContentParagraph>
                Om Transvoice kund avbokar redan inplanerade uppdrag kvarstår
                tolkens rätt till arvode för uppdraget beroende på antal timmar
                kvar till uppdragets starttid vid avbokningstillfället. Olika
                kundavtal har olika regler för dessa avbokningstider för
                kontakttolkning respektive distanstolkning. Dessa
                avtalsbestämmelser för avbokningstider finns att läsa i
                arvodeslistan, som återfinns i Transvoice app och portal.
              </ContentParagraph>
            </ContentSection>

            <ContentSection>
              <ContentHeading>8. Jäv och tystnadsplikt</ContentHeading>
              <ContentParagraph>
                Tolken <b>ska</b> vara opartisk och inte ge uttryck för sina
                åsikter och värderingar. Tolken är en oberoende part och får
                inte åta sig uppdrag där tolkens opartiskhet kan ifrågasättas.
              </ContentParagraph>
              <ContentParagraph>
                Tolkens uppdrag är förenat med tystnadsplikt i enlighet med
                bland annat offentlighets- och sekretesslagen (2009:400) då
                tolken vid utförandet av sina uppdrag kan komma att ta del av
                sekretessbelagda uppgifter. Att röja information kring ett
                uppdrag eller samtalets innehåll är ett allvarligt brott enligt
                svensk lagstiftning.
              </ContentParagraph>
            </ContentSection>

            <ContentSection>
              <ContentHeading>9. Vite</ContentHeading>
              <ContentParagraph>
                Om tolken uteblir eller är försenad utgör detta en avvikelse i
                form av en störning i tjänsten och om kunden ej nyttjar tolkens
                service, betalas varken ersättning för uppdraget eller
                resekostnader. Vid eventuell försening måste Transvoice
                omedelbart meddelas om förseningen. Vid kontakt med förmedlingen
                kan Transvoice i samråd med kund besluta vad som ska göras.
              </ContentParagraph>
              <ContentParagraph>
                Med avvikelse menas bland annat att kunden har upplevt
                störningar i verksamheten i form av kvalitetsbrist i tolkens
                utförande av uppdraget, bristande service vid utfört tolkuppdrag
                eller att tolken har uteblivit eller blivit försenad till ett
                uppdrag. En avvikelse kan medföra att tolken får betala ett vite
                till Transvoice.
              </ContentParagraph>
              <ContentParagraph>
                Med vitesersättning menas den ersättning som Transvoice kan
                komma att få betala till kund för avvikelse vid utfört
                tolkuppdrag. Om tolken uteblivit eller kommit försent till ett
                uppdrag kan tolken i vissa fall befrias från skyldigheten att
                erlägga vitesersättning i det fall tolken, genom att uppvisa
                läkarintyg eller intyg om väsentlig störning i lokaltrafik, kan
                visa att avvikelsen är föranledd av omständigheter utanför
                tolkens kontroll.
              </ContentParagraph>
              <ContentParagraph>
                Vitesersättningen som tolken kan komma att få betala dras av vid
                påföljande månads arvodesutbetalning eller påförs tolken genom
                faktura. Transvoice äger dock rätt att utöver vite kräva
                ersättning för den faktiska skadan.
              </ContentParagraph>
            </ContentSection>

            <ContentSection>
              <ContentHeading>10. Överlåtelse av avtalet</ContentHeading>
              <ContentParagraph>
                Tolken får under inga omständigheter överlåta sitt tolkuppdrag
                till någon annan tolk utan att inhämta Tranvoice skriftliga
                godkännande i förväg. För det fall tolken överlåter sitt
                tolkuppdrag utan att inhämta Tranvoice godkännande i förväg äger
                Transvoice rätt att utkräva vite i enlighet med punkten 8 ovan
                med ett belopp som motsvarar tolkersättningen för uppdraget och
                samarbetet kan helt komma att avslutas.
              </ContentParagraph>
            </ContentSection>

            <ContentSection>
              <ContentHeading>11. Avtalsdokument</ContentHeading>
              <ContentSeperatePara>
                Detta avtal består av följande handlingar och de ska tolkas i
                den
              </ContentSeperatePara>
              <ContentSeperatePara>
                ordning de räknas upp nedan:
                <ul>
                  <li>Allmänna villkor och riktlinjer</li>
                  <li>Arbetsinstruktion – Bilaga 1</li>
                  <li>Användarvillkor för Transvoice app och portal Tolka!</li>
                </ul>
              </ContentSeperatePara>
            </ContentSection>
            <ContentHeading>12. Tvist</ContentHeading>

            <ContentSection>
              <ContentSeperatePara>
                Tvist mellan parterna med anledning av detta avtal ska lösas av
                allmän domstol med tillämpning av svensk lag.
              </ContentSeperatePara>
            </ContentSection>
          </ContentPage>
          <ContentPage>
            <ContentSection>
              <ContentParagraph>
                Bilaga 1<br />
                Arbetsinstruktion
              </ContentParagraph>
            </ContentSection>
            <ContentSection>
              <ContentHeading>1. Allmänt om branschen</ContentHeading>
              <ContentParagraph>
                Tolkbranschen har en mycket viktig funktion i det svenska
                samhället. Sverige är ett invandrartätt land, och har därtill
                ett stort antal internationella besökare i turistande och
                arbetsrelaterade ärenden. För att viktiga samhällsfunktioner,
                myndigheter och företag ska fungera väl i sin verksamhet krävs
                många gånger tolkservice och hjälp med språkförståelsen. Varje
                yrkesverksam tolk bidrar bland annat till det svenska samhällets
                funktionalitet, kommunikationsförmåga och tillväxt.
              </ContentParagraph>
              <ContentParagraph>
                Tolkar och medarbetare på Transvoice har ett stort ansvar och
                ska tillsammans svara på den efterfrågan som finns, och
                samtidigt arbeta för expansion och utveckling. Vi ska
                möjliggöra, upprätthålla och stärka kommunikationen mellan
                beställare och klient. Våra tjänster ska frambringa
                tillit/trygghet både för den enskilde individen och för
                samhället i stort. En stor mängd företag, organisationer, och
                privatpersoner som bor eller är på besök i Sverige, har ett
                behov av tolkning. Genom att ta vårt ansvar driver vi branschen
                framåt och verkar för en säker och positiv framtid.
              </ContentParagraph>
            </ContentSection>
            <ContentSection>
              <ContentHeading>
                2. Förpliktelser vid uppdragstagande
              </ContentHeading>
              <ContentSubSection>
                <ContentSection>
                  <ContentHeading>2.1 Vardagliga rutiner</ContentHeading>
                  <ContentParagraph>
                    <ul>
                      <li>
                        Det åligger dig att se till att du har alla uppgifter om
                        tolkuppdraget
                      </li>
                      <li>
                        Adressen <b>ska</b> kollas upp i förväg så att du hittar
                        till kunden utan problem eller försening
                      </li>
                      <li>
                        Du kommer i god tid till varje uppdrag, gärna 10 minuter
                        före
                      </li>
                      <li>
                        Vid uppdragets start ger du följande upplysningar till
                        involverade parter på både mål- och källspråk:
                      </li>
                      <i>
                        <b>
                          "Jag följer reglerna om tystnadsplikt. Jag är neutral
                          och tar inte parti för någon i tolkningssituationen.
                          Jag kommer att återge vad som sagts i första person
                          (jag-form)".
                        </b>
                      </i>
                      <li>
                        Hela tolkningen <b>ska</b> präglas av professionalitet,
                        från bemötandet till utförandet.
                      </li>
                      <li>
                        Uppdrag <b>ska</b> rapporteras direkt efter avslut.
                      </li>
                      <li>
                        Kontakttolkning <b>ska</b> alltid signeras av kund i
                        Transvoice app Tolka!
                      </li>
                      <li>
                        Vid eventuella problem med uppdrag <b>ska</b> Transvoice
                        kontaktas så att kunden kan underrättas.
                      </li>
                      <li>
                        Distanstolkning <b>ska</b> utföras från ett ostört rum
                        där inga störningsmoment föreligger som kan påverka
                        tolkningen negativt. Du <b>ska</b> vara ensam i rummet
                        när du utför tolkningen. Att distanstolka i en offentlig
                        miljö är <b>strikt förbjudet</b> och kan leda till att
                        samarbetet genast avbryts.
                      </li>
                      <li>
                        Det är tolkens ansvar att själv sörja för god ljudmiljö
                        och väl fungerande teknisk utrustning vid
                        distanstolkning.
                      </li>
                      <li>
                        Distanstolkning <b>ska</b> endast utföras från Sverige
                        eller i land inom EU/EES, ej från annat land.
                      </li>
                      <li>
                        Du måste stanna under hela den bokade tiden om kunden så
                        önskar.
                      </li>
                    </ul>
                  </ContentParagraph>
                </ContentSection>
                <ContentSection>
                  <ContentHeading>2.2 Yrkesbevis</ContentHeading>
                  <ContentParagraph>
                    Transvoice tolklegitimation eller Kammarkollegiets
                    behörighetskort <b>ska</b> hållas väl synlig under hela
                    vistelsen hos kunden. Denna identifikation är ett
                    yrkesbevis, visar leverans för aktuell leverantör, samt är
                    kundens säkerhet att en utbildad och godkänd tolk utför
                    deras tjänster.
                  </ContentParagraph>
                  <ContentParagraph>
                    För uppdaterat yrkesbevis, såsom vid förändring av
                    kompetensnivå, ska Transvoice kontaktas för ny legitimation.
                  </ContentParagraph>
                </ContentSection>
                <ContentSection>
                  <ContentHeading>2.3 Planering</ContentHeading>
                  <ContentParagraph>
                    När du tar täta tolkuppdrag måste du vara väl förberedd. Det
                    är alltid ditt ansvar att du hinner till nästa uppdrag. Om
                    du inte kan planera optimalt så att du kan närvara på utsatt
                    tid, <b>ska</b> du inte åta dig ärendet. Du får inte pressa
                    kunden på att få gå tidigare på grund av efterföljande
                    uppdrag. Det är endast på kundens eget initiativ som ett
                    uppdrag kan avslutas före utsatt tid. Vi <b>ska</b> alltid
                    acceptera den bokade tiden.
                  </ContentParagraph>
                </ContentSection>
                <ContentSection>
                  <ContentHeading>
                    2.4 Tolkningen sker i enlighet med god tolksed
                  </ContentHeading>
                  <ContentParagraph>
                    <ul>
                      <li>
                        Du har tystnadsplikt (skyldighet att iaktta sekretess)
                      </li>
                      <li>
                        Du <b>ska</b> vara neutral och inte ge uttryck för egna
                        åsikter och värderingar
                      </li>
                      <li>
                        Du <b>ska</b> vara opartisk och inte ta ställning för
                        någon av parterna
                      </li>
                      <li>
                        Du <b>ska</b> översätta allt som sägs i rummet, utan att
                        lägga till, dra ifrån eller förändra
                      </li>
                      <li>Du återger vad som sägs i första person/jag-form</li>
                      <li>
                        Du <b>ska</b> undvika jäv-situation
                      </li>
                    </ul>
                  </ContentParagraph>
                </ContentSection>
                <ContentSection>
                  <ContentHeading>2.5 Professionell framtoning</ContentHeading>
                  <ContentParagraph>
                    Varje uppdragstagare åtar sig att vara representativt klädd.
                    Med detta menas att du har diskret klädsel och därmed inte
                    bär utmanande eller färgstarka plagg. Det diskreta intrycket
                    inkluderar även att inte lukta rök/snus, alkohol eller bära
                    tunga parfymer.
                  </ContentParagraph>
                </ContentSection>
              </ContentSubSection>
              <ContentSection>
                <ContentHeading>3.Samarbete tolk och förmedling</ContentHeading>
                <ContentSeperatePara>
                  Varje förmedlare på Transvoice har ansvar för olika språk och
                  områden. Därmed finns goda förutsättningar för ett gott och
                  nära samarbete mellan uppdragstagare och förmedling.
                </ContentSeperatePara>
                <ContentSeperatePara>
                  Uppdragstagarens lojalitet, flexibilitet och tillgänglighet
                  utgör faktorer för att ett långsiktigt och hållbart samarbete
                  ska ta form. Transvoice finns till hands via tolklinjen för
                  att uppdragstagaren lätt ska kunna få tag på förmedlingen vid
                  såväl planering och inbokning av uppdrag som vid akuta
                  situationer.
                </ContentSeperatePara>
              </ContentSection>
              <ContentSection>
                <ContentHeading>
                  4. Rapportering av uppdrag – Utbetalning och fakturering av
                  arvode
                </ContentHeading>
                <ContentSubSection>
                  <ContentSection>
                    <ContentHeading>
                      4.1 Varför ska uppdragen rapporteras?
                    </ContentHeading>
                    <ContentSeperatePara>
                      En uppdragsrapport är en redogörelse för utfört arbete.
                    </ContentSeperatePara>
                    <ContentSeperatePara>
                      Rapporten utgör underlag för utbetalning av arvode för
                      dina tjänster. Varje rapporterat uppdrag är unikt för
                      varje enskilt ärende du utför och din rapport hänvisar
                      alltid till ett aktuellt bokningsnummer.
                    </ContentSeperatePara>
                    <ContentSeperatePara>
                      Genom att fylla i en rapport har både du själv, aktuell
                      kund, och Transvoice en god kontroll på genomfört uppdrag.
                    </ContentSeperatePara>
                    <ContentSeperatePara>
                      Samtliga parter i denna relation behöver en kvittens på
                      utfört uppdrag för att faktureringen och arvoderingen ska
                      bli korrekt.
                    </ContentSeperatePara>
                    <ContentSeperatePara>
                      Fyll i rapporten direkt efter utfört uppdrag. All
                      kontakttolkning ska rapporteras i Transvoice app Tolka!
                      och kund ska alltid signera uppdraget digitalt i appen.
                    </ContentSeperatePara>
                    <ContentSeperatePara>
                      Distanstolkning kan rapporteras i app eller portal utan
                      signatur. Det är mycket viktigt att rapporten är korrekt
                      ifylld, en felaktig rapport kan leda till att du inte får
                      ut någon ersättning för uppdraget.
                    </ContentSeperatePara>
                  </ContentSection>
                  <ContentSection>
                    <ContentHeading>
                      4.2 Hur fyller jag i rapporten?
                    </ContentHeading>
                    <ContentSeperatePara>
                      Vid rapportering av uppdrag ska faktisk tolktid, starttid
                      och sluttid, fyllas i. Sluttiden stäms alltid av med
                      tolkanvändaren innan samtalets avslut.
                    </ContentSeperatePara>
                    <ContentSeperatePara>
                      Vid kontaktuppdrag där du får ersättning för resa ska
                      avstånd i kilometer samt restid till och från uppdraget
                      anges. För eventuella utlägg måste kvitto bifogas.
                    </ContentSeperatePara>
                    <ContentSeperatePara>
                      Vid rapportering av uppdrag så ges även möjlighet att
                      lämna ett betyg på uppdraget. Gör gärna det tillsammans
                      med en kommentar.
                    </ContentSeperatePara>
                  </ContentSection>
                  <ContentSection>
                    <ContentHeading>
                      4.3 När och var ska ett uppdrag rapporteras? När får jag
                      min utbetalning av arvode?
                    </ContentHeading>
                    <ContentSeperatePara>
                      Uppdrag som rapporteras via app eller portal innan den
                      sista varje månad och betalas ut den 25:e nästkommande
                      månad.
                    </ContentSeperatePara>
                    <ContentSeperatePara>
                      Vid helg sker utbetalning föregående vardag.
                    </ContentSeperatePara>
                    <ContentSeperatePara>
                      Kontakttolkningsuppdrag kvitteras i app genom att kund
                      signerar på skärmen. Rapporteras kontakttolkningsuppdraget
                      utan kundens påskrift kan det medföra att arvodet inte
                      betalas ut. Ett bevis på utfört uppdrag måste då
                      presenteras för Transvoice.
                    </ContentSeperatePara>
                    <ContentSeperatePara>
                      Alla uppdrag ska alltid rapporteras direkt efter avslut.
                    </ContentSeperatePara>
                    <ContentSeperatePara>
                      Rapporteras uppdrag senare än trettio (30) dagar efter
                      utfört uppdragsdatum utgår ingen ersättning för uppdraget.
                    </ContentSeperatePara>
                    <ContentSeperatePara>
                      Transvoice förbehåller sig i sådant fall ändå rätten att
                      debitera utfört uppdrag till kund.
                    </ContentSeperatePara>
                  </ContentSection>
                  <ContentSection>
                    <ContentHeading>4.4 Arvodesspecifikation</ContentHeading>
                    <ContentParagraph>
                      Alla arvodesspecifikationer distribueras digitalt via
                      tolkens inlogg i app och portal.
                    </ContentParagraph>
                  </ContentSection>
                </ContentSubSection>
              </ContentSection>
              <ContentSection>
                <ContentHeading>5. Avvikelserapport</ContentHeading>
                <ContentParagraph>
                  Varje avvikelserapport registreras och granskas av Transvoice.
                  Det eventuella felet identifieras och undersökning görs
                  gällande varför avvikelsen inträffat. Om rapporteringen är
                  direkt knuten till tjänsteleveransen, kontaktas aktuell
                  uppdragstagare av Transvoice för klargörande och
                  förbättringsåtgärder. I annat fall löses detta internt hos
                  Transvoice. Åtgärder vidtas för konstruktiva syften, så att
                  dylik avvikelse inte upprepas. Detta kan medföra förnyelse av
                  rutiner, vilket leder till kvalitetsförbättring. När
                  granskningen är gjord återkopplar Transvoice avseende
                  rapporteringen till kunden. Transvoice dokumenterar ärendet
                  och analyserar resultatet internt för att få en god överblick
                  över sin verksamhet.
                </ContentParagraph>
              </ContentSection>
              <ContentSection>
                <ContentHeading>
                  6. Villkor för fakturor (tolkar godkända för f-skattesedel)
                </ContentHeading>
                <ContentParagraph>
                  För att fakturera Transvoice för dina utförda uppdrag krävs
                  att du har eget företag. Du lämnar då in registerutdrag som du
                  får från Skatteverket. Lämna in nytt registerutdrag
                  tillsammans med första fakturan varje år.
                </ContentParagraph>
                <ContentParagraph>
                  I början på varje månad laddas ditt underlag upp i Transvoice
                  app och portal under ”Ersättning”. Där laddas även ett förslag
                  på faktura upp med de uppgifter som vi har fått.
                </ContentParagraph>
                <ContentParagraph>
                  Vill du använda fakturaförslaget ska alla uppgifter
                  kontrolleras. Uppge fakturanummer så att det stämmer med din
                  egen fakturaserie och signera underlaget. Fakturan ska sedan
                  inkomma till oss via e-post eller post.
                </ContentParagraph>
              </ContentSection>
            </ContentSection>
          </ContentPage>
        </ContentPageWrap>
      </ContentPanel>
    </div>
  );
}

GeneralCondition.propTypes = {};

export default GeneralCondition;
