export const TRANSVOICE_LOGIN = 'TRANSVOICE_LOGIN';
export const AUTHENTICATE_RESOURCE_SUCCESS = 'AUTHENTICATE_RESOURCE_SUCCESS';
export const AUTHENTICATE_RESOURCE = 'AUTHENTICATE_RESOURCE';
export const AUTHENTICATE_RESOURCE_USING_ID = 'AUTHENTICATE_RESOURCE_USING_ID';
export const AUTHENTICATE_RESOURCE__USING_ID_SUCCESS =
  'AUTHENTICATE_RESOURCE__USING_ID_SUCCESS';

export const TRANSVOICE_LOGIN_REQUEST = 'TRANSVOICE_LOGIN_REQUEST';
export const TRANSVOICE_LOGIN_SUCCESS = 'TRANSVOICE_LOGIN_SUCCESS';
export const TRANSVOICE_LOGIN_FAILURE = 'TRANSVOICE_LOGIN_FAILURE';
export const TRANSVOICE_LOGOUT = 'TRANSVOICE_LOGOUT';

export const transvoiceResourceAuthenticate = ({
  email,
  passwordHash,
  isRefresh,
}) => ({
  type: AUTHENTICATE_RESOURCE,
  payload: {
    email,
    passwordHash,
    isRefresh: isRefresh === null ? false : isRefresh,
  },
  meta: {
    thunk: 'resourceUserAuthenticate',
  },
});

export const transvoiceResourceAuthenticateSuccess = (
  data,
  request,
  thunk,
) => ({
  type: AUTHENTICATE_RESOURCE_SUCCESS,
  payload: { ...data, ...request },
  meta: {
    request,
    thunk,
  },
});

export const transvoiceResourceAuthenticateUsingId = ({
  email,
  interpreterId,
}) => ({
  type: AUTHENTICATE_RESOURCE_USING_ID,
  payload: {
    email,
    interpreterId,
  },
  meta: {
    thunk: 'resourceUserAuthenticateUsingId',
  },
});

export const transvoiceLoginRequest = (
  interpreterId,
  resourceIdentifier,
  timeStamp,
) => ({
  type: TRANSVOICE_LOGIN_REQUEST,
  payload: {
    interpreterId,
    resourceIdentifier,
    timeStamp,
  },
  meta: {
    thunk: 'loginRequest',
  },
});

export const transvoiceLoginSuccess = (data, request, thunk) => ({
  type: TRANSVOICE_LOGIN_SUCCESS,
  payload: data,
  meta: {
    thunk,
    request,
  },
});

export const transvoiceLoginFailure = (error, request, thunk) => ({
  type: TRANSVOICE_LOGIN_FAILURE,
  error: true,
  payload: error,
  meta: {
    request,
    thunk,
  },
});

export const transvoiceLogout = () => ({ type: TRANSVOICE_LOGOUT });
