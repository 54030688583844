import React from 'react';
import PropTypes from 'prop-types';
import { TabButton, Dropdown } from 'components';
import './style.scss';

const TabList = ({
  tabs,
  onTabClick,
  className,
  activeTab,
  style,
  hideTabInMobileview,
  children,
}) => (
  <div className={`tv-tab_list__container ${className}`} style={style}>
    {/* Tabs */}
    <div className="tv-tab_list__container-tabs">
      {/* Mobile Tabs */}
      <Dropdown
        className="tv-tab_list__container-tabs-mobile"
        value={activeTab}
        options={tabs}
        onChange={tab => {
          onTabClick({ label: tab.label, name: tab.value });
        }}
      />

      {/* Desktop Tabs */}
      <div className="tv-tab_list__container-tabs-desktop">
        {tabs.map(tab => (
          <TabButton
            key={`${tab.name}-tabs`}
            label={tab.label}
            className={`tv-tab_list-tab ${
              hideTabInMobileview && hideTabInMobileview.name === tab.name
                ? 'tv-mobile--hide'
                : ''
            }`}
            disabledClassName="tv-tab_list-tab-disabled"
            disabled={activeTab !== tab.name}
            onClick={() => {
              onTabClick(tab);
            }}
            displayBorder={tab.displayBorderBottom}
          />
        ))}
      </div>
    </div>
    {/* Tab Contents */}
    <div className="tv-tab_list__container-tabs_contents">{children}</div>
  </div>
);

TabList.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.shape({})),
  activeTab: PropTypes.string,
  onTabClick: PropTypes.func,
  style: PropTypes.shape({}),
  hideTabInMobileview: PropTypes.shape({
    name: PropTypes.string,
  }),
  children: PropTypes.node,
  className: PropTypes.string,
};

TabList.defaultProps = {
  tabs: [],
  activeTab: '',
  onTabClick: () => {},
  hideTabInMobileview: null,
  children: '',
  style: {},
  className: '',
};

export default TabList;
