/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Compensation, Translate, Spinner } from 'components';
import { fulfilled, rejected, pending } from 'redux-saga-thunk';
import { fromResource } from 'store/selectors';
import {
  compareObjectBasedOnDate,
  convertToDayJsObject,
  localDayjs,
} from 'utils/dateTimeUtils';
import { resourceListReadRequest } from 'store/actions';
import { useGetFile } from 'hooks';

function groupCompensationListBasedOnMonth(list) {
  const groupedList = {};
  // list sort based on the date
  list
    .sort((a, b) =>
      compareObjectBasedOnDate(a, b, 'FileCreatedDateTime', 'desc'),
    )
    .forEach(item => {
      const month = convertToDayJsObject(item.FileCreatedDateTime).format(
        'MMMM',
      );
      if (!groupedList[month]) {
        groupedList[month] = [];
      }
      groupedList[month].push(item);
    });
  return groupedList;
}
function CompensationContainer({
  compensationList,
  onSearchResourceFiles,
  isPending,
}) {
  const [year, setYear] = useState(localDayjs().endOf('year'));
  const [compensationGroupList, setCompensationGroupList] = useState([]);
  const { GetFile } = useGetFile();
  useEffect(() => {
    if (compensationList) {
      const groupedList = groupCompensationListBasedOnMonth(compensationList);
      setCompensationGroupList(groupedList);
    }
  }, [compensationList]);

  useEffect(() => {
    const params = {
      DatetimeFrom: year.subtract(1, 'year').toDate(),
      DatetimeTo: year.toDate(),
    };
    onSearchResourceFiles(params);
  }, [year]);

  const handleCalendarTravel = (direction = 'forward') => {
    if (direction === 'forward') {
      setYear(year.add(1, 'year'));
    } else {
      setYear(year.subtract(1, 'year'));
    }
  };

  return (
    <>
      <Compensation
        list={compensationGroupList}
        navigatorText={year.format('YYYY')}
        onDownloadClick={GetFile}
        handleCalendarTravel={handleCalendarTravel}
      />
      {isPending && <Spinner />}
    </>
  );
}

CompensationContainer.propTypes = {
  compensationList: PropTypes.arrayOf(PropTypes.shape({})),
  onSearchResourceFiles: PropTypes.func,
  isPending: PropTypes.bool,
};
CompensationContainer.defaultProps = {
  compensationList: [],
  isPending: false,
  onSearchResourceFiles: () => {},
};

const mapDispatchToProps = dispatch => ({
  onSearchResourceFiles: params => {
    dispatch(
      resourceListReadRequest(
        'compensationSearchResources',
        'Files',
        params,
        true,
      ),
    );
  },
});

const mapStateToProps = state => {
  const compensationList = fromResource.getList(
    state,
    'compensationSearchResources',
  );
  const filtered =
    compensationList && compensationList.length
      ? compensationList.filter(
          x =>
            x.FileType === 'Lönespecifikation' ||
            x.FileType === 'Självfaktura' ||
            x.FileType === 'Uppdragsspecifikation',
        )
      : [];

  return {
    isPending: pending(state, 'compensationSearchResourcesListRead'),
    isfulfilled: fulfilled(state, 'compensationSearchResourcesListRead'),
    compensationList: filtered,
    isfailed: rejected(state, 'compensationSearchResourcesListRead'),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CompensationContainer);
