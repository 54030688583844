/* eslint-disable*/
import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Button, Modal, Translate } from 'components';
import './style.scss';
import { useHistory } from 'react-router-dom';

function WelcomeModal({
  isVisible,
  onConfirm,
  onClose,
  checked,
  onRedirect,
  onCheck,
  scheduleMessage,
  customerName,
}) {
  const history = useHistory();

  return (
    <Modal
      isVisible={isVisible}
      className="tv-welcome-modal__container"
      onClose={onClose}
      hideHeader
    >
      <div className="tv-welcome-modal__content">
        <h2 className="tv-welcome-modal__welcome-message">
          {Translate({ content: 'welcome.message' })} {customerName}! 👋🏻
        </h2>
        <p className="tv-welcome-modal__text">{scheduleMessage}</p>
        <Checkbox
          checked={checked}
          onClick={onCheck}
          className="tv-welcome-modal__checkbox"
          text="Visa inte detta igen."
        />
        <div className="tv-welcome-modal__actions">
          <Button
            label={Translate({ content: 'welcome.scheduleAvailability' })}
            className="tv-assignment-filter-modal__actions-button"
            palette="outline"
            onClick={onRedirect}
          />
          <Button
            label={Translate({ content: 'welcome.clear' })}
            className="tv-assignment-filter-modal__actions-button"
            onClick={onConfirm}
          />
        </div>
      </div>
    </Modal>
  );
}

WelcomeModal.propTypes = {
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  checked: PropTypes.bool,
  onCheck: PropTypes.func,
  onRedirect: PropTypes.func,
  scheduleMessage: PropTypes.string,
  customerName: PropTypes.string,
};
WelcomeModal.defaultProps = {
  isVisible: false,
  onClose: () => {},
  onConfirm: () => {},
  checked: false,
  onCheck: () => {},
  onRedirect: () => {},
  scheduleMessage: '',
  customerName: '',
};

export default WelcomeModal;
