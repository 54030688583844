import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import isoWeek from 'dayjs/plugin/isoWeek';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isBetween from 'dayjs/plugin/isBetween';
import duration from 'dayjs/plugin/duration';

dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(isoWeek);
dayjs.extend(advancedFormat);
dayjs.extend(customParseFormat);
dayjs.extend(isBetween);
dayjs.extend(duration);

export const localDayjs = dayjs;

export const dayJsDuration = x => dayjs.duration(x);

export const extendedDayJs = date => dayjs(date).locale('sv');

export const currentDate = () => dayjs().locale('sv');

export const currentSWTime = () => dayjs().tz('Europe/Stockholm');

export const convertToDayJsObject = (
  dateTime,
  format = 'YYYY-MM-DD HH:mm:ss',
) => dayjs(dateTime, format);

export const convertToSWDayJsObject = (
  dateTime,
  format = 'YYYY-MM-DD HH:mm:ss',
) => dayjs(dateTime, format).tz('Europe/Stockholm', true);

export const getTokenExpireDay = (timeZoneBe, expiryNoOfDays, formtStr) =>
  dayjs().add(expiryNoOfDays, 'day').format(formtStr);

export const isDayWithinTheRange = (focusDate, rangeFromDate, rangeToDate) =>
  rangeFromDate <= focusDate && focusDate <= rangeToDate;

export const isSameDay = (focusDate, targetDate) =>
  dayjs(focusDate).isSame(targetDate, 'day');

export const get24HoursInday = () => {
  const hours = [];
  const hoursPerDay = 24;
  const currentDateStart = currentDate().startOf('day');
  for (let i = 0; i < hoursPerDay; i += 1) {
    const formattedTime = currentDateStart.add(i, 'hour').format('H:00');
    hours.push(formattedTime);
  }
  return hours;
};

export const calculateAssignmentSearchParameters = (
  searchRange,
  calendarRange,
) => {
  let param = { start: calendarRange.start, end: searchRange.start };
  let dateRange = { start: calendarRange.start, end: searchRange.end };
  let isRefresh = false;
  if (
    calendarRange.start.isBetween(searchRange.start, searchRange.end, 'day') &&
    calendarRange.end.isBetween(searchRange.start, searchRange.end, 'day')
  ) {
    param = searchRange;
    dateRange = searchRange;
  } else if (
    calendarRange.start.isBetween(searchRange.start, searchRange.end, 'day')
  ) {
    param = { start: searchRange.end, end: calendarRange.end };
    dateRange = { start: searchRange.start, end: calendarRange.end };
  } else if (searchRange.start.diff(calendarRange.end, 'month') >= 4) {
    param = calendarRange;
    dateRange = calendarRange;
    isRefresh = true;
  } else if (
    searchRange.start.isBetween(
      calendarRange.start,
      calendarRange.end,
      'day',
    ) &&
    searchRange.end.isBetween(calendarRange.start, calendarRange.end, 'day')
  ) {
    param = calendarRange;
    dateRange = calendarRange;
    isRefresh = true;
  } else if (calendarRange.start.diff(searchRange.end, 'month') >= 4) {
    param = calendarRange;
    dateRange = calendarRange;
    isRefresh = true;
  } else if (calendarRange.start.diff(searchRange.end, 'month') >= 0) {
    dateRange = { start: searchRange.start, end: calendarRange.end };
    param = { start: searchRange.end, end: calendarRange.end };
  }
  return {
    param,
    dateRange,
    isRefresh,
  };
};

const getRemainderAndQuotient = (divdend = 1, divsor = 1) => [
  divdend % divsor,
  parseInt(divdend / divsor, 10),
];

// export const parseHourMinuteField = inputText => {
//   let { minutes, hours } = {
//     minutes: 0,
//     hours: 0,
//   };
//   if (!inputText.includes(':')) {
//     [minutes, hours] = getRemainderAndQuotient(inputText, 60);
//   } else {
//     const [hourPart, minutePart] = inputText.split(':');
//     const [minutePartBasedMin, minutePartBasedHour] = getRemainderAndQuotient(
//       minutePart,
//       60,
//     );
//     const hourPartBasedHour = Number(hourPart); // getRemainderAndQuotient(hourPart, 12);
//     [minutes, hours] = [
//       minutePartBasedMin,
//       hourPartBasedHour + minutePartBasedHour,
//     ];
//   }

//   return {
//     minutes,
//     hours,
//   };
// };

export const parseHourMinuteField = inputText => {
  let { minutes, hours } = {
    minutes: 0,
    hours: 0,
  };
  let timeString = inputText;
  if (!inputText.includes(':')) {
    switch (timeString.length) {
      case 1:
        timeString = `0${timeString[0]}:00`;
        break;
      case 2:
        timeString = `00:${timeString.substring(0, 2)}`;
        break;
      case 3:
        timeString = `0${timeString}`;
        timeString = `${timeString.substring(0, 2)}:${
          timeString.length > 2 ? timeString.substring(2) : '00'
        }`;
        break;

      case 5:
        timeString = `${timeString.substring(0, 3)}:${timeString.substring(3)}`;
        break;
      default:
        timeString = `${timeString.substring(0, 2)}:${
          timeString.length > 2 ? timeString.substring(2) : '00'
        }`;
        break;
    }
  }
  const [hourPart, minutePart] = timeString.split(':');
  const [minutePartBasedMin, minutePartBasedHour] = getRemainderAndQuotient(
    minutePart,
    60,
  );
  const hourPartBasedHour = Number(hourPart); // getRemainderAndQuotient(hourPart, 12);
  [minutes, hours] = [
    minutePartBasedMin,
    hourPartBasedHour + minutePartBasedHour,
  ];
  return {
    minutes,
    hours: hours > 10 ? 10 : hours,
  };
};

export function compareObjectBasedOnDate(a, b, key, dir = 'asc') {
  const aDate = localDayjs(a[key]);
  const bDate = localDayjs(b[key]);
  if (dir === 'asc') {
    return aDate.isBefore(bDate) ? -1 : 1;
  }
  return bDate.isBefore(aDate) ? -1 : 1;
}
