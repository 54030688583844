/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';
import { Login } from 'containers';
import './style.scss';
import { NavigationLink } from 'components';

function AuthenticationPage(props) {
  return (
    <>
      <Login location={props.location} />
      <NavigationLink
        to="/forgot-password"
        className="tv-authentication-page__Link"
        text="Återställ lösenord"
      />
    </>
  );
}

AuthenticationPage.propTypes = {};

export default AuthenticationPage;
