/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { IconButton } from 'components';
import './style.scss';

const Modal = ({
  children,
  onOverlayClick,
  onClose,
  className,
  isVisible,
  hideHeader,
  ...rest
}) => {
  useEffect(() => {
    document.body.style.overflowY = 'hidden';
    document.body.style.paddingRight = '16px';
    return () => {
      document.body.style.overflowY = 'unset';
      document.body.style.paddingRight = 'unset';
    };
  }, []);
  return (
    <div
      className={`tv-modal__overlay ${
        isVisible ? 'tv-modal__overlay--open' : ''
      } ${className}`}
      onClick={onOverlayClick}
    >
      <div className="tv-modal__container">
        {!hideHeader && <ModalHeader onClose={onClose} {...rest} />}
        {children}
      </div>
    </div>
  );
};

Modal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  onOverlayClick: PropTypes.func,
  onClose: PropTypes.func,
  isVisible: PropTypes.bool,
  hideHeader: PropTypes.bool,
  rest: PropTypes.shape({}),
};

Modal.defaultProps = {
  children: null,
  className: '',
  onOverlayClick: () => {},
  onClose: () => {},
  isVisible: false,
  hideHeader: false,
  rest: {},
};

export default Modal;

function ModalHeader({ onClose, mobileTitle = null, mainTitle = '' }) {
  return (
    <div className="tv-modal__header">
      <div className="tv-modal__header-title tv-mobile--hide">{mainTitle}</div>

      <div className="tv-modal__header-title tv-mobile--show">
        {mobileTitle || mainTitle}
      </div>

      <IconButton onClick={onClose} iconName="times" />
      <hr className="tv-modal__header-divider" />
    </div>
  );
}
