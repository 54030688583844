import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import PropTypes from 'prop-types';

/**
 * A custom useEffect hook that only triggers on updates, not on initial mount
 * @param {Function} effect
 */
function useUpdateEffect(effect, deps = []) {
  const initialMount = useRef(true);

  useEffect(() => {
    if (initialMount.current) {
      initialMount.current = false;
    } else {
      effect();
    }
  }, deps);
}

function useErrorListener(activeError) {
  const { addToast } = useToasts();
  const [error, setError] = useState(activeError);

  useEffect(() => {
    setError(activeError);
  }, [activeError]);

  useUpdateEffect(() => {
    if (error) {
      addToast(error, { appearance: 'error', autoDismiss: true });
    }
  }, [error]);
}

const ErrorListener = ({ activeError }) => {
  useErrorListener(activeError.activeError);
  return null;
};

ErrorListener.propTypes = {
  activeError: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
};
ErrorListener.defaultProps = {
  activeError: null,
};

const mapStateToProps = state => ({
  activeError: state.error,
});

export default connect(mapStateToProps, null)(ErrorListener);
