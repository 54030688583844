import React from 'react';
import Modal from 'components/molecules/Modal';
import './style.scss';
import Button from 'components/atoms/Button';
import PropTypes from 'prop-types';

const ReconfirmTermsAndConditions = ({ cancel, accept }) => (
  <Modal
    isVisible
    onClose={() => {}}
    hideHeader
    className="tv-modal__container__cancel-terms-and-condition"
  >
    <div className="tv-modal__container__wrapper">
      <div className="tv-modal__container__content">
        <h1 className="content-text">
          Du måste acceptera Transvoice allmänna villkor för att använda
          verktyget. Om du inte gör det så blir du utloggad. Vill du fortsätta
          och inte acceptera våra allmänna villkor?
        </h1>
      </div>
      <div className="tv-modal__container__bottom">
        <Button label="Nej" palette="outline" onClick={cancel} />
        <Button label="Ja" onClick={accept} />
      </div>
    </div>
  </Modal>
);

ReconfirmTermsAndConditions.propTypes = {
  cancel: PropTypes.func,
  accept: PropTypes.func,
};

ReconfirmTermsAndConditions.defaultProps = {
  cancel: () => {},
  accept: () => {},
};

export default ReconfirmTermsAndConditions;
