export const WORK_ASSIGNMENT_HIDE = 'WORK_ASSIGNMENT_HIDE';
export const DISPLAY_RATED_ASSIGNMENT = 'DISPLAY_RATED_ASSIGNMENT';
export const HIDE_RATED_ASSIGNMENT = 'HIDE_RATED_ASSIGNMENT';

export const workAssignmentHidden = WorkAssignmentIdentifier => ({
  type: WORK_ASSIGNMENT_HIDE,
  payload: WorkAssignmentIdentifier,
  meta: {
    resource: 'assignmentOverviewDetail',
    thunk: 'assignmentOverviewDetailHide',
  },
});

export const displayRatedAssignmentModal = display => ({
  type: DISPLAY_RATED_ASSIGNMENT,
  payload: display,
});

export const hideRatedAssignmentModal = () => ({
  type: HIDE_RATED_ASSIGNMENT,
  payload: {},
});