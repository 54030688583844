/* eslint-disable no-nested-ternary */
import React, { isValidElement } from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller } from 'react-hook-form';
import { Field, Translate, Button } from 'components';
import './style.scss';
import { LOCAL_STORE_PASSWORDHASH } from 'constant';
import generateHashPassword from 'utils/hashUtil';
import { validPasswordFormat } from 'utils/miscUtil';

const ChangePassword = ({ onSetPassword, hideCurrentPassword, className }) => {
  const {
    formState: { errors },
    control,
    handleSubmit,
    getValues,
  } = useForm();
  const onSubmit = data => onSetPassword(data);
  const localPassword = localStorage.getItem(LOCAL_STORE_PASSWORDHASH);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={`tv-change-password-form__container ${className}`}
    >
      {!hideCurrentPassword ? (
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <Field
              type="password"
              onChange={onChange}
              onBlur={onBlur}
              name="currentPassword"
              value={value}
              placeholder={Translate({ content: 'settings.currentPassword' })}
              label={Translate({ content: 'settings.currentPassword' })}
              autoFocus
              error={
                errors.currentPassword
                  ? errors?.currentPassword?.type === 'required'
                    ? Translate({
                        content: 'validator.isRequired',
                        params: {
                          name: Translate({
                            content: 'settings.currentPassword',
                          }),
                        },
                      })
                    : errors.currentPassword.message
                  : null
              }
            />
          )}
          type="password"
          name="currentPassword"
          rules={{
            required: true,
            validate: {
              passwordValid: v =>
                localPassword === generateHashPassword(v) ||
                Translate({
                  content: 'settings.matchcurrentPassword',
                }),
            },
          }}
        />
      ) : (
        <h4 className="tv-login-form__header">Välj lösenord</h4>
      )}

      <Controller
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <Field
            type="password"
            onChange={onChange}
            onBlur={onBlur}
            name="newPassword"
            value={value}
            max="256"
            placeholder={Translate({
              content: 'settings.newPasswordPlaceHolder',
            })}
            label={Translate({ content: 'settings.newPassword' })}
            autoFocus
            error={
              errors.newPassword
                ? errors?.newPassword?.type === 'required'
                  ? Translate({
                      content: 'validator.isRequired',
                      params: {
                        name: Translate({
                          content: 'settings.newPassword',
                        }),
                      },
                    })
                  : errors.newPassword.message
                : null
            }
          />
        )}
        type="password"
        name="newPassword"
        rules={{
          required: true,
          validate: {
            formatValidate: v => {
              const { isValid, message } = validPasswordFormat(v || '');
              return isValid ? true : `Måste innehålla ${message}`;
            },
          },
        }}
      />

      <Controller
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <Field
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            name="retypeNewPassword"
            placeholder={Translate({
              content: 'settings.newPasswordPlaceHolder',
            })}
            max="256"
            type="password"
            label={Translate({ content: 'settings.repeatPassword' })}
            error={
              errors.retypeNewPassword
                ? errors?.retypeNewPassword?.type === 'required'
                  ? Translate({
                      content: 'validator.isRequired',
                      params: {
                        name: Translate({ content: 'settings.repeatPassword' }),
                      },
                    })
                  : errors.retypeNewPassword.message
                : null
            }
          />
        )}
        type="password"
        name="retypeNewPassword"
        rules={{
          required: true,
          validate: {
            matchesPreviousPassword: value => {
              const { newPassword } = getValues();
              return (
                newPassword === value ||
                Translate({
                  content: 'settings.passwordMatch',
                })
              );
            },
          },
        }}
      />
      <Button
        type="submit"
        label={Translate({ content: 'settings.saveNewPassword' })}
      />
    </form>
  );
};

ChangePassword.propTypes = {
  onSetPassword: PropTypes.func,
  hideCurrentPassword: PropTypes.bool,
  className: PropTypes.string,
};
ChangePassword.defaultProps = {
  onSetPassword: () => {},
  hideCurrentPassword: false,
  className: '',
};

export default ChangePassword;
