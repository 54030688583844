import React, { useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Button, Translate, DropdownMenuItem } from 'components';
import './style.scss';
import { fromResource } from 'store/selectors';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const AvailabilityMenuDropdown = ({
  displayAvailabilityModal,
  onDisplayModal,
}) => {
  const [showDropDown, setShowDropDown] = useState(false);
  const isInSickLeave = useSelector(state =>
    fromResource.getSickLeaveInfo(state),
  );
  const history = useHistory();

  const toggleMenu = () => {
    setShowDropDown(!showDropDown);
  };

  const onBlur = event => {
    const { currentTarget } = event;
    event.preventDefault();
    event.stopPropagation();
    setTimeout(() => {
      if (!currentTarget.contains(document.activeElement)) {
        setShowDropDown(false);
      }
    }, 250);
  };

  const handleClick = () => {
    if (isInSickLeave) {
      onDisplayModal();
    } else {
      history.push('/settings#sick-leave');
    }
  };

  return (
    <div>
      <div
        role="button"
        tabIndex="-1"
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          toggleMenu();
        }}
        onKeyDown={() => {}}
        onBlur={onBlur}
      >
        <Button
          label={Translate({ content: 'calendar.submitTimeForAvailability' })}
          className="tv-calendar__availability-exception-button"
        />
      </div>

      {showDropDown && (
        <div className="tv-availability-list__menu">
          <DropdownMenuItem
            leftSideImage="../../images/manage_availability.png"
            heading="Tillgänglig / Upptagen"
            content="Använd detta för att ange kortare tillgänglighet eller upptagen tid."
            onClick={() => {
              setShowDropDown(false);
              displayAvailabilityModal();
            }}
          />
          <DropdownMenuItem
            leftSideImage="../../images/schedule_availability.png"
            heading="Schemalagd tillgänglighet"
            content="Här schemalägger du din återkommande tillgänglighet."
            navigateTo="/settings/#default-availability"
          />
          <DropdownMenuItem
            leftSideImage="../../images/long_leave.png"
            heading="Lägg in längre ledighet"
            content="Här anger du ledighet i en längre  sammanhängande period."
            navigateTo="/settings/#semesters-allocation"
          />
          <DropdownMenuItem
            leftSideImage="../../images/sick_leave.png"
            heading={isInSickLeave ? 'Friskanmäl dig' : 'Sjukanmälan'}
            content={
              isInSickLeave
                ? 'Du är just nu sjukanmäld. Friskanmäl dig för att kunna ta emot uppdrag.'
                : 'Här anmäler du om du blivit akut sjuk.'
            }
            onClick={handleClick}
            navigateTo={isInSickLeave ? '/' : '/settings#sick-leave'}
          />
        </div>
      )}
    </div>
  );
};

AvailabilityMenuDropdown.propTypes = {
  displayAvailabilityModal: PropTypes.func,
  onDisplayModal: PropTypes.func,
};

AvailabilityMenuDropdown.defaultProps = {
  displayAvailabilityModal: () => {},
  onDisplayModal: () => {},
};

export default AvailabilityMenuDropdown;
