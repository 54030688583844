import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';
import { iconForTheServiceType } from 'utils/miscUtil';

function AssignmentType({
  status,
  serviceName,
  className,
  isColoredIcon,
  showShortText,
}) {
  return (
    <div className={`tv-assignment-type__container ${className}`}>
      <i
        className={` icon tv-assignment-type-article-icon material-icons ${
          isColoredIcon ? `tv-assignment-type-article-icon-color--${status}` : ''
        } `}
      >
        {iconForTheServiceType(serviceName)}
      </i>
      <div className="tv-assignment-type-article-name">
        {!showShortText
          ? serviceName
          : serviceName.substring(
              0,
              serviceName.length > 4
                ? serviceName.length - 4
                : serviceName.length,
            )}
      </div>
    </div>
  );
}
AssignmentType.propTypes = {
  status: PropTypes.string,
  serviceName: PropTypes.string,
  className: PropTypes.string,
  isColoredIcon: PropTypes.bool,
  showShortText: PropTypes.bool,
};
AssignmentType.defaultProps = {
  status: 'available',
  serviceName: 'Telefontolkning',
  className: '',
  isColoredIcon: true,
  showShortText: false,
};

export default AssignmentType;
