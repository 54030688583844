/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import {
  AssignmentType,
  AssignmentCountdown,
  AssignmentStatus,
  AssignmentRequestHandler,
} from 'components';
import { convertToDayJsObject } from 'utils/dateTimeUtils';

import './style.scss';

function NotificationRequest({
  item,
  onAccept,
  onReject,
  history,
  handleCardClick,
  onShowInCalendar
}) {
  return (
    <div className="tv-notification-request__container" role="button">
      <div className="tv-assignment-item-mobile__container">
        <div
          className="tv-notification-request__header"
          onClick={() =>
            handleCardClick(
              'Request',
              item.WorkAssignmentIdentifier,
              item.NotificationIdentifier,
            )
          }
        >
          <AssignmentType
            isColoredIcon
            status={item.OrderStatus}
            serviceName={item.ArticleName}
          />
          <AssignmentCountdown
            className="tv-notification-request__countdown"
            expiryTime={convertToDayJsObject(item?.DatetimeExpiration)}
          />
        </div>
        <div className="tv-notification-request__content">
          <div
            className="tv-notification-request__text text-max-length"
            onClick={() =>
              handleCardClick(
                'Request',
                item.WorkAssignmentIdentifier,
                item.NotificationIdentifier,
              )
            }
          >
            <i className="material-icons">language</i>
            {item.Skill}
          </div>
          <div
            className="tv-notification-request__text text-max-length"
            onClick={() =>
              handleCardClick(
                'Request',
                item.WorkAssignmentIdentifier,
                item.NotificationIdentifier,
              )
            }
          >
            <i className="material-icons">access_time_filled</i>
            {`${convertToDayJsObject(item.DatetimeOrderFrom).format(
              'DD MMM, HH:mm',
            )} - ${convertToDayJsObject(item.DatetimeOrderTo).format('HH:mm')}`}
          </div>
          <div className="">
            <div
              onClick={() =>
                handleCardClick(
                  'Request',
                  item.WorkAssignmentIdentifier,
                  item.NotificationIdentifier,
                )
              }
            >
              <div className="tv-notification-request__text text-max-length">
                <i className="material-icons">person</i>
                Beställare: {item.Customer.CustomerName}
              </div>
            </div>
            <div className="tv-notification-request__action">
              <AssignmentRequestHandler
                item={item}
                onAccept={onAccept}
                onReject={onReject}
                onShowInCalendar={onShowInCalendar}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

NotificationRequest.propTypes = {
  onAccept: PropTypes.func,
  onReject: PropTypes.func,
  handleCardClick: PropTypes.func,
  onShowInCalendar: PropTypes.func,
};
NotificationRequest.defaultProps = {
  onAccept: () => {},
  onReject: () => {},
  handleCardClick: () => {},
  onShowInCalendar: () => {},
};

export default NotificationRequest;
