import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Translate } from 'components';

import './style.scss';

function MobileAppInfoModal({ isVisible, onClose, imagePath, linkPath }) {
  return (
    <Modal
      className="tv-mobile-app-info__container"
      onClose={onClose}
      isVisible={isVisible}
      mainTitle={Translate({ content: 'mobileAppInfo.title' })}
    >
      <div className="tv-mobile-app-info__container__body">
        <p>Du vet väl om att Transvoice har en app för tolkar?</p>
        <p>Ladda ner den här. Hör av dig till oss om du har några frågor.</p>
        <div className="download-image">
          <a href={linkPath} target="_blank" rel="noopener noreferrer">
            <img
              className="download-image__download"
              alt="download-icon"
              src={imagePath}
            />
          </a>
        </div>
      </div>
    </Modal>
  );
}

MobileAppInfoModal.propTypes = {
  isVisible: PropTypes.bool,
  onClose: PropTypes.func,
  imagePath: PropTypes.string,
  linkPath: PropTypes.string,
};
MobileAppInfoModal.defaultProps = {
  isVisible: false,
  onClose: () => {},
  imagePath: '',
  linkPath: '',
};

export default MobileAppInfoModal;
