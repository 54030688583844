import React from 'react';
import PropTypes from 'prop-types';
import {
  Button,
} from 'components';

import './style.scss';

function AssignmentNavigator({ onClickPrevious, onClickNext, className }) {
  return (
    <div className={`${className} tv-assignment-navigator__container`}>
        <div className="tv-assignment-navigator__content">
          <Button onClick={onClickPrevious} className="back-icon" palette="text" label={<i className="fa fa-chevron-left" />} />
        <span role="button" tabIndex={0} onKeyPress={onClickPrevious} onClick={onClickPrevious}>Föregående uppdrag</span>
        </div>
        <div className="tv-assignment-navigator__content">
        <span role="button" tabIndex={0} onKeyPress={onClickNext} onClick={onClickNext}>Nästa uppdrag</span>
          <Button onClick={onClickNext} className="close-icon" palette="text" label={<i className="fa fa-chevron-right"/> }/>
        </div>
    </div>
  );
}

AssignmentNavigator.propTypes = {
  onClickPrevious:PropTypes.func,
  onClickNext:PropTypes.func,
  className:PropTypes.string
};
AssignmentNavigator.defaultProps = {
  onClickPrevious:()=>{},
  onClickNext:()=>{},
  className:""
};

export default AssignmentNavigator;
