import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'components';

import './style.scss';
import StarRating from '../StarRating';

function UserFeedbackModal({
  isVisible,
  onOverlayClick,
  onClick,
  onClose,
  item,
}) {
  const {
    RatingCustomer,
    CommentRatingCustomer,
    OrderNumber,
    CustomerName,
  } = item;
  return (
    <Modal
      className="tv-user-feedback-modal__container"
      hideHeader
      onClose={onClose}
      onOverlayClick={onOverlayClick}
      isVisible={isVisible}
    >
      <div className="tv-modal__container__header">
        <h3 className="tv-modal__container__header__title">Bra jobbat! </h3>
      </div>

      <div className="tv-modal__container__content">
        <div>
          <h5 className="tv-modal__container__content__heading-name">
            {CustomerName}
          </h5>
          <h5 className="tv-modal__container__content__heading">
            {OrderNumber}
          </h5>
          <p>Kunden var mycket nöjd</p>
        </div>

        <StarRating defaultValue={RatingCustomer} viewMode />

        {CommentRatingCustomer && (
          <p className="tv-modal__container__content__comment">
            {`"${CommentRatingCustomer}"`}
          </p>
        )}
      </div>

      <div className="tv-modal__container__footer">
        <Button
          onClick={onClick}
          className="tv-user-feedback-modal__button"
          label="Tack!"
        />
      </div>
    </Modal>
  );
}

UserFeedbackModal.propTypes = {
  isVisible: PropTypes.bool,
  onOverlayClick: PropTypes.func,
  onClose: PropTypes.func,
  onClick: PropTypes.func,
  item: PropTypes.shape({
    RatingCustomer: PropTypes.number,
    CommentRatingCustomer: PropTypes.string,
    OrderNumber: PropTypes.string,
    CustomerName: PropTypes.string,
  }),
};
UserFeedbackModal.defaultProps = {
  isVisible: false,
  onOverlayClick: () => {},
  onClose: () => {},
  onClick: () => {},
  item: {
    RatingCustomer: 1,
    CommentRatingCustomer: '',
    OrderNumber: '',
    CustomerName: '',
  },
};

export default UserFeedbackModal;
