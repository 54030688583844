import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, TimePicker } from 'components';
import {
  currentDate,
  dayJsDuration,
  localDayjs as dayjs,
} from 'utils/dateTimeUtils';
import './style.scss';

function AvailablitySlot({
  state,
  text,
  dayName,
  onCopyTimeToAll,
  onDelete,
  onAddAvailability,
  onChange,
  showCopyTimeToAll,
  slotStart,
  slotEnd,
}) {
  const [startTime, setStartTime] = useState(slotStart);

  const [endTime, setEndTime] = useState(slotEnd);
  const [validation, setValidation] = useState({
    isValid: true,
    message: '',
  });

  const [timeDiff, setTimeDiff] = useState(dayjs.duration({ hours: 1 }));

  useEffect(() => {
    setStartTime(slotStart);
  }, [slotStart]);

  useEffect(() => {
    setEndTime(slotEnd);
    // eslint-disable-next-line react/prop-types
    const differentTime = dayjs.duration(slotEnd.diff(startTime));
    if (differentTime.asMinutes() < 0) {
      setValidation({
        isValid: false,
        message: '*Sluttiden måste vara högre än starttiden',
      });
    } else if (differentTime.asMinutes() < 15) {
      setValidation({
        isValid: false,
        message: '*Tidsramen bör vara minst 15 minuter',
      });
    } else {
      setValidation({
        isValid: true,
        message: '',
      });
    }
    setTimeDiff(differentTime);
  }, [slotEnd]);

  const onChangeTime = (dateFor, date) => {
    let [start, end] = [startTime, endTime];
    let differentTime = dayjs.duration(end.diff(startTime));
    let isValid = true;
    if (dateFor === 'start') {
      start = date;
      end = date.add(timeDiff);
      setStartTime(start);
      setEndTime(end);
    }
    if (dateFor === 'end') {
      end = date;
      differentTime = dayjs.duration(end.diff(startTime));
      setEndTime(date);
      setTimeDiff(differentTime);
    }
    if (differentTime.asMinutes() < 0) {
      isValid = false;
      setValidation({
        isValid: false,
        message: '*Sluttiden måste vara högre än starttiden',
      });
    } else if (differentTime.asMinutes() < 15) {
      isValid = false;
      setValidation({
        isValid: false,
        message: '*Tidsramen bör vara minst 15 minuter',
      });
    }
    onChange({
      day: dayName,
      start,
      end,
      isValid,
    });
  };

  const disabled = state === 'inactive';

  return (
    <div className="tv-availablity-slot__container">
      <div className="tv-availablity-slot__upper-row">
        <div className="tv-availablity-slot__day">{text}</div>
        <div className="tv-availablity-slot__time-wrapper">
          <div className="tv-availablity-slot__time-picker-start">
            <span className="tv-availablity-slot__label">Från</span>
            <TimePicker
              onChange={date => onChangeTime('start', date)}
              disabled={disabled}
              startFromDayBegin
              minuteStep={15}
              defaultValue={startTime}
              className={`tv-availablity-slot__start-time ${
                !validation.isValid && 'tv-availablity-slot--invalid'
              }`}
              />
          </div>
          <div className="tv-availablity-slot__time-picker-end">
            <span className="tv-availablity-slot__label">Till</span>
            <TimePicker
              onChange={date => onChangeTime('end', date)}
              disabled={disabled}
              defaultValue={endTime}
              durationCalculate={startTime}
              minuteStep={15}
              isNotRanged={false}
              className={`tv-availablity-slot__end-time ${
                !validation.isValid && 'tv-availablity-slot--invalid'
              }`}
              />
            </div>
        </div>
        {state === 'inactive' && (
          <div className="tv-availablity-slot__button-wrapper">
            <Button
              className="tv-availablity-slot__text"
              palette="text"
              label="+ Lägg till dag"
              onClick={() => onAddAvailability(dayName)}
            />
          </div>
        )}
        {state === 'active' && (
          <div className="tv-availablity-slot__button-wrapper">
            {showCopyTimeToAll && (
              <Button
                onClick={() =>
                  onCopyTimeToAll({
                    start: startTime,
                    end: endTime,
                    isValid: validation.isValid,
                  })
                }
                className="tv-availablity-slot__text"
                palette="text"
                label="Kopiera till alla"
              />
            )}
            <Button
              onClick={() => onDelete(dayName)}
              className="tv-availablity-slot__text"
              palette="text"
              label="Radera"
            />
          </div>
        )}
      </div>

      <div className="tv-availablity-slot__error-row">{validation.message}</div>
    </div>
  );
}

AvailablitySlot.propTypes = {
  state: PropTypes.string,
  text: PropTypes.string,
  onCopyTimeToAll: PropTypes.func,
  onDelete: PropTypes.func,
  onChange: PropTypes.func,
  onAddAvailability: PropTypes.func,
  showCopyTimeToAll: PropTypes.bool,
  dayName: PropTypes.string,
  slotStart: PropTypes.shape({}),
  slotEnd: PropTypes.shape({}),
};
AvailablitySlot.defaultProps = {
  state: 'active',
  dayName: '',
  text: '',
  showCopyTimeToAll: false,
  onCopyTimeToAll: () => {},
  onDelete: () => {},
  onAddAvailability: () => {},
  onChange: () => {},
  slotStart: currentDate(),
  slotEnd: currentDate().add(1, 'hour'),
};

export default AvailablitySlot;
