/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resourceCreateRequest } from 'store/actions';
import { Spinner } from 'components';
import { fulfilled, pending, clean } from 'redux-saga-thunk';
import generateHashPassword from 'utils/hashUtil';
import { v4 } from 'uuid';
import { LOCAL_STORE_PASSWORDHASH } from 'constant';
import { useToasts } from 'react-toast-notifications';
import Translate from 'components/atoms/Translate';

function ResetPasswordContainer({ render: Render, onSuccess }) {
  const dispatch = useDispatch();
  const resource = 'setPassword';
  const [key, setKey] = useState(v4());
  const { addToast } = useToasts();
  const [newPasswordHashed, setNewPasswordHashed] = useState(
    localStorage.getItem(LOCAL_STORE_PASSWORDHASH),
  );

  const isPasswordResetPending = useSelector(state =>
    pending(state, `${resource}Create`),
  );

  const isPasswordResetFulFilled = useSelector(state =>
    fulfilled(state, `${resource}Create`),
  );

  useEffect(() => {
    if (isPasswordResetFulFilled) {
      if (onSuccess) {
        onSuccess();
      } else {
        localStorage.setItem(LOCAL_STORE_PASSWORDHASH, newPasswordHashed);
      }
      setKey(v4());

      addToast(Translate({ content: 'settings.passwordResetSucces' }), {
        appearance: 'success',
      });
      dispatch(clean(`${resource}Create`));
    }
  }, [isPasswordResetFulFilled]);

  const handleSetPassword = ({ newPassword }) => {
    const params = {
      PasswordHash: generateHashPassword(newPassword),
    };
    setNewPasswordHashed(params.PasswordHash);

    dispatch(resourceCreateRequest(resource, params));
  };

  return (
    <>
      <Render onSetPassword={handleSetPassword} key={key} />

      {isPasswordResetPending && <Spinner />}
    </>
  );
}

ResetPasswordContainer.propTypes = {};

export default ResetPasswordContainer;
