/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './style.scss';

const SelectDropdown = ({
  options,
  onSelect,
  defaultOption,
  placeholder,
  select,
}) => {
  const [selected, setSelected] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef(null);

  useEffect(() => {
    setSelected(defaultOption);
  }, []);

  useEffect(() => {
    if (select) setSelected(select);
  }, [select]);

  const handleBlur = () => {
    setIsOpen(false);
  };
  const handleToogle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div className="tv-select__container" onBlur={handleBlur} ref={selectRef}>
        <SelectorDisplay
          selected={selected}
          placeholder={placeholder}
          toogle={handleToogle}
        />
        <SelctorDropdownToogle setIsOpen={setIsOpen} isOpen={isOpen} />
        <ul
          className={`tv-select__drop-down-container ${
            isOpen && 'tv-select__drop-down--open'
          }`}
        >
          {options.map(item => (
            <OptionItem
              selected={selected}
              item={item}
              onSelect={onSelect}
              setIsOpen={setIsOpen}
            />
          ))}
        </ul>
      </div>
    </>
  );
};

SelectDropdown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      displayText: PropTypes.string.isRequired,
    }),
  ),
  defaultOption: PropTypes.shape({
    displayText: PropTypes.string,
  }),
  select: PropTypes.shape({
    displayText: PropTypes.string,
  }),
  onSelect: PropTypes.func,
  placeholder: PropTypes.string,
};
SelectDropdown.defaultProps = {
  options: [],
  defaultOption: null,
  select: null,
  onSelect: () => {},
  placeholder: '',
};

export default SelectDropdown;

const OptionItem = ({ selected, item, onSelect, setIsOpen }) => (
  <li
    className={`tv-select__drop-down-item ${
      selected &&
      selected.key === item.key &&
      'tv-select__drop-down-item--selected'
    }`}
    key={item.key}
    onClick={() => {
      onSelect(item);
      setIsOpen(false);
    }}
  >
    {item.displayText}
  </li>
);

const SelectorDisplay = ({ selected, placeholder, toogle }) => (
  <span
    className={`tv-select__display ${
      !selected && 'tv-select__display--default'
    }`}
    onClick={toogle}
  >
    {selected?.displayText || placeholder}
  </span>
);

const SelctorDropdownToogle = ({ setIsOpen, isOpen }) => (
  <div
    className="tv-select__display-icon material-icons"
    onClick={() => setIsOpen(!isOpen)}
  >
    {!isOpen ? 'arrow_drop_down' : 'arrow_drop_down'}
  </div>
);
