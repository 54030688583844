import React from 'react';
import PropTypes from 'prop-types';
import ReactDatePicker from 'react-datepicker';
import './style.scss';

const DatePicker = ({
  onChange,
  selectedDate,
  className,
  disabled,
  ...rest
}) => (
  <div className={`tv-datepicker ${className}`}>
    <ReactDatePicker
      dateFormat="yyyy-MM-dd"
      selected={selectedDate}
      onChange={onChange}
      locale="sv"
      calendarStartDay={1}
      readOnly={disabled}
      {...rest}
    />
    <span className="tv-datepicker__icon material-icons">&#xe935;</span>
  </div>
);

DatePicker.propTypes = {
  onChange: PropTypes.func,
  className: PropTypes.string,
  selectedDate: PropTypes.shape({}),
  disabled: PropTypes.bool,
};

DatePicker.defaultProps = {
  onChange: () => {},
  className: '',
  selectedDate: new Date(),
  disabled: false,
};

export default DatePicker;
