import Modal from 'components/molecules/Modal';
import React, { useEffect, useState } from 'react';
import './style.scss';
import Button from 'components/atoms/Button';
import { connect, useSelector } from 'react-redux';
import { resourceDetailReadRequest } from 'store/actions';
import PropTypes from 'prop-types';
import axios from 'axios';
import { apiUrl } from 'config';

const TermsAndConditionsModal = ({ getInterpreterProfile, reject }) => {
  const [displayModal, setDisplayModal] = useState(false);
  const { token } = useSelector(state => state.authenticate);
  const datetimeTermsAndConditionsAccepted = useSelector(
    state =>
      state.resource.userProfile?.detail?.Profile
        .DatetimeTermsAndConditionsAccepted,
  );

  useEffect(() => {
    if (datetimeTermsAndConditionsAccepted !== undefined) {
      if (
        datetimeTermsAndConditionsAccepted === '' ||
        datetimeTermsAndConditionsAccepted === null
      ) {
        setDisplayModal(true);
      } else {
        setDisplayModal(false);
      }
    }
  }, [datetimeTermsAndConditionsAccepted]);

  const onAccept = () => {
    axios
      .post(`${apiUrl}SetTermsAndConditionsAccepted`, {
        Token: token,
      })
      .then(async response => {
        if (response.data && !response.data.Errors) {
          await getInterpreterProfile();
          setDisplayModal(false);
        }
      });
  };

  return (
    <Modal
      isVisible={displayModal}
      onClose={() => {}}
      hideHeader
      className="tv-modal__container__terms-and-conditions__modal"
    >
      <div className="tv-modal__container__wrapper">
        <div className="tv-modal__container__header">
          <h1 className="heading-text">
            Allmänna villkor och riktlinjer för tolkuppdrag
          </h1>
        </div>
        <div className="tv-modal__container__content">
          <div className="tv-modal__container__content__terms">
            <h5 className="tc-heading">1. Inledning</h5>
            <p className="tc-paragraph">
              Dessa allmänna villkor gäller för tolkar som utför tolkuppdrag för
              Transvoice Sweden AB, org. nr: 556653- 6370 samt Transvoice AB,
              org.nr. 556482-8654, Lindhagensgatan 126, 112 51 Stockholm.
              <br />
              Transvoice-bolagen benämns härefter gemensamt
              &quot;Transvoice&quot;. Dessa allmänna villkor reglerar vad som
              ska gälla mellan parterna vid utförande av tolkuppdrag för
              Transvoice.
            </p>
            <h5 className="tc-heading">2. Tolken som uppdragstagare</h5>
            <p className="tc-paragraph">
              Tolkuppdraget faller inte inom ramen för anställning, dock betalar
              Transvoice de sociala avgifter som gäller för uppdragstagaren med
              A-skattsedel. Sjuk-och friskanmälan till Försäkringskassan
              ansvarar tolken själv för. Antal tolkuppdrag som kommer in till
              Transvoice per dag beror på den efterfrågan som för tillfället
              finns på marknaden och Transvoice kan därför inte förbinda sig att
              ge några tolkuppdrag till tolken. Tolken arbetar därmed på
              frilansbasis med ersättning per timme.
              <br />
              Utbetalning av arvode sker efter att tolken rapporterat sina
              utförda uppdrag till Transvoice. I det fall tolken är godkänd för
              F-skatt utbetalas lön efter att tolken fakturerat Transvoice för
              utförda tolktjänster. Tolken ska alltid lämna in registerutdrag
              för innevarande år till Transvoice.
              <br />
              Som tolk får man ej finnas upptagen i polisens
              belastningsregister. Transvoice förbehåller sig rätten att göra
              kontroller mot Sveriges domstolar för alla aktiva uppdragstagare.
              Detta görs en gång per år via tjänsten Bakgrundskollen.se. Vid
              accept av Transvoice allmänna villkor så accepteras också att
              denna kontroll görs en gång per år.
            </p>
            <h5 className="tc-heading">
              3. Tolkens rättigheter och skyldigheter
            </h5>
            <p className="tc-paragraph">
              Tolken har som individuell uppdragstagare stor frihet och
              valmöjlighet främst då det gäller planering och åtagande av
              arbete. Tolken är inte bunden till fasta tider eller ärenden, utan
              kan självständigt välja att åta sig eller avböja uppdrag. Tolken
              är skyldig att iaktta Transvoice allmänna villkor och riktlinjer
              inklusive arbetsinstruktion och utföra varje tolkning i enlighet
              med dessa.
              <br />
              Tolken <span>ska</span> rapportera uppdrag i enlighet med bilaga
              1.
              <br />
              Tolken bör inneha en ansvarsförsäkring som är avsedd för utförande
              av tolkuppdrag.
              <br />
              Tolken <span>ska</span> utföra sitt uppdrag i enlighet med
              Kammarkollegiets föreskrift God tolksed. Med God tolksed menas det
              regelverk som finns på området, den yrkeskodex som utvecklats av
              tolkar och den praxis som skapats av Kammarkollegiet på
              tolkområdet.
            </p>
            <h5 className="tc-heading">4. Tilldelning av tolkuppdrag</h5>
            <p className="tc-paragraph">
              Transvoice har en tydlig policy att alltid tillsätta uppdraget med
              den högsta tillgängliga kompetensen för sammanhanget i kombination
              med anmäld tillgänglighet i Transvoice app/portal Tolka!. Det
              innebär att auktoriserade tolkar med speciell kompetens
              prioriteras först och därefter auktoriserade tolkar. Därefter
              förmedlas jobben till utbildade tolkar enligt Kammarkollegiets
              definition och till sist övriga tolkar, det vill säga tolkar som
              har genomgått minst en introduktionsutbildning, har erfarenhet av
              yrket och sedan är testade och godkända i Transvoice
              rekryteringstest.
            </p>
            <p className="tc-paragraph">
              Utöver detta prioriteras tolkar även baserat på kvalitet på
              utförande och acceptansgrad, det vill säga utförda uppdrag utan
              anmärkningar och hur många förfrågningar man accepterat under
              anmäld tillgänglighet, samt enligt närhetsprincipen vid
              kontakttolkningsuppdrag.
              <br />
              Tolkuppdragen förmedlas av Transvoice förmedlingssystem WorkBit
              efter gällande tillsättningsprinciper. När en tolk accepterat ett
              uppdrag är det tolkens skyldighet att genomföra åtagandet.
            </p>
            <h5 className="tc-heading">5. Princip vid åtagande av uppdrag</h5>
            <p className="tc-paragraph">
              När uppdragstagaren accepterat ett åtagande är han/hon skyldig att
              utföra tolkningen. Innan tolken åtar sig och fullföljer ett
              uppdrag, <span>ska</span> denne noga överväga om han/hon är
              lämplig för ärendet och därmed kan utföra tolkningen på ett
              tillfredställande sätt. Om tolken anser sig ej vara kompetent för
              uppdragets innehåll eller av annan anledning olämplig,{' '}
              <span>ska</span> han/hon inte åta sig uppdraget.
              <br />
              Tolken <span>ska</span> endast åta sig uppdrag som han/hon finner
              sig lämpad för.
            </p>
            <h5 className="tc-heading">
              6. När betraktas åtagandet vara accepterat?
            </h5>
            <p className="tc-paragraph">
              När en tolk accepterat en uppdragsförfrågan via Transvoice
              app/portal Tolka! är tolken skyldig att genomföra åtagandet.
              <br />
              Uppdragsförfrågningar som inkommer via telefonsamtal från
              förmedlingen anses som accepterade när tolken har tackat ja, samt
              tilldelats uppdragsuppgifter och uppdragsnummer. Om/när
              uppdragsuppgifter inte distribueras via app/portal utan via e-post
              är detta en bekräftelse på tidigare muntlig överenskommelse där
              tolk accepterat uppdrag på förhand. Tolk och förmedlingen har då
              kommit överens om via telefon eller mejl om vilka dagar och tider
              tolken är tillgänglig för uppdrag.
            </p>
            <h5 className="tc-heading">7. Om kunden avbokar</h5>
            <p className="tc-paragraph">
              Om Transvoice kund avbokar redan inplanerade uppdrag kvarstår
              tolkens rätt till arvode för uppdraget beroende på antal timmar
              kvar till uppdragets starttid vid avbokningstillfället. Olika
              kundavtal har olika regler för dessa avbokningstider för
              kontakttolkning respektive distanstolkning. Dessa
              avtalsbestämmelser för avbokningstider finns att läsa i
              arvodeslistan, som återfinns i Transvoice app och portal.
            </p>
            <h5 className="tc-heading">8. Jäv och tystnadsplikt</h5>
            <p className="tc-paragraph">
              Tolken <span>ska</span> vara opartisk och inte ge uttryck för sina
              åsikter och värderingar. Tolken är en oberoende part och får inte
              åta sig uppdrag där tolkens opartiskhet kan ifrågasättas.
            </p>
            <p>
              Tolkens uppdrag är förenat med tystnadsplikt i enlighet med bland
              annat offentlighets- och sekretesslagen (2009:400) då tolken vid
              utförandet av sina uppdrag kan komma att ta del av
              sekretessbelagda uppgifter. Att röja information kring ett uppdrag
              eller samtalets innehåll är ett allvarligt brott enligt svensk
              lagstiftning.
            </p>
            <h5 className="tc-heading">9. Vite</h5>
            <p className="tc-paragraph">
              Om tolken uteblir eller är försenad utgör detta en avvikelse i
              form av en störning i tjänsten och om kunden ej nyttjar tolkens
              service, betalas varken ersättning för uppdraget eller
              resekostnader. Vid eventuell försening måste Transvoice omedelbart
              meddelas om förseningen. Vid kontakt med förmedlingen kan
              Transvoice i samråd med kund besluta vad som ska göras.
            </p>
            <p className="tc-paragraph">
              Med avvikelse menas bland annat att kunden har upplevt störningar
              i verksamheten i form av kvalitetsbrist i tolkens utförande av
              uppdraget, bristande service vid utfört tolkuppdrag eller att
              tolken har uteblivit eller blivit försenad till ett uppdrag. En
              avvikelse kan medföra att tolken får betala ett vite till
              Transvoice.
            </p>
            <p className="tc-paragraph">
              Med vitesersättning menas den ersättning som Transvoice kan komma
              att få betala till kund för avvikelse vid utfört tolkuppdrag. Om
              tolken uteblivit eller kommit försent till ett uppdrag kan tolken
              i vissa fall befrias från skyldigheten att erlägga vitesersättning
              i det fall tolken, genom att uppvisa läkarintyg eller intyg om
              väsentlig störning i lokaltrafik, kan visa att avvikelsen är
              föranledd av omständigheter utanför tolkens kontroll.
            </p>
            <p className="tc-paragraph">
              Vitesersättningen som tolken kan komma att få betala dras av vid
              påföljande månads arvodesutbetalning eller påförs tolken genom
              faktura. Transvoice äger dock rätt att utöver vite kräva
              ersättning för den faktiska skadan.
            </p>
            <h5 className="tc-heading">10. Överlåtelse av avtalet</h5>
            <p className="tc-paragraph">
              Tolken får under inga omständigheter överlåta sitt tolkuppdrag
              till någon annan tolk utan att inhämta Tranvoice skriftliga
              godkännande i förväg. För det fall tolken överlåter sitt
              tolkuppdrag utan att inhämta Tranvoice godkännande i förväg äger
              Transvoice rätt att utkräva vite i enlighet med punkten 8 ovan med
              ett belopp som motsvarar tolkersättningen för uppdraget och
              samarbetet kan helt komma att avslutas.
            </p>
            <h5 className="tc-heading">11. Avtalsdokument</h5>
            <p className="tc-paragraph">
              Detta avtal består av följande handlingar och de ska tolkas i den
              <br />
              ordning de räknas upp nedan:
              <br />
              - Allmänna villkor och riktlinjer
              <br />
              - Arbetsinstruktion – Bilaga 1<br />- Användarvillkor för
              Transvoice app och portal Tolka!
            </p>
            <h5 className="tc-heading">12. Tvist</h5>
            <p className="tc-paragraph">
              Tvist mellan parterna med anledning av detta avtal ska lösas av
              allmän domstol med tillämpning av svensk lag.
            </p>
            <div className="tc-separator" />
            <h5 className="tc-heading-main">Bilaga 1</h5>
            <h5 className="tc-heading-main">Arbetsinstruktion</h5>
            <h5 className="tc-heading-sub">1. Allmänt om branschen</h5>
            <p className="tc-paragraph">
              Tolkbranschen har en mycket viktig funktion i det svenska
              samhället. Sverige är ett invandrartätt land, och har därtill ett
              stort antal internationella besökare i turistande och
              arbetsrelaterade ärenden. För att viktiga samhällsfunktioner,
              myndigheter och företag ska fungera väl i sin verksamhet krävs
              många gånger tolkservice och hjälp med språkförståelsen. Varje
              yrkesverksam tolk bidrar bland annat till det svenska samhällets
              funktionalitet, kommunikationsförmåga och tillväxt.
            </p>
            <p className="tc-paragraph">
              Tolkar och medarbetare på Transvoice har ett stort ansvar och ska
              tillsammans svara på den efterfrågan som finns, och samtidigt
              arbeta för expansion och utveckling. Vi ska möjliggöra,
              upprätthålla och stärka kommunikationen mellan beställare och
              klient. Våra tjänster ska frambringa tillit/trygghet både för den
              enskilde individen och för samhället i stort. En stor mängd
              företag, organisationer, och privatpersoner som bor eller är på
              besök i Sverige, har ett behov av tolkning. Genom att ta vårt
              ansvar driver vi branschen framåt och verkar för en säker och
              positiv framtid.
            </p>
            <h5 className="tc-heading-sub">
              2. Förpliktelser vid uppdragstagande
            </h5>
            <h5 className="tc-heading">2.1 Vardagliga rutiner</h5>
            <p className="tc-paragraph">
              - Det åligger dig att se till att du har alla uppgifter om
              tolkuppdraget
              <br />- Adressen <span>ska</span> kollas upp i förväg så att du
              hittar till kunden utan problem eller försening
              <br />
              - Du kommer i god tid till varje uppdrag, gärna 10 minuter före
              <br />
              - Vid uppdragets start ger du följande upplysningar till
              involverade parter på både mål- och källspråk:
              <br />
              <span className="tc-italic">
                Jag följer reglerna om tystnadsplikt. Jag är neutral och tar
                inte parti för någon i tolkningssituationen. Jag kommer att
                återge vad som sagts i första person (jag-form).
              </span>
              <br />- Hela tolkningen <span>ska</span> präglas av
              professionalitet, från bemötandet till utförandet.
              <br />- Uppdrag <span>ska</span> rapporteras direkt efter avslut.
              <br />- Kontakttolkning <span>ska</span> alltid signeras av kund i
              Transvoice app Tolka!
              <br />- Vid eventuella problem med uppdrag <span>ska</span>{' '}
              Transvoice kontaktas så att kunden kan underrättas.
              <br />- Distanstolkning <span>ska</span> utföras från ett ostört
              rum där inga störningsmoment föreligger som kan påverka tolkningen
              negativt. Du <span>ska</span> vara ensam i rummet när du utför
              tolkningen. Att distanstolka i en offentlig miljö är{' '}
              <span>strikt förbjudet</span> och kan leda till att samarbetet
              genast avbryts.
              <br />- Det är tolkens ansvar att själv sörja för god ljudmiljö
              och väl fungerande teknisk utrustning vid distanstolkning. <br />-
              Distanstolkning <span>ska</span> endast utföras från Sverige eller
              i land inom EU/EES, ej från annat land.
              <br />- Du måste stanna under hela den bokade tiden om kunden så
              önskar.
            </p>
            <h5 className="tc-heading">2.2 Yrkesbevis</h5>
            <p className="tc-paragraph">
              Transvoice tolklegitimation eller Kammarkollegiets behörighetskort{' '}
              <span>ska</span> hållas väl synlig under hela vistelsen hos
              kunden. Denna identifikation är ett yrkesbevis, visar leverans för
              aktuell leverantör, samt är kundens säkerhet att en utbildad och
              godkänd tolk utför deras tjänster.
            </p>
            <p className="tc-paragraph">
              För uppdaterat yrkesbevis, såsom vid förändring av kompetensnivå,
              ska Transvoice kontaktas för ny legitimation.
            </p>
            <h5 className="tc-heading">2.3 Planering</h5>
            <p className="tc-paragraph">
              När du tar täta tolkuppdrag måste du vara väl förberedd. Det är
              alltid ditt ansvar att du hinner till nästa uppdrag. Om du inte
              kan planera optimalt så att du kan närvara på utsatt tid,{' '}
              <span>ska</span> du inte åta dig ärendet. Du får inte pressa
              kunden på att få gå tidigare på grund av efterföljande uppdrag.
              Det är endast på kundens eget initiativ som ett uppdrag kan
              avslutas före utsatt tid. Vi <span>ska</span> alltid acceptera den
              bokade tiden.
            </p>
            <h5 className="tc-heading">
              2.4 Tolkningen sker i enlighet med god tolksed
            </h5>
            <p className="tc-paragraph">
              - Du har tystnadsplikt (skyldighet att iaktta sekretess)
              <br />- Du <span>ska</span> vara neutral och inte ge uttryck för
              egna åsikter och värderingar
              <br />- Du <span>ska</span> vara opartisk och inte ta ställning
              för någon av parterna
              <br />- Du <span>ska</span> översätta allt som sägs i rummet, utan
              att lägga till, dra ifrån eller förändra att lägga till, dra ifrån
              eller förändra
              <br />
              - Du återger vad som sägs i första person/jag-form
              <br />- Du <span>ska</span> undvika jäv-situation
            </p>
            <h5 className="tc-heading">2.5 Professionell framtoning</h5>
            <p className="tc-paragraph">
              arje uppdragstagare åtar sig att vara representativt klädd. Med
              detta menas att du har diskret klädsel och därmed inte bär
              utmanande eller färgstarka plagg. Det diskreta intrycket
              inkluderar även att inte lukta rök/snus, alkohol eller bära tunga
              parfymer.
            </p>
            <h5 className="tc-heading-sub">3. Samarbete tolk och förmedling</h5>
            <p className="tc-paragraph">
              Varje förmedlare på Transvoice har ansvar för olika språk och
              områden. Därmed finns goda förutsättningar för ett gott och nära
              samarbete mellan uppdragstagare och förmedling.
              <br />
              Uppdragstagarens lojalitet, flexibilitet och tillgänglighet utgör
              faktorer för att ett långsiktigt och hållbart samarbete ska ta
              form. Transvoice finns till hands via tolklinjen för att
              uppdragstagaren lätt ska kunna få tag på förmedlingen vid såväl
              planering och inbokning av uppdrag som vid akuta situationer.
            </p>
            <h5 className="tc-heading-sub">
              4. Rapportering av uppdrag – Utbetalning och fakturering av arvode
            </h5>
            <h5 className="tc-heading">
              4.1 Varför ska uppdragen rapporteras?
            </h5>
            <p className="tc-paragraph">
              En uppdragsrapport är en redogörelse för utfört arbete.
              <br /> Rapporten utgör underlag för utbetalning av arvode för dina
              tjänster. Varje rapporterat uppdrag är unikt för varje enskilt
              ärende du utför och din rapport hänvisar alltid till ett aktuellt
              bokningsnummer.
              <br />
              Genom att fylla i en rapport har både du själv, aktuell kund, och
              Transvoice en god kontroll på genomfört uppdrag.
              <br />
              Samtliga parter i denna relation behöver en kvittens på utfört
              uppdrag för att faktureringen och arvoderingen ska bli korrekt.
              <br />
              Fyll i rapporten direkt efter utfört uppdrag. All kontakttolkning
              ska rapporteras i Transvoice app Tolka! och kund ska alltid
              signera uppdraget digitalt i appen.
              <br />
              Distanstolkning kan rapporteras i app eller portal utan signatur.
              Det är mycket viktigt att rapporten är korrekt ifylld, en felaktig
              rapport kan leda till att du inte får ut någon ersättning för
              uppdraget.
            </p>

            <h5 className="tc-heading">4.2 Hur fyller jag i rapporten?</h5>
            <p className="tc-paragraph">
              Vid rapportering av uppdrag ska faktisk tolktid, starttid och
              sluttid, fyllas i. Sluttiden stäms alltid av med tolkanvändaren
              innan samtalets avslut.
              <br />
              Vid kontaktuppdrag där du får ersättning för resa ska avstånd i
              kilometer samt restid till och från uppdraget anges. För
              eventuella utlägg måste kvitto bifogas.
              <br />
              Vid rapportering av uppdrag så ges även möjlighet att lämna ett
              betyg på uppdraget. Gör gärna det tillsammans med en kommentar.
            </p>
            <h5 className="tc-heading">
              4.3 När och var ska ett uppdrag rapporteras? När får jag min
              utbetalning av arvode?
            </h5>
            <p className="tc-paragraph">
              Uppdrag som rapporteras via app eller portal innan den sista varje
              månad och betalas ut den 25:e nästkommande månad.
              <br />
              Vid helg sker utbetalning föregående vardag.
              <br />
              Kontakttolkningsuppdrag kvitteras i app genom att kund signerar på
              skärmen. Rapporteras kontakttolkningsuppdraget utan kundens
              påskrift kan det medföra att arvodet inte betalas ut. Ett bevis på
              utfört uppdrag måste då presenteras för Transvoice.
              <br />
              Alla uppdrag ska alltid rapporteras direkt efter avslut.
              <br />
              Rapporteras uppdrag senare än trettio (30) dagar efter utfört
              uppdragsdatum utgår ingen ersättning för uppdraget.
              <br />
              Transvoice förbehåller sig i sådant fall ändå rätten att debitera
              utfört uppdrag till kund.
            </p>

            <h5 className="tc-heading">4.4 Arvodesspecifikation</h5>
            <p className="tc-paragraph">
              Alla arvodesspecifikationer distribueras digitalt via tolkens
              inlogg i app och portal.
            </p>
            <h5 className="tc-heading-sub">5. Avvikelserapport</h5>
            <p className="tc-paragraph">
              Varje avvikelserapport registreras och granskas av Transvoice. Det
              eventuella felet identifieras och undersökning görs gällande
              varför avvikelsen inträffat. Om rapporteringen är direkt knuten
              till tjänsteleveransen, kontaktas aktuell uppdragstagare av
              Transvoice för klargörande och förbättringsåtgärder. I annat fall
              löses detta internt hos Transvoice. Åtgärder vidtas för
              konstruktiva syften, så att dylik avvikelse inte upprepas.
              <br />
              Detta kan medföra förnyelse av rutiner, vilket leder till
              kvalitetsförbättring. När granskningen är gjord återkopplar
              Transvoice avseende rapporteringen till kunden. Transvoice
              dokumenterar ärendet och analyserar resultatet internt för att få
              en god överblick över sin verksamhet.
            </p>
            <h5 className="tc-heading-sub">
              6. Villkor för fakturor (tolkar godkända för f-skattesedel)
            </h5>
            <p className="tc-paragraph">
              För att fakturera Transvoice för dina utförda uppdrag krävs att du
              har eget företag. Du lämnar då in registerutdrag som du får från
              Skatteverket. Lämna in nytt registerutdrag tillsammans med första
              fakturan varje år.
            </p>
            <p className="tc-paragraph">
              I början på varje månad laddas ditt underlag upp i Transvoice app
              och portal under ”Ersättning”. Där laddas även ett förslag på
              faktura upp med de uppgifter som vi har fått.
            </p>
            <p className="tc-paragraph">
              Vill du använda fakturaförslaget ska alla uppgifter kontrolleras.
              Uppge fakturanummer så att det stämmer med din egen fakturaserie
              och signera underlaget. Fakturan ska sedan inkomma till oss via
              e-post eller post.
            </p>
          </div>
          <div className="tv-modal__container__content__buttons">
            <Button label="Neka" palette="outline" onClick={reject} />
            <Button label="Acceptera" onClick={onAccept} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

TermsAndConditionsModal.propTypes = {
  getInterpreterProfile: PropTypes.func,
  reject: PropTypes.func,
};

TermsAndConditionsModal.defaultProps = {
  getInterpreterProfile: () => {},
  reject: () => {},
};

const mapDispatchToProps = dispatch => ({
  getInterpreterProfile: () => {
    dispatch(resourceDetailReadRequest('userProfile'));
  },
});

export default connect(null, mapDispatchToProps)(TermsAndConditionsModal);
