import React, { useState } from 'react';
import { TabButtonRow, Translate, Terms, PersonalDataPolicy } from 'components';

import './style.scss';

function TermsAndCondition() {
  const [activeTab, setActiveTab] = useState('Terms');
  return (
    <div className="tv-terms-and-condition__container">
      <div className="tv-terms-and-condition__title">
        {Translate({ content: 'settings.generalTermAndCondions' })}
      </div>
      <div className="tv-terms-and-condition__tab">
        <TabButtonRow
          tabs={[
            { name: 'Terms', label: 'Användarvillkor' },
            { name: 'PersonalDataPolicy', label: 'Personuppgiftspolicy' },
          ]}
          activeTab={activeTab}
          onTabClick={tab => {
            setActiveTab(tab.name);
          }}
        />
      </div>
      {activeTab === 'Terms' && <Terms />}
      {activeTab !== 'Terms' && <PersonalDataPolicy />}
    </div>
  );
}


export default TermsAndCondition;
