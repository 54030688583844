/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Label, InputField } from 'components';
import './style.scss';

const Field = ({
  name,
  label,
  value,
  placeholder,
  type,
  fieldType,
  options,
  onChange,
  onBlur,
  className,
  onKeyUp,
  error,
  showIcon,
  icon,
  ...rest
}) => (
  <div className={`${className.containerClass} tv-field__container`}>
    {label && (
      <Label
        htmlFor={name}
        className={`${className.labelClass} tv-field__label`}
        labelName={label}
      />
    )}
    <InputField
      name={name}
      value={value}
      placeholder={placeholder}
      type={type}
      fieldType={fieldType}
      className={`${className.inputClass}  tv-field__input ${
        error && 'tv-field__input--error'
      }`}
      onChange={onChange}
      onKeyUp={onKeyUp}
      onBlur={onBlur}
      options={options}
      {...rest}
    />
    {showIcon && <i className="material-icons">{icon}</i>}
    {error && (
      <div className={`${className.errorClass}  tv-field__error`}>{error} </div>
    )}
  </div>
);

Field.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.node,
  fieldType: PropTypes.string,
  onBlur: PropTypes.func,
  style: PropTypes.shape({}),
  onKeyUp: PropTypes.func,
  error: PropTypes.string,
  icon: PropTypes.string,
  showIcon: PropTypes.bool,
  className: PropTypes.shape({
    inputClass: PropTypes.string,
    labelClass: PropTypes.string,
    containerClass: PropTypes.string,
    errorClass: PropTypes.string,
  }),
};

Field.defaultProps = {
  name: '',
  label: null,
  value: undefined,
  onKeyUp: () => {},
  placeholder: '',
  type: '',
  fieldType: '',
  options: [<option value="">Vilaj</option>],
  onChange: () => {},
  onBlur: () => {},
  style: {},
  error: null,
  icon: null,
  showIcon: false,
  className: {
    inputClass: '',
    labelClass: '',
    containerClass: '',
    errorClass: '',
  },
};

export default Field;
