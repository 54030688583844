/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import './style.scss';
import {
  Translate,
  DatePicker,
  TabButtonRow,
  TimePicker,
  Button,
} from 'components';
import PropTypes from 'prop-types';
import { localDayjs } from 'utils/dateTimeUtils';

const tabs = [
  { name: 'available', label: 'Tillgänglig' },
  { name: 'occupied', label: 'Upptagen' },
];

function hasCurrentDaySelected(selectedDate) {
  const returnValue = localDayjs(selectedDate).isSame(localDayjs(), 'day');
  return returnValue;
}

function ExceptionAvailability({
  exceptionType,
  onAvailablityTypeChange,
  selectedDate,
  onDatePickerChange,
  startTime,
  onTimePickerChange,
  endTime,
  onConfirm,
  validationError,
  showDelete,
  onDelete,
}) {
  return (
    <div className="tv-shorter-availablity__container">
      <div className="tv-shorter-availablity__title">
        {Translate({ content: 'shorterAvailablity.title' })}
      </div>
      <div className="tv-shorter-availablity__content tv-mobile--hide">
        {Translate({ content: 'shorterAvailablity.contentWeb' })}
      </div>
      <div className="tv-shorter-availablity__content tv-mobile--show">
        {Translate({ content: 'shorterAvailablity.contentMobile' })}
      </div>
      <div className="tv-shorter-availablity__wrapper">
        <div className="tv-shorter-availablity__tab-container">
          <TabButtonRow
            className="tab"
            tabs={tabs}
            activeTab={exceptionType}
            onTabClick={onAvailablityTypeChange}
          />
        </div>
        <div className="tv-shorter-availablity__datepicker-container">
          <div className="tv-shorter-availablity__control">
            <label htmlFor="datenum">
              {Translate({ content: 'shorterAvailablity.date' })}
            </label>
            <DatePicker
              selectedDate={selectedDate}
              minDate={new Date()}
              label="Datum"
              name="Datum"
              placeholderText="Önskat datum"
              onChange={onDatePickerChange}
            />
          </div>
          <div className="tv-shorter-availablity__control-time">
            <div className="tv-shorter-availablity__control ">
              <label>
                {Translate({ content: 'shorterAvailablity.start' })}
              </label>
              <TimePicker
                label="Start"
                inputName="Start"
                startFromDayBegin={!hasCurrentDaySelected(selectedDate)}
                minuteStep={15}
                defaultValue={startTime}
                onChange={timeObj => onTimePickerChange(timeObj, 'start')}
              />
            </div>
            <div className="tv-shorter-availablity__control">
              <label>{Translate({ content: 'shorterAvailablity.stop' })}</label>
              <TimePicker
                label="Stopp"
                inputName="Stopp"
                startFromDayBegin={!hasCurrentDaySelected(selectedDate)}
                defaultValue={endTime}
                minuteStep={15}
                onChange={timeObj => onTimePickerChange(timeObj, 'end')}
              />
            </div>
          </div>
        </div>
      </div>
      {!validationError.isValid && (
        <div className="tv-exception-avilability__errors">
          {validationError.errorMessage}
        </div>
      )}
      <div className="tv-shorter-availablity__actions">
        {showDelete && (
          <div className="tv-exception-avilability__actions__row">
            <div
              className="tv-exception-avilability__actions--delete"
              onClick={onDelete}
            >
              Ta bort
            </div>
          </div>
        )}
        <div className="tv-shorter-availablity__actions__row">
          {/* <Button
            label="Avbryt"
            className="tv-shorter-availablity__actions-button"
            palette="outline"
          /> */}
          <Button
            label="Lägg till"
            className="tv-shorter-availablity__actions-button"
            onClick={onConfirm}
          />
        </div>
      </div>
    </div>
  );
}

ExceptionAvailability.propTypes = {
  onDatePickerChange: PropTypes.func,
  onTimePickerChange: PropTypes.func,
  onAvailablityTypeChange: PropTypes.func,
  onDelete: PropTypes.func,

  onConfirm: PropTypes.func,
  selectedDate: PropTypes.shape({}),
  startTime: PropTypes.shape({}),
  endTime: PropTypes.shape({}),
  exceptionType: PropTypes.string,
  validationError: PropTypes.shape({
    isValid: PropTypes.bool,
    errorMessage: PropTypes.string,
  }),
  showDelete: PropTypes.bool,
};

ExceptionAvailability.defaultProps = {
  onDatePickerChange: () => {},
  onTimePickerChange: () => {},
  onAvailablityTypeChange: () => {},
  onConfirm: () => {},
  onDelete: () => {},
  selectedDate: null,
  startTime: new Date(),
  endTime: new Date(),
  exceptionType: 'occupied',
  validationError: {
    isValid: true,
    errorMessage: '',
  },
  showDelete: false,
};

export default ExceptionAvailability;
