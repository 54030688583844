/* eslint-disable */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import './style.scss';

const Toggle = ({
  label,
  large,
  noText,
  theme,
  checked,
  onChange,
  className,
  textOn,
  textOff,
  isInactive,
  inactiveMessage,
}) => {
  let id = label;

  large == true ? (className += ' tv-toggle--large') : null;
  noText == true ? (className += ' tv-toggle--no-text') : null;
  theme == 'success' ? (className += ' tv-toggle--success') : null;
  isInactive == true ? (className += ' tv-toggle--inactive  bottom') : null;

  return (
    <>
      <div
        aria-label={label}
        className={className}
        role="tv-toggle"
        data-tip={isInactive ? inactiveMessage : ''}
      >
        <label className="tv-toggle__label" htmlFor={id}>
          <input
            type="checkbox"
            className="tv-toggle__input d-none"
            disabled={isInactive}
            id={id}
            onChange={onChange}
            checked={checked}
          />
          <span
            className="tv-toggle__text"
            data-on={textOn}
            data-off={textOff}
          ></span>
          <span className="tv-toggle__handle"></span>
        </label>
      </div>
      {isInactive && (
        <>
          {/* div open on hover with bg black and text white */}
          <div className="tv-toggle__inactive-message">
            <span>{inactiveMessage}</span>
            <NavLink to="/releases-page">Läs mer här.</NavLink>
          </div>
        </>
      )}
    </>
  );
};

Toggle.propTypes = {
  className: PropTypes.string,
  theme: PropTypes.string,
  label: PropTypes.string,
  noText: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  textOn: PropTypes.string,
  textOff: PropTypes.string,
  isInactive: PropTypes.bool,
  inactiveMessage: PropTypes.string,
};

Toggle.defaultProps = {
  className: 'tv-toggle',
  noText: false,
  onChange: () => {},
  checked: false,
  textOn: '  på',
  textOff: 'av',
  isInactive: false,
  inactiveMessage: '',
};

export default Toggle;
