 /* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const StarRating = ({ onChange, outOf, defaultValue, viewMode }) => {
  const setOutof = () => {
    const outOfVal = outOf || 5;
    const starsList = [];

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < outOfVal; i++) {
      starsList.push(i + 1);
    }
    return starsList;
  };

  const [stars, setStars] = useState(setOutof);
  const [rating, setRating] = useState(defaultValue);
  useEffect(() => {
    setRating(defaultValue);
  }, [defaultValue]);
  const [hovered, setHovered] = useState(0);
  const [selectedIcon, setSelectedIcon] = useState(<i className="material-icons material-icons-outlined">star</i>);
  
  const [deselectedIcon, setDeselectedIcon] = useState(<i className="material-icons material-icons-outlined">star_outline</i>);

  const changeRating = newRating => {
    if (!viewMode) {
      setRating(newRating);
      onChange(newRating);
    }
  };

  const hoverRating = value => {
    if (!viewMode) {
      setHovered(value);
    }
  };
  return (
    <>
      <div className="rating tv-star-rating__icon">
        {stars.map(star => (
          <span
            onClick={() => {
              changeRating(star);
            }}
            onMouseEnter={() => {
              hoverRating(star);
            }}
            onMouseLeave={() => {
              hoverRating(0);
            }}
            onKeyPress={() => {
              changeRating(star);
            }}
            role="button"
            tabIndex={0}
            style={viewMode ? { cursor: 'default' } : {}}
          >
            {rating < star
              ? hovered < star
                ? deselectedIcon
                : selectedIcon
              : selectedIcon}
          </span>
        ))}
      </div>
    </>
  );
};

StarRating.propTypes = {
  onChange: PropTypes.func,
  outOf: PropTypes.number,
  defaultValue: PropTypes.number,
  viewMode: PropTypes.bool,
};

StarRating.defaultProps = {
  onChange: () => {},
  outOf: 5,
  defaultValue: 0,
  viewMode: true,
};
export default StarRating;
