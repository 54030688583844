/* eslint-disable react/prop-types */
import React, { Component, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  convertToDayJsObject,
  currentDate,
  get24HoursInday,
} from 'utils/dateTimeUtils';
import { isEqual } from 'lodash';
import { WorkAssignmentItemContainer } from 'containers';
import {
  AvailabilityList,
  WeekViewAssignmentItem,
  DaySemesterColumn,
} from 'components';
import { PIXEL_PER_HOUR } from 'constant';
import './style.scss';
import { sortAssignmentRequestList } from 'context/CalendarContext';

class WeekDay extends Component {
  constructor(props) {
    super(props);

    this.currentTimeRef = React.createRef();
    this.weekDayRef = React.createRef();
    this.isMacOS = false;
    if (navigator.platform.match('Mac') !== null) {
      this.isMacOS = true;
    }
  }

  componentDidMount() {
    const currentTimeElement = this.currentTimeRef?.current;

    if (currentTimeElement) {
      const pixelPerMinute = PIXEL_PER_HOUR / 60;
      const initialStartTime =
        currentDate().diff(currentDate().startOf('day'), 'minute') *
        pixelPerMinute;

      currentTimeElement.style.top = `${initialStartTime}px`;
      currentTimeElement.setAttribute('data-time', initialStartTime);
      this.currentTimeLineSetInterval = setInterval(() => {
        const newTime =
          (Number.parseFloat(currentTimeElement.getAttribute('data-time')) ||
            0) + pixelPerMinute;
        currentTimeElement.style.top = `${newTime}px`;
        currentTimeElement.setAttribute('data-time', newTime);
      }, 60000);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const {
      eventsForDay,
      availabilityList,
      semesterDay,
      activeAssignment,
      selectedCalendarType,
      isAvailabilityFilterApplied,
    } = this.props;
    if (!isEqual(eventsForDay, nextProps.eventsForDay)) {
      return true;
    }
    if (!isEqual(availabilityList, nextProps.availabilityList)) {
      return true;
    }
    if (!isEqual(semesterDay, nextProps.semesterDay)) {
      return true;
    }
    if (!isEqual(activeAssignment, nextProps.activeAssignment)) {
      return true;
    }
    if (
      !isEqual(
        isAvailabilityFilterApplied,
        nextProps.isAvailabilityFilterApplied,
      )
    ) {
      return true;
    }
    if (!isEqual(selectedCalendarType, nextProps.selectedCalendarType)) {
      return true;
    }
    return false;
  }

  componentWillUnmount() {
    if (this.currentTimeLineSetInterval) {
      clearInterval(this.currentTimeLineSetInterval);
    }
  }

  render() {
    const {
      eventsForDay,
      availabilityList,
      semesterDay,
      date,
      location,
      activeAssignment,
      onWorkAssignmentClick,
      onAvailabilityExceptionClick,
      onActiveSemesterAllocationClick,
      isAvailabilityFilterApplied,
      singleDay,
    } = this.props;

    const hours = get24HoursInday();

    return (
      <>
        <div className="tv-week-day__container" ref={this.weekDayRef}>
          <div
            className="tv-week-day__hour-container"
            style={{
              background: "url('/images/unavailable-texture.png')",
              height: `${PIXEL_PER_HOUR * 24}px`,
            }}
          >
            {hours.map(hour => (
              <div className="tv-week-day__hour-row" key={hour} />
            ))}
            <AvailabilityList
              list={
                !isAvailabilityFilterApplied
                  ? availabilityList
                  : availabilityList.filter(x => x.AvailabilityType !== 3)
              }
              onItmeclick={onAvailabilityExceptionClick}
              isAvailabilityFilterApplied={isAvailabilityFilterApplied}
            />
            {semesterDay.isSemesterDay && (
              <DaySemesterColumn
                semester={semesterDay.data}
                isSemesterStartDay={semesterDay.isSemesterStartDay}
                onItemClick={onActiveSemesterAllocationClick}
                singleDay={singleDay}
              />
            )}
          </div>
          <AssignmentList
            list={eventsForDay}
            location={location}
            isAvailabilityFilterApplied={isAvailabilityFilterApplied}
            activeAssignment={activeAssignment}
            onWorkAssignmentClick={onWorkAssignmentClick}
          />
          {currentDate().isSame(date, 'day') ? (
            <hr
              className={`tv-calendar-timeline__curent-time ${
                this.isMacOS ? 'macFix' : ''
              }`}
              ref={this.currentTimeRef}
            />
          ) : null}
        </div>
      </>
    );
  }
}

WeekDay.propTypes = {};

export default WeekDay;

function groupOverlappingAssignments(list) {
  const sortedList = (list || [])
    .sort((a, b) =>
      convertToDayJsObject(a.DatetimeOrderFrom).diff(b.DatetimeOrderFrom),
    )
    .filter(x => x.OrderStatus !== 'cancelled');
  // eslint-disable-next-line no-debugger
  const processedList = [];
  // eslint-disable-next-line no-plusplus
  for (let index = 0; index < sortedList.length; index++) {
    const element = sortedList[index];
    if (processedList.length === 0) {
      processedList.push({
        from: convertToDayJsObject(element.DatetimeOrderFrom),
        to: convertToDayJsObject(element.DatetimeOrderTo),
      });
    } else if (
      convertToDayJsObject(element.DatetimeOrderFrom).diff(
        processedList[processedList.length - 1].from,
        'minute',
      ) < 0 ||
      convertToDayJsObject(element.DatetimeOrderFrom).diff(
        processedList[processedList.length - 1].to,
        'minute',
      ) < 15
    ) {
      processedList[processedList.length - 1].to = convertToDayJsObject(
        element.DatetimeOrderTo,
      );
    } else {
      processedList.push({
        from: convertToDayJsObject(element.DatetimeOrderFrom),
        to: convertToDayJsObject(element.DatetimeOrderTo),
      });
    }
  }
  return processedList;
}

function calculateHeightAndPosition(timeFrom, timeTo) {
  const diffFromDaySatrt = timeFrom.diff(timeFrom.startOf('day'), 'minutes');
  const diffInMinutes = timeTo.diff(timeFrom, 'minutes') || 0;
  const fifteenMinPortions = diffInMinutes / 15;
  const fullHourhieghtInPx = 128;
  return {
    height: `${(fullHourhieghtInPx * fifteenMinPortions) / 4}px`,
    top: `${(fullHourhieghtInPx * diffFromDaySatrt) / 60}px`,
    background: "url('/images/unavailable-texture.png')",
  };
}

function AssignmentList({
  list = [],
  location,
  onWorkAssignmentClick,
  activeAssignment,
  isAvailabilityFilterApplied,
}) {
  const [assignmentsList, setAssignmentsList] = useState(list || []);
  const [mergedAssignmentsList, setMergedAssignmentsList] = useState(
    groupOverlappingAssignments(list) || [],
  );
  useEffect(() => {
    setAssignmentsList(list);
    if (isAvailabilityFilterApplied) {
      setMergedAssignmentsList(groupOverlappingAssignments(list) || []);
    } else {
      setAssignmentsList(list);
    }
  }, [list, isAvailabilityFilterApplied]);

  return !isAvailabilityFilterApplied ? (
    assignmentsList.map((item, index) => (
      <WorkAssignmentItemContainer
        key={item.key}
        detail={item}
        index={index}
        isActive={
          item.WorkAssignmentIdentifier ===
          activeAssignment?.WorkAssignmentIdentifier
        }
        render={childProps => (
          <WeekViewAssignmentItem
            {...childProps}
            location={location}
            onClick={() => onWorkAssignmentClick(item)}
          />
        )}
      />
    ))
  ) : (
    <>
      {mergedAssignmentsList.map(x => (
        <div
          style={calculateHeightAndPosition(
            convertToDayJsObject(x.from),
            convertToDayJsObject(x.to),
          )}
          className="tv-week-day-assignment-off-state"
        />
      ))}
    </>
  );
}
