import React, { useEffect, useRef } from 'react';
import { LoginForm, Translate, Spinner } from 'components';
import { fulfilled, pending, clean } from 'redux-saga-thunk';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { transvoiceResourceAuthenticate } from 'store/actions';
import { useHistory } from 'react-router';
import { useToasts } from 'react-toast-notifications';
import generateHashPassword from 'utils/hashUtil';

export const Login = ({
  authenticateResourceUser,
  isAuthenticationFulfilled,
  isAuthenticationPending,
  cleanAuthentication,
}) => {
  const history = useHistory();

  useEffect(() => {
    const params = new URLSearchParams(history.location.search);
    const isUserLoginFromEmailLink = params.get('data');
    if (isUserLoginFromEmailLink) {
      history.push('/forgot-password', {
        data: history.location.search.split('=')[1],
      });
    }
  }, []);

  useEffect(() => {
    if (isAuthenticationFulfilled) {
      cleanAuthentication();

      history.push('/', {
        showWelcomeModal: true,
      });
    }
  }, [isAuthenticationFulfilled]);

  const handleFormSubmit = ({ password, email }) => {
    const data = {
      email,
      passwordHash: generateHashPassword(password),
      isRefresh: false,
    };
    authenticateResourceUser(data);
  };

  return (
    <>
      {isAuthenticationPending && <Spinner />}
      <LoginForm onFormSubmit={handleFormSubmit} />
    </>
  );
};

Login.propTypes = {
  authenticateResourceUser: PropTypes.func,
  cleanAuthentication: PropTypes.func,
  location: PropTypes.shape({ search: PropTypes.string }),
  isAuthenticationFulfilled: PropTypes.bool,
  isAuthenticationPending: PropTypes.bool,
};
Login.defaultProps = {
  authenticateResourceUser: () => {},
  cleanAuthentication: () => {},
  location: { search: '' },
  isAuthenticationFulfilled: false,
  isAuthenticationPending: false,
};

const mapDispatchToProps = dispatch => ({
  authenticateResourceUser: data =>
    dispatch(transvoiceResourceAuthenticate(data)),
  cleanAuthentication: () => {
    dispatch(clean('resourceUserAuthenticate'));
  },
});
const mapStateToProps = state => ({
  isAuthenticationFulfilled: fulfilled(state, 'resourceUserAuthenticate'),
  isAuthenticationPending: pending(state, 'resourceUserAuthenticate'),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
