import React, { useState } from 'react';
import { Button, DropdownMenuItem, Translate } from 'components';
import './style.scss';
import PropTypes from 'prop-types';

const LeaveTypeMenuDropdown = ({ onClickExceptionAvailability }) => {
  const [showDropDown, setShowDropDown] = useState(false);

  const toggleMenu = () => {
    setShowDropDown(!showDropDown);
  };

  const onBlur = event => {
    const { currentTarget } = event;
    event.preventDefault();
    event.stopPropagation();
    setTimeout(() => {
      if (!currentTarget.contains(document.activeElement)) {
        setShowDropDown(false);
      }
    }, 250);
  };

  return (
    <div className="tv-availability-list__menu tv-leave-type-menu-dropdown">
      <div
        className="tv-calendar__leave-type-menu-dropdown"
        onClick={e => {
          e.preventDefault();
          e.stopPropagation();
          toggleMenu();
        }}
        onKeyDown={() => {}}
        role="button"
        tabIndex={0}
        onBlur={onBlur}
      >
        <div>Hantera tillgänglighet</div>
        <i
          className={`fa ${showDropDown ? 'fa-angle-up' : 'fa-angle-down'}`}
          aria-hidden="true"
        />
      </div>
      {showDropDown && (
        <div>
          <DropdownMenuItem
            leftSideImage="../../images/manage_availability.png"
            heading="Tillgänglig / Upptagen"
            content="Använd detta för att ange kortare tillgänglighet eller upptagen tid."
            onClick={() => {
              setShowDropDown(false);
              onClickExceptionAvailability();
            }}
          />
          <DropdownMenuItem
            leftSideImage="../../images/schedule_availability.png"
            heading="Schemalagd tillgänglighet"
            content="Här schemalägger du din återkommande tillgänglighet."
            navigateTo="/settings/#default-availability"
          />
          <DropdownMenuItem
            leftSideImage="../../images/long_leave.png"
            heading="Lägg in längre ledighet"
            content="Här anger du ledighet i en längre  sammanhängande period."
            navigateTo="/settings/#semesters-allocation"
          />
          <DropdownMenuItem
            leftSideImage="../../images/sick_leave.png"
            heading="Sjukanmälan"
            content="Här anmäler du om du blivit akut sjuk."
            navigateTo="/settings#sick-leave"
            // onClick={onDisplayModal}
          />
        </div>
      )}
    </div>
  );
};

LeaveTypeMenuDropdown.propTypes = {
  onClickExceptionAvailability: PropTypes.func,
};

LeaveTypeMenuDropdown.defaultProps = {
  onClickExceptionAvailability: () => {},
};

export default LeaveTypeMenuDropdown;
