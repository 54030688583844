import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { isMobile } from 'utils/miscUtil';

function EmailRedirect() {
  const history = useHistory();
  const assignmentIdHandler = loc => {
    const searchParameter = loc?.search;
    if (!searchParameter || !searchParameter.includes('assignmentId'))
      return null;
    const assignmentSearchParameter = /assignmentId=(.*)/gm.exec(
      searchParameter,
    ) || [null];
    // eslint-disable-next-line prefer-destructuring
    return assignmentSearchParameter[1];
  };
  const redirectToDependOnDevice = assignmentId => {
    if (isMobile.Android()) {
      window.location.href = `transvoice://tolk.transvoice.se/email-redirect?assignmentId=${assignmentId}`;

      setTimeout(() => {
        window.location.replace(
          'https://play.google.com/store/apps/details?id=se.transvoice.tolka',
        );
      }, 10000);
      return;
    }
    if (isMobile.iOS()) {
      window.location.replace(
        `transvoice://tolk.transvoice.se/email-redirect?assignmentId=${assignmentId}`,
      );

      setTimeout(() => {
        window.location.replace(
          'https://apps.apple.com/us/app/tolka/id1489136873',
        );
      }, 10000);
    }
  };
  const redirectToPath = assignmentId => {
    if (isMobile.any()) {
      redirectToDependOnDevice(assignmentId);
      return;
    }
    history.push({
      pathname: '/my-assignments',
      state: {
        workAssignmentId: assignmentId,
        status: 'Avbokad',
      },
    });
  };
  useEffect(() => {
    const assignmentId = assignmentIdHandler(history.location);
    if (assignmentId) {
      redirectToPath(assignmentId);
    }
  }, []);

  return assignmentIdHandler(history.location) ? (
    <div className="mx-auto mt-5 text-center">
      Email link redirect to Order Uppdrag och förfrågningar otherwise
      <div className="tv-buttons--download-wrapper tv-display-flex mt-5 ">
        <a
          href="https://apps.apple.com/us/app/tolka/id1489136873"
          className="tv-buttons--download-ios mr-5"
          rel="noreferrer"
          target="_blank"
        >
          <img
            className="tv-buttons--download-button"
            src="https://www.transvoice.se/wp-content/themes/transvoice-theme/img/iOS-download.svg"
            alt="iOS-download"
          />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=se.transvoice.tolka"
          className="tv-buttons--download-android"
          rel="noreferrer"
          target="_blank"
        >
          <img
            className="tv-buttons--download-button"
            src="https://www.transvoice.se/wp-content/themes/transvoice-theme/img/google-play-badge.svg"
            alt="Android-download"
          />
        </a>
      </div>
    </div>
  ) : (
    <p className="mx-auto mt-5 text-center">
      Email redirect link broken. Kindly apologize for the inconvenience.
      <br />
      <b> Please contact Transvoice</b>
    </p>
  );
}

export default EmailRedirect;
