import React, { lazy, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import {
  AssignmentListPage,
  NotificationPage,
  EmailRedirect,
  PublicRoute,
  PrivateRoute,
  MainLayout,
  LoginLayout,
  MobileApp,
} from 'components';
import { clarityProjectKey } from 'config'

const AuthenticationPage = lazy(() => import('./pages/AuthenticationPage'));
const ForgotPasswordPage = lazy(() => import('./pages/ForgotPasswordPage'));
const DashboardPage = lazy(() => import('./pages/DashboardPage'));
const ContactPage = lazy(() => import('./pages/ContactPage'));
const SettingPage = lazy(() => import('./pages/SettingPage'));
const NewsPage = lazy(() => import('./pages/NewsPage'));

localStorage.setItem('clarityKey', clarityProjectKey);

const App = () => (
  <Suspense fallback={<div>Loading...</div>}>
    <Switch>
      <PrivateRoute
        exact
        path="/"
        layout={MainLayout}
        component={DashboardPage}
      />
      <PrivateRoute
        exact
        path="/my-assignments"
        layout={MainLayout}
        component={AssignmentListPage}
      />
      <PrivateRoute
        exact
        path="/notifications"
        layout={MainLayout}
        component={NotificationPage}
      />
      <PrivateRoute
        exact
        path="/contact-and-faq"
        layout={MainLayout}
        component={ContactPage}
      />
      <PrivateRoute
        exact
        path="/settings"
        layout={MainLayout}
        component={SettingPage}
      />
      <PrivateRoute
        exact
        path="/releases-page"
        layout={MainLayout}
        component={NewsPage}
      />
      <PublicRoute
        exact
        path="/login"
        layout={LoginLayout}
        component={AuthenticationPage}
      />
      <PublicRoute
        exact
        path="/forgot-password"
        layout={LoginLayout}
        component={ForgotPasswordPage}
      />
      <Route exact path="/mobile-app/login" component={MobileApp} />
      <PublicRoute
        exact
        path="/email-redirect"
        layout={LoginLayout}
        component={EmailRedirect}
      />
    </Switch>
  </Suspense>
);

export default App;
